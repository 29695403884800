import { Delete, Download } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TFunction } from "react-i18next";

import { ConfirmButton } from "../../../components/Controls/ConfirmButton";
import { Event } from "../../../models/calendar/Event";
import {
    DeleteEventListCallback,
    DownloadIcalListCallback,
    EditEventListCallback,
} from "../../../service/CalendarService";
import { createActionsColumn } from "../../../utils/datagrid.utils";
import { EditDialog } from "../Dialog/EditDialog";

interface Options {
    canEdit: boolean;
    download: DownloadIcalListCallback;
    editEvent: EditEventListCallback;
    deleteEvent: DeleteEventListCallback;
    t: TFunction;
}

export function getCalendarListActions({
    canEdit,
    download,
    editEvent,
    deleteEvent,
    t,
}: Options) {
    return createActionsColumn<Event>(params => {
        const row = params.row;

        const actions = [
            <IconButton
                key={`calendar_action_download_${row.id}`}
                onClick={() =>
                    download(row.id, row.name, row.customerLocationName)
                }
            >
                <Download />
            </IconButton>,
        ];
        if (canEdit) {
            actions.push(
                <EditDialog
                    key={`calendar_action_edit_${row.id}`}
                    event={row}
                    editEventCallback={editEvent}
                />,
            );
            actions.push(
                <ConfirmButton
                    key={`calendar_action_delete_${row.id}`}
                    confirmText={t("pages.calendar.edit.warning_remove")}
                    onConfirm={() => deleteEvent(row.id)}
                    color="error"
                    icon
                    isDanger
                >
                    <Delete />
                </ConfirmButton>,
            );
        }

        return actions;
    });
}
