import { MenuItem, TextField } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import {
    CustomerSelect,
    useCurrentCustomer,
    useCustomerSelect,
} from "../../context/AppContext";
import { formatCustomerName } from "../../utils/customer.utils";

export function CustomerDropdown() {
    const { t } = useTranslation();
    const currentCustomer = useCurrentCustomer();
    const selectCustomer = useCustomerSelect();

    const response = useSWR<CustomerSelect[]>("customers/allowed");
    const customers = useMemo(
        () => (!response.isLoading ? response.data ?? [] : []),
        [response.isLoading, response.data],
    );

    const onSelectCustomer = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const [customerId, locationId] = event.target.value.split("_");
            const customer = response.data?.find(
                f =>
                    f.customerId === Number(customerId) &&
                    f.customerLocationId === Number(locationId),
            );
            selectCustomer?.(customer);
        },
        [response.data, selectCustomer],
    );

    useEffect(() => {
        if (customers.length > 0 && currentCustomer === undefined) {
            selectCustomer?.(customers[0]);
        }
    }, [currentCustomer, customers, selectCustomer]);

    if (customers.length <= 1) {
        return <></>;
    }

    return (
        <TextField
            id="customer-select"
            value={
                currentCustomer
                    ? `${currentCustomer.customerId}_${currentCustomer.customerLocationId}`
                    : "-1"
            }
            label={t("common.customer_select")}
            onChange={onSelectCustomer}
            select
            fullWidth
        >
            <MenuItem key="-1" value="-1" disabled>
                {t("common.no_customer_selected")}
            </MenuItem>
            {customers.map(customer => (
                <MenuItem
                    key={`customer_${customer.customerId}_${customer.customerLocationId}`}
                    value={`${customer.customerId}_${customer.customerLocationId}`}
                >
                    {formatCustomerName(customer)}
                </MenuItem>
            ))}
        </TextField>
    );
}
