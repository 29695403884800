import { Edit } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
} from "@mui/material";
import { fromUnixTime } from "date-fns";
import { Formik, FormikHelpers } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { WeekPickersDay } from "./RangePickerDay";
import { useCalendarEventSchema } from "./schema";
import { FormikAutoComplete } from "../../../components/Controls/Formik/AutoComplete";
import { FormikDateInput } from "../../../components/Controls/Formik/DateInput";
import { FormikCustomerSelect } from "../../../components/Controls/Formik/Impl/CustomerSelect";
import { FormikSubmitButton } from "../../../components/Controls/Formik/SubmitButton";
import { useFormTranslator } from "../../../hooks/useFormTranslator";
import { useOpenState } from "../../../hooks/useOpenState";
import { Event, EventCreate, EventEdit } from "../../../models/calendar/Event";
import {
    EditEventListCallback,
    useCalendarEventNames,
} from "../../../service/CalendarService";

interface EditDialogProps {
    event: Event;
    editEventCallback: EditEventListCallback;
}

export function EditDialog({ event, editEventCallback }: EditDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("calendar");
    const { state, open, close } = useOpenState();

    const { validationSchema, isRequired } = useCalendarEventSchema(translator);
    const { namesResponse } = useCalendarEventNames(
        state,
        event.customerLocationId,
    );

    const onSubmitInternal = useCallback(
        async (values: EventCreate, helpers: FormikHelpers<EventCreate>) => {
            await editEventCallback(event.id, values)
                .then(() => helpers.resetForm())
                .then(close);
        },
        [editEventCallback, event.id, close],
    );

    return (
        <>
            <IconButton onClick={open}>
                <Edit />
            </IconButton>
            <Dialog open={state} fullWidth>
                <Formik
                    initialValues={{
                        name: event.name,
                        customerLocationId: event.customerLocationId,
                        startDate: fromUnixTime(event.startDate),
                        endDate: fromUnixTime(event.endDate),
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitInternal}
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t("pages.calendar.edit.action_create")}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    <FormikAutoComplete<EventEdit, string>
                                        formik={formik}
                                        field="name"
                                        translator={translator}
                                        required={isRequired("name")}
                                        options={namesResponse.data ?? []}
                                        freeSolo
                                        autoComplete
                                        autoHighlight
                                        autoSelect
                                        blurOnSelect
                                    />

                                    <FormikCustomerSelect
                                        formik={formik}
                                        field="customerLocationId"
                                        translator={translator}
                                        required={isRequired(
                                            "customerLocationId",
                                        )}
                                        opened={state}
                                        disabled
                                        noCustomerText={t(
                                            "pages.calendar.edit.no_customer",
                                        )}
                                    />

                                    <FormikDateInput
                                        formik={formik}
                                        field="startDate"
                                        translator={translator}
                                        slots={{
                                            day: WeekPickersDay,
                                        }}
                                    />

                                    <FormikDateInput
                                        formik={formik}
                                        field="endDate"
                                        translator={translator}
                                        minDate={formik.values.startDate}
                                        slots={{
                                            day: WeekPickersDay,
                                        }}
                                        slotProps={{
                                            day: {},
                                        }}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={close}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
