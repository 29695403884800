import { useParams } from "react-router-dom";

import { useCustomerIdFilter } from "./useCustomerIdFilter";

interface ResponseData {
    customerId: number | undefined;
    locationId: number | undefined;
}

export function useCustomerLocationIdFilter(): ResponseData {
    const { customerId } = useCustomerIdFilter();
    const { locationId } = useParams();

    let locationIdParsed: number | undefined = undefined;
    if (locationId) {
        locationIdParsed = parseInt(locationId, 10);
        if (isNaN(locationIdParsed)) {
            locationIdParsed = undefined;
        }
    }

    return {
        customerId,
        locationId: locationIdParsed,
    };
}
