import { useMemo } from "react";
import * as yup from "yup";

import { FormTranslator } from "../../../hooks/useFormTranslator";
import { useValidationResult, useYup } from "../../../hooks/useYup";

export function useFloorPlanSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(() => {
        return yup.object({
            name: yup.string().required(),
            customerLocationId: yup
                .number()
                .required()
                .test(
                    "Cannot be default value",
                    translator
                        .getField("customerLocationId")
                        .getGlobalError("required"),
                    num => num !== -1,
                ),
            file: yup.mixed<File>().required(),
        });
    }, [translator]);

    return useValidationResult(validationSchema);
}
