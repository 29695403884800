import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from "@mui/material";
import { Formik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDocumentDialogSchema } from "./schema";
import { FormikMultipleFileInput } from "../../../components/Controls/Formik/FileInput";
import { FormikSubmitButton } from "../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../hooks/useFormTranslator";
import { DocumentsCreate } from "../../../models/document/Document";
import { AddDocumentsCallback } from "../../../service/DocumentService";

interface UploadDialogProps {
    customerLocationId: number | undefined;
    directory: string[];
    addDocuments: AddDocumentsCallback;
}
export function AddDocumentDialog({
    customerLocationId,
    directory,
    addDocuments,
}: UploadDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("document");
    const { validationSchema, isRequired } =
        useDocumentDialogSchema(translator);

    const [opened, setOpened] = useState(false);

    const initialValues = useMemo<DocumentsCreate>(
        () => ({
            customerLocationId,
            files: [],
            directory,
            description: undefined,
        }),
        [customerLocationId, directory],
    );

    const onOpen = useCallback(() => setOpened(true), [setOpened]);
    const onClose = useCallback(() => setOpened(false), [setOpened]);

    return (
        <>
            <Button variant="outlined" onClick={onOpen}>
                {t("pages.document.edit.action_upload")}
            </Button>
            <Dialog open={opened} fullWidth>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, formik) => {
                        try {
                            await addDocuments(values);
                            onClose();
                            formik.resetForm();
                        } catch (ex) {
                            console.warn(ex);
                            formik.setSubmitting(false);
                        }
                    }}
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t("pages.document.edit.action_upload")}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    <FormikMultipleFileInput
                                        formik={formik}
                                        field="files"
                                        translator={translator}
                                        required={isRequired("file")}
                                    />

                                    <FormikTextField
                                        formik={formik}
                                        field="description"
                                        translator={translator}
                                        required={isRequired("description")}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={onClose}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
