export enum ControlPointType {
    MONITOR_MECHANICAL = "MONITOR_MECHANICAL",
    MONITOR_NONTOX = "MONITOR_NONTOX",
    FIGHT_MECHANICAL = "FIGHT_MECHANICAL",
    FIGHT_CHEMICAL = "FIGHT_CHEMICAL",
    OTHER = "OTHER",
}

export interface ControlPointForOverview {
    id: number;
    customerLocationId: number;
    customerName: string;
    customerLocationName: string;
    name: string;
    type: ControlPointType;
    sortId?: number;
    sort?: string;
    room: string;
    exactLocation?: string;
}

export interface ControlPoint {
    id: number;
    customerLocationId: number;
    name: string;
    type: ControlPointType;
    sortId?: number;
    room: string;
    exactLocation?: string;
    currentAmount: number;
    floorPlanId?: number;
    floorPlanPercentX?: number;
    floorPlanPercentY?: number;
}

export interface ControlPointsCreate {
    customerLocationId: number;
    name: string;
    type: ControlPointType;
    sortId: number;
    room: string;
    exactLocation: string;
}

export interface ControlPointEdit {
    sortId: number;
    room: string;
    exactLocation: string;
}

export interface ControlPointSort {
    id: number;
    name: string;
    color: string;
}

export interface ControlPointSortCreate {
    name: string;
    color: string;
}
