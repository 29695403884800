import { LinearProgress } from "@mui/material";
import React, { PropsWithChildren, useMemo } from "react";
import { SWRResponse } from "swr";

import { AnyType } from "../utils/utils";

type ParamList = {
    [key: string]: AnyType;
};

type ResponseArguments<T extends ParamList, TErr extends AnyType> = {
    [key in keyof T]: SWRResponse<T[key], TErr>;
};

type ResultArguments<T extends ParamList> = {
    [key in keyof T]: T[key] | undefined;
};

export interface UseServiceResponseOptions<TError extends AnyType> {
    loadingComponent: React.ElementType;
    validatingComponent: React.ElementType;
    errorComponent: React.ElementType<{ errors: TError[] }>;
}

export interface UseServiceResponse<
    T extends ParamList,
    TError extends AnyType,
> {
    isLoading: boolean;
    isValidating: boolean;
    errors: TError[];
    data: ResultArguments<T>;
}

interface ServiceResponseProps<T extends ParamList, TError extends AnyType> {
    response: UseServiceResponse<T, TError>;
    options?: Partial<UseServiceResponseOptions<TError>>;
}

export function useResponseCombiner<
    T extends ParamList,
    TError extends AnyType,
>(container: ResponseArguments<T, TError>): UseServiceResponse<T, TError> {
    return useMemo(() => {
        return Object.entries(container).reduce<UseServiceResponse<T, TError>>(
            (prev, [currKey, curr]) => {
                return {
                    isLoading: prev.isLoading === true ? true : curr.isLoading,
                    isValidating:
                        prev.isValidating === true ? true : curr.isValidating,
                    errors:
                        curr.error !== undefined
                            ? prev.errors.concat(curr.error.toString())
                            : prev.errors,
                    data: { ...prev.data, [currKey]: curr.data },
                };
            },
            {
                isLoading: false,
                isValidating: false,
                errors: [],
                data: {} as never,
            },
        );
    }, [container]);
}

export function ServiceResponse<T extends ParamList, TError extends AnyType>({
    response,
    options,
    children,
}: PropsWithChildren<ServiceResponseProps<T, TError>>) {
    if (response.isLoading) {
        const LoadingComponent = options?.loadingComponent ?? LinearProgress;
        return <LoadingComponent />;
    }
    if (response.isValidating) {
        const ValidatingComponent =
            options?.validatingComponent ?? LinearProgress;
        return <ValidatingComponent />;
    }
    if (response.errors.length > 0) {
        const ErrorComponent = options?.errorComponent ?? ErrorList;
        return <ErrorComponent errors={response.errors} />;
    }

    return <>{children}</>;
}

interface ErrorListProps<TError extends AnyType> {
    errors: TError[];
}

function ErrorList<TError extends AnyType>({ errors }: ErrorListProps<TError>) {
    return (
        <ul>
            {errors.map((err, idx) => (
                <li key={`error_${idx}`}>{err?.toString()}</li>
            ))}
        </ul>
    );
}
