import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from "@mui/material";
import { Formik } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFloorPlanSchema } from "./schema";
import { FormikFileInput } from "../../../components/Controls/Formik/FileInput";
import { FormikCustomerSelect } from "../../../components/Controls/Formik/Impl/CustomerSelect";
import { FormikSubmitButton } from "../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../hooks/useFormTranslator";
import { FloorPlanCreate } from "../../../models/floorplan/FloorPlan";
import { AddFloorPlanCallback } from "../../../service/FloorPlanService";

interface UploadDialogProps {
    customerLocationId: number | undefined;
    onSave: AddFloorPlanCallback;
}

function getInitialValues(
    customerLocationId: number | undefined,
): FloorPlanCreate {
    return {
        name: "",
        customerLocationId: customerLocationId ?? -1,
        file: undefined,
    };
}

export function UploadDialog({
    customerLocationId,
    onSave,
}: UploadDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("floorplan");
    const [opened, setOpened] = useState(false);

    const { validationSchema, isRequired } = useFloorPlanSchema(translator);

    const onOpen = useCallback(() => setOpened(true), [setOpened]);
    const onClose = useCallback(() => setOpened(false), [setOpened]);

    return (
        <>
            <Button variant="outlined" onClick={onOpen}>
                {t("pages.floorplan.edit.action_upload")}
            </Button>
            <Dialog open={opened} fullWidth>
                <Formik
                    initialValues={getInitialValues(customerLocationId)}
                    validationSchema={validationSchema}
                    onSubmit={async values => {
                        await onSave(values);
                        onClose();
                    }}
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t("pages.floorplan.edit.action_upload")}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    <FormikTextField
                                        formik={formik}
                                        field="name"
                                        translator={translator}
                                        required={isRequired("name")}
                                    />

                                    <FormikCustomerSelect
                                        formik={formik}
                                        field="customerLocationId"
                                        translator={translator}
                                        required={isRequired(
                                            "customerLocationId",
                                        )}
                                        disabled={
                                            customerLocationId !== undefined
                                        }
                                        noCustomerText={t(
                                            "pages.floorplan.edit.no_customer",
                                        )}
                                        opened={opened}
                                    />

                                    <FormikFileInput
                                        formik={formik}
                                        field="file"
                                        translator={translator}
                                        required={isRequired("file")}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={onClose}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
