import { Edit } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useControlPointEditSchema } from "./schema";
import { FormikSelect } from "../../components/Controls/Formik/Select";
import { FormikSubmitButton } from "../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../hooks/useFormTranslator";
import { useOpenState } from "../../hooks/useOpenState";
import {
    ControlPointEdit,
    ControlPointForOverview,
} from "../../models/controlpoint/ControlPoint";
import { useCompanyControlPointSortService } from "../../service/CompanyService";
import { EditControlPointCallback } from "../../service/ControlPointService";

interface EditDialogProps {
    key: string;
    controlPoint: ControlPointForOverview;
    editControlPointCallback: EditControlPointCallback;
}

function getInitialValues(
    controlPoint: ControlPointForOverview,
): ControlPointEdit {
    return {
        sortId: controlPoint.sortId ?? -1,
        room: controlPoint.room,
        exactLocation: controlPoint.exactLocation ?? "",
    };
}

export function EditDialog({
    key,
    controlPoint,
    editControlPointCallback,
}: EditDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("controlpoint");
    const { open, close, state } = useOpenState();
    const controlPointSortService = useCompanyControlPointSortService(state);

    const { validationSchema, isRequired } =
        useControlPointEditSchema(translator);

    const onSubmitInternal = useCallback(
        async (
            values: ControlPointEdit,
            helpers: FormikHelpers<ControlPointEdit>,
        ) => {
            await editControlPointCallback(controlPoint.id, values)
                .then(() => helpers.resetForm())
                .then(close);
        },
        [close, controlPoint.id, editControlPointCallback],
    );

    return (
        <Fragment key={key}>
            <IconButton onClick={open}>
                <Edit />
            </IconButton>
            <Dialog open={state} fullWidth>
                <Formik
                    initialValues={getInitialValues(controlPoint)}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitInternal}
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t("pages.controlpoint.edit.action_edit")}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    {controlPointSortService
                                        .controlPointSortsResponse.data && (
                                        <FormikSelect
                                            formik={formik}
                                            field="sortId"
                                            translator={translator}
                                            required={isRequired("sortId")}
                                        >
                                            {controlPointSortService.controlPointSortsResponse.data.map(
                                                controlPointSort => (
                                                    <MenuItem
                                                        key={`sortId_selection_${controlPointSort.id}`}
                                                        value={
                                                            controlPointSort.id
                                                        }
                                                    >
                                                        {controlPointSort.name}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </FormikSelect>
                                    )}

                                    <FormikTextField
                                        formik={formik}
                                        field="room"
                                        translator={translator}
                                        required={isRequired("room")}
                                    />

                                    <FormikTextField
                                        formik={formik}
                                        field="exactLocation"
                                        translator={translator}
                                        required={isRequired("exactLocation")}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={close}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </Fragment>
    );
}
