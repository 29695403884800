import { Box } from "@mui/material";
import { useMemo } from "react";

import { useCustomerAllowedService } from "../../../../service/CustomerService";
import { FormikAutoComplete, FormikAutoCompleteProps } from "../AutoComplete";
import { FormValues } from "../utils";

interface FormikCustomerSelectProps<T extends FormValues>
    extends Omit<FormikAutoCompleteProps<T, number>, "options"> {
    opened: boolean;
    noCustomerValue?: number;
    noCustomerText: string;
}

export function FormikCustomerSelect<T extends FormValues>({
    opened,
    noCustomerValue = -1,
    noCustomerText,
    formik,
    field,
    translator,
    disabled,
    ...props
}: FormikCustomerSelectProps<T>) {
    const { customerOptionsResponse } = useCustomerAllowedService(opened);

    const options = useMemo(
        () =>
            [noCustomerValue].concat(
                customerOptionsResponse.data?.map(m => m.customerLocationId) ??
                    [],
            ),
        [customerOptionsResponse.data, noCustomerValue],
    );

    return (
        <Box display="flex" flexDirection="row" gap={1}>
            <FormikAutoComplete
                {...props}
                formik={formik}
                field={field}
                translator={translator}
                options={options}
                loading={customerOptionsResponse.isValidating}
                disabled={!opened || disabled}
                noOptionsText={noCustomerText}
                style={{ flex: 1 }}
                getOptionLabel={item =>
                    item === noCustomerValue
                        ? noCustomerText
                        : customerOptionsResponse.data?.find(
                              f => f.customerLocationId === item,
                          )?.customerLocationName ?? ""
                }
                getOptionDisabled={item => item === noCustomerValue}
                groupBy={item =>
                    customerOptionsResponse.data?.find(
                        f => f.customerLocationId === item,
                    )?.customerName ?? ""
                }
                onEmptied={() => {
                    formik.setFieldValue(field, noCustomerValue);
                }}
            />
        </Box>
    );
}
