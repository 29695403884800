import { Add } from "@mui/icons-material";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    DialogActions,
    Button,
    IconButton,
} from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { ColorPicker } from "../../../../components/Controls/ColorPicker";
import { FormikSubmitButton } from "../../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { useOpenState } from "../../../../hooks/useOpenState";
import { ControlPointSortCreate } from "../../../../models/controlpoint/ControlPoint";
import { CreateControlPointSortViewCallback } from "../../../../service/CompanyService";
import { usePestSchema } from "../../schema";

interface CreatePestDialogProps {
    createControlPointSortCallback: CreateControlPointSortViewCallback;
}

const initialValues: ControlPointSortCreate = {
    name: "",
    color: "#FFFFFF",
};

export function CreateControlPointSortDialog({
    createControlPointSortCallback,
}: CreatePestDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("company.controlpointsort");

    const {
        state: open,
        open: openDialog,
        close: closeDialog,
    } = useOpenState();
    const { validationSchema, isRequired } = usePestSchema(translator);

    return (
        <>
            <IconButton onClick={openDialog}>
                <Add />
            </IconButton>
            <Dialog open={open} fullWidth>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async values => {
                        createControlPointSortCallback(values);
                        closeDialog();
                    }}
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t(
                                    "pages.company.controlpointsort.create_header",
                                )}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    <FormikTextField
                                        formik={formik}
                                        field="name"
                                        translator={translator}
                                        required={isRequired("name")}
                                    />
                                    <ColorPicker
                                        label={translator
                                            .getField("color")
                                            .getName()}
                                        name="color"
                                        color={formik.values.color}
                                        onChange={value =>
                                            formik.setFieldValue("color", value)
                                        }
                                        required={isRequired("color")}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={closeDialog}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
