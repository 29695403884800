import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { AnyType } from "../../utils/utils";

interface ErrorsCardProps<TError extends AnyType> {
    errors: TError[];
}

export function ErrorsCard<TError extends AnyType>({
    errors,
}: ErrorsCardProps<TError>) {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Card style={{ backgroundColor: theme.palette.error.light }}>
            <CardHeader title={t("common.errors")} style={{ color: "white" }} />
            <CardContent>
                {errors.map((error, idx) => (
                    <Typography key={`error_result_${idx}`} color="white">
                        {error?.toString()}
                    </Typography>
                ))}
            </CardContent>
        </Card>
    );
}
