import { useCallback, useMemo } from "react";

import { useCurrentUser } from "./useCurrentUser";
import { Permission } from "../permissions";

interface PermissionManager {
    hasPermission: (permission: Permission) => boolean;
}

export function usePermissions(): PermissionManager {
    const currentUser = useCurrentUser();
    const isLoggedIn = currentUser !== undefined;

    const hasPermission = useCallback(
        (permission: Permission) => {
            if (!isLoggedIn) {
                return false;
            }
            return (
                permission.roles.findIndex(f => f === currentUser.role) !== -1
            );
        },
        [isLoggedIn, currentUser],
    );

    return useMemo(
        () => ({
            hasPermission,
        }),
        [hasPermission],
    );
}
