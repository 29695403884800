import { i18n } from "i18next";
import { TFunction, useTranslation } from "react-i18next";

/**
 * A simple key-value pairs object containing values used for message interpolation.
 */
export type TranslationParams = { [key: string]: string | number };

export class FormTranslator {
    private t: TFunction;
    private i18n: i18n;
    private namespace: string;

    constructor(t: TFunction, i18n: i18n, namespace: string) {
        this.t = t;
        this.i18n = i18n;
        this.namespace = namespace;
    }

    /**
     * Get a translator dedicated to a specific field in the form.
     * @param name The key of the field.
     * @returns A FieldTranslator object.
     */
    getField(name: string): FieldTranslator {
        return new FieldTranslator(this.t, this.i18n, this.namespace, name);
    }

    /**
     * Returns a translated error string from the form local error scope `"{namespace}:errors:{name}"`.
     * @param name The name of the error.
     * @param params Additional parameters used to interpolate the error message.
     * @returns The translated error message.
     */
    getFormError(name: string, params?: TranslationParams): string {
        return this.t(this.namespace + ".errors." + name, params);
    }
}

export class FieldTranslator {
    private t: TFunction;
    private i18n: i18n;
    private namespace: string;
    private field: string;

    constructor(t: TFunction, i18n: i18n, namespace: string, field: string) {
        this.t = t;
        this.i18n = i18n;
        this.namespace = namespace;
        this.field = field;
    }

    /**
     * Get the name of this field.
     * @returns The name.
     */
    getName() {
        if (
            this.i18n.exists(this.namespace + ".fields." + this.field + ".name")
        ) {
            return this.t(this.namespace + ".fields." + this.field + ".name");
        } else {
            return this.t(this.namespace + ".fields." + this.field);
        }
    }

    /**
     * Get the name of an enum value for this field.
     * @param key The enum value.
     * @returns The translated message for the enum value.
     */
    getEnum(key: string) {
        return this.t(
            this.namespace + ".fields." + this.field + ".values." + key,
        );
    }

    /**
     * Returns a translated error string from the global error scope `"errors:{name}"`.
     * This method automatically adds the param `name` to the interpolated values, which contains the translated field name.
     * @param name The name of the error.
     * @param params Additional parameters used to interpolate the error message.
     * @returns The translated error message.
     */
    getGlobalError(name: string, params?: TranslationParams): string {
        const actualParams = params ?? {};
        actualParams.name = this.getName();
        return this.t("errors." + name, actualParams);
    }
}

/**
 * Creates a form translator which you can use to translate form fields.
 * @param namespace The namespace which to find the form fields and errors under, for example: `form:{name}`
 * @returns The form translator object.
 */
export function useFormTranslator(namespace: string): FormTranslator {
    const { t, i18n } = useTranslation();

    return new FormTranslator(t, i18n, "form." + namespace);
}
