import { useCallback, useMemo } from "react";
import useSWR, { SWRResponse } from "swr";

import { useFetch } from "../hooks/useFetch";
import {
    Employee,
    EmployeeCreate,
    EmployeeEdit,
} from "../models/company/Employee";
import { Pest, PestCreate } from "../models/company/Pest";
import {
    ControlPointSort,
    ControlPointSortCreate,
} from "../models/controlpoint/ControlPoint";

export type CreatePestViewCallback = (model: PestCreate) => Promise<void>;
export type DeletePestViewCallback = (id: number) => Promise<void>;

interface CompanyPestServiceViewResult {
    pestsResponse: SWRResponse<Pest[], Error>;
    createPest: CreatePestViewCallback;
    deletePest: DeletePestViewCallback;
}

const CompanyPestsPath = "company/my/pests";
const CreatePestPath = "company/my/pests";
const DeletePestPath = (id: number) => `company/my/pests/${id}`;

export function useCompanyPestService(): CompanyPestServiceViewResult {
    const fetch = useFetch();

    const pestsResponse = useSWR<Pest[], Error>(CompanyPestsPath);

    const createPest = useCallback<CreatePestViewCallback>(
        async model => {
            await fetch.post(CreatePestPath, model);
            await pestsResponse.mutate();
        },
        [pestsResponse, fetch],
    );

    const deletePest = useCallback<DeletePestViewCallback>(
        async id => {
            await fetch.delete(DeletePestPath(id));
            await pestsResponse.mutate();
        },
        [pestsResponse, fetch],
    );

    return useMemo(
        () => ({
            pestsResponse,
            createPest,
            deletePest,
        }),
        [pestsResponse, createPest, deletePest],
    );
}

export type CreateControlPointSortViewCallback = (
    model: ControlPointSortCreate,
) => Promise<void>;
export type DeleteControlPointSortViewCallback = (id: number) => Promise<void>;

interface CompanyControlPointSortServiceViewResult {
    controlPointSortsResponse: SWRResponse<ControlPointSort[], Error>;
    createControlPointSort: CreateControlPointSortViewCallback;
    deleteControlPointSort: DeleteControlPointSortViewCallback;
}

const CompanyControlPointSortPath = "company/my/sorts";
const CreateControlPointSortPath = "company/my/sorts";
const DeleteControlPointSortPath = (id: number) => `company/my/sorts/${id}`;

export function useCompanyControlPointSortService(
    shouldLoad = true,
): CompanyControlPointSortServiceViewResult {
    const fetch = useFetch();

    const controlPointSortsResponse = useSWR<ControlPointSort[], Error>(
        shouldLoad ? CompanyControlPointSortPath : undefined,
    );

    const createControlPointSort =
        useCallback<CreateControlPointSortViewCallback>(
            async model => {
                await fetch.post(CreateControlPointSortPath, model);
                await controlPointSortsResponse.mutate();
            },
            [controlPointSortsResponse, fetch],
        );

    const deleteControlPointSort =
        useCallback<DeleteControlPointSortViewCallback>(
            async id => {
                await fetch.delete(DeleteControlPointSortPath(id));
                await controlPointSortsResponse.mutate();
            },
            [controlPointSortsResponse, fetch],
        );

    return useMemo(
        () => ({
            controlPointSortsResponse,
            deleteControlPointSort,
            createControlPointSort,
        }),
        [
            controlPointSortsResponse,
            createControlPointSort,
            deleteControlPointSort,
        ],
    );
}

export type CreateEmployeeViewCallback = (
    model: EmployeeCreate,
) => Promise<void>;
export type EditEmployeeViewCallback = (
    id: number,
    model: EmployeeEdit,
) => Promise<void>;
export type DeleteEmployeeViewCallback = (id: number) => Promise<void>;

interface CompanyEmployeeServiceViewResult {
    employeesResponse: SWRResponse<Employee[], Error>;
    createEmployee: CreateEmployeeViewCallback;
    editEmployee: EditEmployeeViewCallback;
    deleteEmployee: DeleteEmployeeViewCallback;
}

const CompanyEmployeePath = "company/my/employees";
const CreateEmployeePath = "company/my/employees";
const EditEmployeePath = (id: number) => `company/my/employees/${id}`;
const DeleteEmployeePath = (id: number) => `company/my/employees/${id}`;

export function useCompanyEmployeeService(
    shouldLoad = true,
): CompanyEmployeeServiceViewResult {
    const fetch = useFetch();

    const employeesResponse = useSWR<Employee[], Error>(
        shouldLoad ? CompanyEmployeePath : undefined,
    );

    const createEmployee = useCallback<CreateEmployeeViewCallback>(
        async model => {
            await fetch.post(CreateEmployeePath, model);
            await employeesResponse.mutate();
        },
        [employeesResponse, fetch],
    );

    const editEmployee = useCallback<EditEmployeeViewCallback>(
        async (id, model) => {
            await fetch.post(EditEmployeePath(id), model);
            await employeesResponse.mutate();
        },
        [employeesResponse, fetch],
    );

    const deleteEmployee = useCallback<DeleteEmployeeViewCallback>(
        async id => {
            await fetch.delete(DeleteEmployeePath(id));
            await employeesResponse.mutate();
        },
        [employeesResponse, fetch],
    );

    return useMemo(
        () => ({
            employeesResponse,
            deleteEmployee,
            editEmployee,
            createEmployee,
        }),
        [employeesResponse, deleteEmployee, editEmployee, createEmployee],
    );
}
