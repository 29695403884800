import { Grid } from "@mui/material";

import { CalendarList } from "./CalendarList";
import { CustomerDropdown } from "../../../components/Controls/CustomerDropdown";
import { useCurrentCustomer } from "../../../context/AppContext";
import { useCalendarService } from "../../../service/CalendarService";

export function CalendarIndexCustomerPage() {
    const currentCustomer = useCurrentCustomer();

    const { eventsResponse, addEvent, editEvent, deleteEvent, downloadIcal } =
        useCalendarService(currentCustomer?.customerLocationId);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} flexGrow={0}>
                <Grid container>
                    <Grid item xs={3}>
                        <CustomerDropdown />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} flexGrow={1}>
                <CalendarList
                    customerLocationId={currentCustomer?.customerLocationId}
                    response={eventsResponse}
                    addEvent={addEvent}
                    editEvent={editEvent}
                    deleteEvent={deleteEvent}
                    downloadIcal={downloadIcal}
                />
            </Grid>
        </Grid>
    );
}
