import { ButtonGroup, Grid, LinearProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getControlPointListActions } from "./Actions";
import { AddDialog } from "./AddDialog";
import { ApplicationPagingDataGrid } from "../../components/Controls/DataGrid/Paging";
import { CustomerPageTitle } from "../../components/CustomerPageTitle";
import { use404Page } from "../../hooks/use404Page";
import { useCustomerLocationIdFilter } from "../../hooks/useCustomerLocationIdFilter";
import { useFormTranslator } from "../../hooks/useFormTranslator";
import { usePermissions } from "../../hooks/usePermissions";
import { ControlPointForOverview } from "../../models/controlpoint/ControlPoint";
import { Permissions } from "../../permissions";
import { useControlPointService } from "../../service/ControlPointService";
import { formatCustomerName } from "../../utils/customer.utils";

export function ControlPointsPage() {
    const { customerId, locationId } = useCustomerLocationIdFilter();

    const { t } = useTranslation();
    const translator = useFormTranslator("controlpoint");
    const { hasPermission } = usePermissions();

    const { controlPointsResponse, addControlPoints, editControlPoint } =
        useControlPointService(locationId);

    use404Page(controlPointsResponse.response.error !== undefined);

    const canEdit = hasPermission(Permissions.CalendarManage);

    const columns = useMemo<GridColDef<ControlPointForOverview>[]>(
        () => [
            {
                field: "actions",
                type: "actions",
                getActions: params =>
                    getControlPointListActions({
                        row: params.row,
                        canEdit,
                        editControlPointCallback: editControlPoint,
                        t,
                    }),
            },
            {
                field: "name",
                headerName: translator.getField("name").getName(),
                flex: 1,
            },
            {
                field: "customerLocationId",
                headerName: translator.getField("customerLocationId").getName(),
                flex: 1,
                valueGetter: row => formatCustomerName(row.row),
            },
            {
                field: "type",
                headerName: translator.getField("type").getName(),
                flex: 1,
                valueGetter: row =>
                    translator.getField("type").getEnum(row.value),
            },
            {
                field: "sortId",
                headerName: translator.getField("sortId").getName(),
                flex: 1,
                valueGetter: row => row.row.sort,
            },
            {
                field: "room",
                headerName: translator.getField("room").getName(),
                flex: 1,
            },
            {
                field: "exactLocation",
                headerName: translator.getField("exactLocation").getName(),
                flex: 1,
            },
        ],
        [canEdit, editControlPoint, t, translator],
    );

    return (
        <>
            {customerId && locationId && (
                <CustomerPageTitle
                    headerPrefix={t("pages.controlpoint.header_customer")}
                    customerId={customerId}
                    locationId={locationId}
                />
            )}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {canEdit && (
                            <Grid item>
                                <ButtonGroup>
                                    <AddDialog
                                        addControlPointsCallback={
                                            addControlPoints
                                        }
                                        customerLocationId={locationId}
                                    />
                                </ButtonGroup>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {controlPointsResponse.response.data ? (
                        <ApplicationPagingDataGrid
                            pagination={controlPointsResponse.response.data}
                            paginationControls={
                                controlPointsResponse.paginationControls
                            }
                            columns={columns}
                            getRowId={row => row.id}
                            removeColumns={
                                locationId ? ["customerLocationId"] : undefined
                            }
                            disableRowSelectionOnClick
                            autoHeight
                        />
                    ) : (
                        <LinearProgress />
                    )}
                </Grid>
            </Grid>
        </>
    );
}
