import {
    Business,
    CalendarMonth,
    Dashboard,
    HouseSiding,
    Hub,
    InsertDriveFile,
    LibraryAdd,
    LibraryBooks,
    ManageAccounts,
    PeopleAlt,
    SvgIconComponent,
} from "@mui/icons-material";
import { ComponentType } from "react";

import { Role } from "./models/user/Role";
import { UserClaims } from "./models/user/UserClaims";
import { AccountPage } from "./pages/Account";
import {
    CalendarIndexCustomerPage,
    CalendarIndexEmployeePage,
} from "./pages/Calendar";
import { CompanyPage } from "./pages/Company";
import { ControlPointsPage } from "./pages/ControlPoints";
import {
    CustomerIndexPage,
    CustomerLocationViewPage,
    CustomerViewPage,
} from "./pages/Customer";
import { DashboardPage } from "./pages/Dashboard";
import { DocumentsPageCustomer } from "./pages/Document/List/DocumentsPageCustomer";
import { DocumentsPageEmployee } from "./pages/Document/List/DocumentsPageEmployee";
import {
    FloorPlanIndexCustomerPage,
    FloorPlanIndexEmployeePage,
    FloorPlanViewPage,
} from "./pages/FloorPlan";
import { WorkListPage } from "./pages/WorkList";
import {
    WorkOrderIndexCustomerPage,
    WorkOrderIndexEmployeePage,
} from "./pages/WorkOrder";
import { Permission, Permissions } from "./permissions";

export interface ChildPage {
    title?: string;
    path: string;
    element: ComponentType;
}

export interface Page extends ChildPage {
    name: string;
    icon?: SvgIconComponent;
    isDashboard?: true;
    isNavigation?: true;
    navigationDivider?: true;
    permission: Permission;
    children?: ChildPage[];
}

const pages: Page[] = [
    {
        name: "pages.dashboard.header",
        path: "dashboard",
        element: DashboardPage,
        icon: Dashboard,
        isNavigation: true,
        permission: Permissions.DocumentsView,
    },
    {
        name: "pages.calendar.header",
        path: "calendar",
        element: CalendarIndexEmployeePage,
        icon: CalendarMonth,
        isDashboard: true,
        isNavigation: true,
        permission: Permissions.CalendarView,
        children: [
            {
                path: "customer/:customerId/location/:locationId",
                element: CalendarIndexEmployeePage,
            },
        ],
    },
    {
        name: "pages.calendar.header",
        path: "calendar/me",
        element: CalendarIndexCustomerPage,
        icon: CalendarMonth,
        isDashboard: true,
        isNavigation: true,
        permission: Permissions.CalendarCustomerView,
    },
    {
        name: "pages.customer.header",
        path: "customers",
        element: CustomerIndexPage,
        icon: PeopleAlt,
        isDashboard: true,
        isNavigation: true,
        permission: Permissions.CustomersView,
        children: [
            {
                title: "pages.customer.header_view",
                path: ":customerId",
                element: CustomerViewPage,
            },
            {
                title: "pages.customerLocation.header_view",
                path: ":customerId/locations/:locationId",
                element: CustomerLocationViewPage,
            },
        ],
    },
    {
        name: "pages.document.header",
        path: "documents",
        element: DocumentsPageEmployee,
        icon: InsertDriveFile,
        isDashboard: true,
        isNavigation: true,
        permission: Permissions.DocumentsView,
        children: [
            {
                path: "customer/:customerId/location/:locationId",
                element: DocumentsPageEmployee,
            },
        ],
    },
    {
        name: "pages.document.header",
        path: "documents/me",
        element: DocumentsPageCustomer,
        icon: InsertDriveFile,
        isDashboard: true,
        isNavigation: true,
        permission: Permissions.DocumentsCustomerView,
    },
    {
        name: "pages.floorplan.header",
        path: "floorplans",
        element: FloorPlanIndexEmployeePage,
        icon: HouseSiding,
        isDashboard: true,
        isNavigation: true,
        permission: Permissions.FloorPlansView,
        children: [
            {
                path: "customer/:customerId/location/:locationId",
                element: FloorPlanIndexEmployeePage,
            },
            {
                path: ":floorPlanId",
                element: FloorPlanViewPage,
            },
        ],
    },
    {
        name: "pages.floorplan.header",
        path: "floorplans/me",
        element: FloorPlanIndexCustomerPage,
        icon: HouseSiding,
        isDashboard: true,
        isNavigation: true,
        permission: Permissions.FloorPlansCustomerView,
    },
    {
        name: "pages.controlpoint.header",
        path: "controlpoints",
        element: ControlPointsPage,
        icon: Hub,
        isDashboard: true,
        isNavigation: true,
        permission: Permissions.ControlPointsView,
        children: [
            {
                path: "customer/:customerId/location/:locationId",
                element: ControlPointsPage,
            },
        ],
    },
    {
        name: "pages.workorder.header",
        path: "workorders",
        element: WorkOrderIndexEmployeePage,
        icon: LibraryBooks,
        isDashboard: true,
        isNavigation: true,
        permission: Permissions.WorkOrdersView,
        children: [
            {
                path: "customer/:customerId/location/:locationId",
                element: WorkOrderIndexEmployeePage,
            },
        ],
    },
    {
        name: "pages.workorder.header",
        path: "workorders/me",
        element: WorkOrderIndexCustomerPage,
        icon: LibraryBooks,
        isDashboard: true,
        isNavigation: true,
        permission: Permissions.WorkOrdersCustomerView,
    },
    {
        name: "pages.worklist.header",
        path: "worklists",
        element: WorkListPage,
        icon: LibraryAdd,
        isNavigation: true,
        permission: Permissions.WorkListsView,
    },
    {
        name: "pages.company.header",
        path: "company",
        element: CompanyPage,
        icon: Business,
        isNavigation: true,
        navigationDivider: true,
        permission: Permissions.CompanyView,
    },
    {
        name: "pages.account.header",
        path: "account",
        element: AccountPage,
        icon: ManageAccounts,
        isNavigation: true,
        navigationDivider: true,
        permission: Permissions.AccountView,
    },
];

export class PagesFinder {
    private static getPagesForRoles(role?: Role) {
        return pages.filter(
            f =>
                role === undefined ||
                f.permission.roles.findIndex(f => f === role) !== -1,
        );
    }

    static getPages(
        idToken: UserClaims | undefined,
        forceAll?: boolean,
    ): Page[] {
        const force = forceAll ?? false;

        let isLoggedIn = false;
        let role: Role | undefined;
        if (idToken !== undefined) {
            isLoggedIn = true;
            role = idToken.role;
        }

        if (isLoggedIn || force) {
            return this.getPagesForRoles(role);
        }

        return [];
    }
}
