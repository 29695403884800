import { Delete } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CreateControlPointSortDialog } from "./CreateControlPointSortDialog";
import { ConfirmButton } from "../../../../components/Controls/ConfirmButton";
import { ApplicationStandardDataGrid } from "../../../../components/Controls/DataGrid/Standard";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { usePermissions } from "../../../../hooks/usePermissions";
import { ControlPointSort } from "../../../../models/controlpoint/ControlPoint";
import { Permissions } from "../../../../permissions";
import { useCompanyControlPointSortService } from "../../../../service/CompanyService";

export function ControlPointSortInfo() {
    const { t } = useTranslation();
    const companyService = useCompanyControlPointSortService();
    const translator = useFormTranslator("company.controlpointsort");

    const permissions = usePermissions();
    const canEdit = permissions.hasPermission(Permissions.CompanyViewManage);

    const columns = useMemo<GridColDef<ControlPointSort>[]>(
        () => [
            {
                field: "actions",
                type: "actions",
                getActions: params => [
                    <ConfirmButton
                        key={`company_controlpoint_sort_delete_${params.id}`}
                        confirmText={t(
                            "pages.company.controlpointsort.warning_remove",
                        )}
                        onConfirm={() =>
                            companyService.deleteControlPointSort(params.row.id)
                        }
                        color="error"
                        icon
                        isDanger
                    >
                        <Delete />
                    </ConfirmButton>,
                ],
                renderHeader: () => (
                    <CreateControlPointSortDialog
                        createControlPointSortCallback={
                            companyService.createControlPointSort
                        }
                    />
                ),
            },
            {
                field: "name",
                headerName: translator.getField("name").getName(),
                flex: 1,
            },
            {
                field: "color",
                headerName: translator.getField("color").getName(),
                flex: 1,
                renderCell: params => (
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                    >
                        <Box
                            sx={{
                                width: "1em",
                                height: "1em",
                                padding: 0,
                                borderRadius: "0.25em",
                                backgroundColor: params.row.color,
                                border: "1px solid black",
                            }}
                        />
                        <Typography>{params.row.color}</Typography>
                    </Box>
                ),
            },
        ],
        [companyService, t, translator],
    );

    return (
        <Card>
            <CardHeader title={t("pages.company.controlpointsort.header")} />
            <CardContent>
                <Typography sx={{ paddingBottom: 3 }}>
                    {t("pages.company.controlpointsort.description")}
                </Typography>
                <ApplicationStandardDataGrid
                    columns={columns}
                    rows={companyService.controlPointSortsResponse.data ?? []}
                    loading={
                        companyService.controlPointSortsResponse.isValidating
                    }
                    getRowId={item => item.id}
                    removeColumns={canEdit ? [] : ["actions"]}
                    autoHeight
                    disableRowSelectionOnClick
                    pagination
                    pageSizeOptions={[5]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                        sorting: {
                            sortModel: [
                                {
                                    field: "name",
                                    sort: "asc",
                                },
                            ],
                        },
                    }}
                />
            </CardContent>
        </Card>
    );
}
