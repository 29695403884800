import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CustomerField } from "./CustomerField";
import { Customer } from "../../../../models/customer/Customer";

interface CustomerDataProps {
    customer: Customer | undefined;
}

export function CustomerData({ customer }: CustomerDataProps) {
    const { t } = useTranslation();

    return (
        <Card>
            <CardHeader title={t("pages.customer.view.headers.base_info")} />
            <CardContent>
                <Stack spacing={2}>
                    <CustomerField field="type" value={customer?.type} enum />
                    <CustomerField field="name" value={customer?.name} />
                    <CustomerField
                        field="email"
                        value={customer?.email}
                        link={`mailto:${customer?.email}`}
                    />
                    <CustomerField
                        field="phoneNumber"
                        value={customer?.phoneNumber}
                        link={`tel:${customer?.phoneNumber}`}
                    />
                    <CustomerField
                        field="mobileNumber"
                        value={customer?.mobileNumber}
                        link={`tel:${customer?.mobileNumber}`}
                    />
                </Stack>
            </CardContent>
        </Card>
    );
}
