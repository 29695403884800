import { OidcConfiguration } from "@axa-fr/react-oidc";

import { Environment } from "./env";

export function getOidcConfiguration(): OidcConfiguration {
    const data: OidcConfiguration = {
        client_id: Environment.OIDC_CLIENT_ID,
        authority: Environment.OIDC_AUTHORITY,
        redirect_uri: window.location.origin + "/authentication/callback",
        silent_login_uri:
            window.location.origin + "/authentication/silent-login",
        silent_redirect_uri:
            window.location.origin + "/authentication/silent-callback",
        scope: "openid profile email roles offline_access full.access",
        service_worker_relative_url: "/OidcServiceWorker.js",
        service_worker_only: true,
    };
    return data;
}
