import { Delete } from "@mui/icons-material";
import { TFunction } from "react-i18next";

import { ConfirmButton } from "../../../components/Controls/ConfirmButton";
import { FloorPlanForOverview } from "../../../models/floorplan/FloorPlan";

interface Options {
    row: FloorPlanForOverview;
    canEdit: boolean;
    deleteFloorPlan: (id: number) => Promise<void>;
    t: TFunction;
}

export function getFloorPlansListActions({
    row,
    canEdit,
    deleteFloorPlan,
    t,
}: Options) {
    const actions = [];
    if (canEdit) {
        actions.push(
            <ConfirmButton
                key={`floorplans_action_delete_${row.id}`}
                confirmText={t("pages.floorplan.edit.warning_remove")}
                onConfirm={() => deleteFloorPlan(row.id)}
                color="error"
                icon
                isDanger
            >
                <Delete />
            </ConfirmButton>,
        );
    }
    return actions;
}
