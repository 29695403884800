import { Box, Link, Typography } from "@mui/material";

import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { Customer } from "../../../../models/customer/Customer";

interface CustomerFieldProps {
    field: keyof Customer;
    link?: string;
    value?: string;
    enum?: boolean;
    italic?: boolean;
}

export function CustomerField({
    field,
    link,
    value,
    enum: isEnum = false,
    italic = false,
}: CustomerFieldProps) {
    const translator = useFormTranslator("customer");

    const internalValue =
        isEnum && value ? translator.getField(field).getEnum(value) : value;

    return (
        <Box>
            <Typography variant="subtitle1">
                {translator.getField(field).getName()}
            </Typography>
            {internalValue ? (
                link ? (
                    <Link
                        variant="body2"
                        href={link}
                        style={{
                            fontStyle: italic ? "italic" : "initial",
                        }}
                    >
                        {internalValue}
                    </Link>
                ) : (
                    <Typography
                        variant="body2"
                        style={{ fontStyle: italic ? "italic" : "initial" }}
                    >
                        {internalValue}
                    </Typography>
                )
            ) : (
                <Typography variant="body2">-</Typography>
            )}
        </Box>
    );
}
