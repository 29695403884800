import { TFunction } from "react-i18next";

import { EditDialog } from "./EditDialog";
import { ControlPointForOverview } from "../../models/controlpoint/ControlPoint";
import { EditControlPointCallback } from "../../service/ControlPointService";

interface Options {
    row: ControlPointForOverview;
    canEdit: boolean;
    editControlPointCallback: EditControlPointCallback;
    t: TFunction;
}

export function getControlPointListActions({
    row,
    canEdit,
    editControlPointCallback,
    t,
}: Options) {
    const actions = [];
    if (canEdit) {
        actions.push(
            <EditDialog
                key={`controlpoint_action_edit_${row.id}`}
                controlPoint={row}
                editControlPointCallback={editControlPointCallback}
            />,
        );
    }
    return actions;
}
