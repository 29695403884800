import { OidcSecure } from "@axa-fr/react-oidc";
import { useTranslation } from "react-i18next";
import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes,
} from "react-router-dom";

import { Layout } from "./components/Layout";
import { PageRoot } from "./components/Layout/PageRoot";
import { PagesFinder } from "./pages";
import { NotFoundPage } from "./pages/Errors/NotFound";

export function Router() {
    const { t } = useTranslation();

    const pages = PagesFinder.getPages(undefined, true);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    index
                    element={<Navigate to="/app/dashboard" replace />}
                />

                <Route
                    path="app"
                    element={
                        <OidcSecure>
                            <Layout>
                                <Outlet />
                            </Layout>
                        </OidcSecure>
                    }
                >
                    {pages.map(page => {
                        const RouteElement = page.element;
                        return (
                            <Route key={`page_${page.path}`} path={page.path}>
                                <Route
                                    index
                                    element={
                                        <PageRoot title={t(page.name)}>
                                            <RouteElement />
                                        </PageRoot>
                                    }
                                />
                                {page.children?.map(childPage => {
                                    const ChildRouteElement = childPage.element;
                                    return (
                                        <Route
                                            key={`page_${page.path}`}
                                            path={childPage.path}
                                            element={
                                                <PageRoot
                                                    title={t(
                                                        childPage.title ??
                                                            page.name,
                                                    )}
                                                >
                                                    <ChildRouteElement />
                                                </PageRoot>
                                            }
                                        />
                                    );
                                })}
                            </Route>
                        );
                    })}
                    <Route path="404" element={<NotFoundPage />} />
                </Route>

                <Route path="*" element={<Navigate to="/app/404" replace />} />
            </Routes>
        </BrowserRouter>
    );
}
