import { ButtonGroup, Grid, LinearProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ApplicationPagingDataGrid } from "../../../../components/Controls/DataGrid/Paging";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { usePermissions } from "../../../../hooks/usePermissions";
import { CustomerOverview } from "../../../../models/customer/Customer";
import { Permissions } from "../../../../permissions";
import { useCustomerListService } from "../../../../service/CustomerService";
import { AddCustomerDialog } from "../EditDialog/AddCustomerDialog";

export function CustomerIndexPage() {
    const navigate = useNavigate();
    const translator = useFormTranslator("customer");
    const { hasPermission } = usePermissions();

    const { customersResponse, addCustomer } = useCustomerListService();

    const columns = useMemo<GridColDef<CustomerOverview>[]>(
        () => [
            {
                field: "name",
                headerName: translator.getField("name").getName(),
                flex: 1,
            },
            {
                field: "type",
                headerName: translator.getField("type").getName(),
                flex: 1,
                valueGetter: row =>
                    translator.getField("type").getEnum(row.row.type),
            },
        ],
        [translator],
    );

    const canEdit = hasPermission(Permissions.CustomersView);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {canEdit && (
                    <ButtonGroup>
                        <AddCustomerDialog addCustomer={addCustomer} />
                    </ButtonGroup>
                )}
            </Grid>
            <Grid item xs={12}>
                {customersResponse.response.data ? (
                    <ApplicationPagingDataGrid
                        pagination={customersResponse.response.data}
                        paginationControls={
                            customersResponse.paginationControls
                        }
                        columns={columns}
                        getRowId={row => row.id}
                        autoHeight
                        onCellClick={params =>
                            navigate(`/app/customers/${params.row.id}`)
                        }
                    />
                ) : (
                    <LinearProgress />
                )}
            </Grid>
        </Grid>
    );
}
