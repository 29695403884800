import { Card, CardContent, CardHeader, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ColorPicker } from "../../../../components/Controls/ColorPicker";
import { useCompanyMetadata } from "../../../../context/AppContext";

export function BaseInfo() {
    const { t } = useTranslation();
    const metadata = useCompanyMetadata();

    return (
        <Card>
            <CardHeader title={t("pages.company.base.header")} />
            <CardContent>
                {metadata && (
                    <Stack spacing={2}>
                        <TextField
                            label={t("pages.company.base.company_name")}
                            value={metadata.name}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <ColorPicker
                            label={t("pages.company.base.theme_color")}
                            color={metadata.themeColor}
                            disabled
                        />
                        <ColorPicker
                            label={t("pages.company.base.text_color")}
                            color={metadata.textColor}
                            disabled
                        />
                    </Stack>
                )}
            </CardContent>
        </Card>
    );
}
