import { ContentCopy } from "@mui/icons-material";
import {
    Alert,
    ButtonBase,
    Grid,
    Paper,
    Snackbar,
    Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { usePopupContext } from "../../../../context/PopupContext";
import { useOpenState } from "../../../../hooks/useOpenState";

interface RecoveryCodesContentProps {
    recoveryCodes: string[];
}

export function useRecoveryCodesPopup() {
    const { t } = useTranslation();
    const popupContext = usePopupContext();

    const showPopup = useCallback(
        (recoveryCodes: string[]) => {
            popupContext.showPopup({
                title: t(
                    "pages.account.twofactor.recovery_codes.dialog.response.header",
                ),
                text: t(
                    "pages.account.twofactor.recovery_codes.dialog.response.description",
                ),
                allowClose: false,
                content: <RecoveryCodesContent recoveryCodes={recoveryCodes} />,
            });
        },
        [popupContext, t],
    );

    return useMemo(() => ({ showPopup }), [showPopup]);
}

function RecoveryCodesContent({ recoveryCodes }: RecoveryCodesContentProps) {
    const { t } = useTranslation();
    const {
        state: copyClicked,
        open: openCopy,
        close: closeCopy,
    } = useOpenState();

    const startCopy = useCallback(() => {
        navigator.clipboard.writeText(recoveryCodes.join(" "));
        openCopy();
    }, [openCopy, recoveryCodes]);

    const stopCopy = useCallback(
        (_event: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === "clickaway") {
                return;
            }
            closeCopy();
        },
        [closeCopy],
    );

    return (
        <>
            <ButtonBase
                onClick={startCopy}
                sx={{
                    textAlign: "start",
                }}
            >
                <Paper variant="outlined" sx={{ position: "relative" }}>
                    <ContentCopy
                        sx={{
                            position: "absolute",
                            top: "0.5em",
                            right: "0.5em",
                        }}
                    />
                    <Grid container onClick={() => null}>
                        {recoveryCodes.map(item => (
                            <Grid
                                key={`recovery_code_item_${item}`}
                                item
                                xs={6}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{ paddingX: 1, paddingY: 0.5 }}
                                >
                                    {item}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </ButtonBase>
            <Snackbar
                open={copyClicked}
                autoHideDuration={6000}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                onClose={stopCopy}
            >
                <Alert
                    severity="success"
                    sx={{ flex: 1, flexDirection: "row" }}
                    onClose={stopCopy}
                >
                    {t(
                        "pages.account.twofactor.recovery_codes.dialog.response.copied",
                    )}
                </Alert>
            </Snackbar>
        </>
    );
}
