import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { WorkOrderList } from "./WorkOrderList";
import { CustomerPageTitle } from "../../../components/CustomerPageTitle";
import { useCustomerLocationIdFilter } from "../../../hooks/useCustomerLocationIdFilter";

export function WorkOrderIndexEmployeePage() {
    const { customerId, locationId } = useCustomerLocationIdFilter();
    const { t } = useTranslation();

    return (
        <>
            {customerId && locationId && (
                <CustomerPageTitle
                    headerPrefix={t("pages.workorder.header_customer")}
                    customerId={customerId}
                    locationId={locationId}
                />
            )}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <WorkOrderList customerLocationId={locationId} />
                </Grid>
            </Grid>
        </>
    );
}
