import { Divider, Grid } from "@mui/material";

import { AppInfo } from "./Blocks/AppInfo";
import { BaseInfo } from "./Blocks/BaseInfo";
import { ControlPointSortInfo } from "./Blocks/ControlPointSortInfo";
import { EmployeeInfo } from "./Blocks/EmployeeInfo";
import { PestInfo } from "./Blocks/PestInfo";

export function CompanyPage() {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={4} md={6} sm={12}>
                    <BaseInfo />
                </Grid>
                <Grid item lg={2} md={6} sm={12}>
                    <AppInfo />
                </Grid>
            </Grid>
            <Divider
                sx={{
                    marginY: 3,
                }}
            />
            <Grid container spacing={3}>
                <Grid item lg={4} md={6} sm={12}>
                    <PestInfo />
                </Grid>
                <Grid item lg={4} md={6} sm={12}>
                    <ControlPointSortInfo />
                </Grid>
            </Grid>
            <Divider
                sx={{
                    marginY: 3,
                }}
            />
            <Grid container spacing={3}>
                <Grid item lg={8} md={12} sm={12}>
                    <EmployeeInfo />
                </Grid>
            </Grid>
        </>
    );
}
