import { ArrowLeft } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Grid } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { CustomerLocationData } from "./CustomerLocationData";
import { ConfirmButton } from "../../../../components/Controls/ConfirmButton";
import { ErrorsCard } from "../../../../components/Layout/ErrorsCard";
import { useCustomerLocationIdFilter } from "../../../../hooks/useCustomerLocationIdFilter";
import {
    ServiceResponse,
    useResponseCombiner,
} from "../../../../hooks/useServiceResponse";
import { useCustomerLocationViewService } from "../../../../service/CustomerLocationService";
import { EditCustomerLocationDialog } from "../EditDialog/EditCustomerLocationDialog";

interface CustomerLocationViewContentProps {
    customerId: number;
    locationId: number;
}

function CustomerViewErrorRedirect() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/app/customers");
    }, [navigate]);
    return <></>;
}

function CustomerLocationViewContent({
    customerId,
    locationId,
}: CustomerLocationViewContentProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        customerResponse,
        customerLocationResponse,
        editCustomerLocation,
        deleteCustomerLocation,
    } = useCustomerLocationViewService(customerId, locationId);

    const responseData = useMemo(
        () => ({
            Customer: customerResponse,
            CustomerLocation: customerLocationResponse,
        }),
        [customerLocationResponse, customerResponse],
    );
    const response = useResponseCombiner(responseData);

    const deleteInternal = useCallback(
        () =>
            deleteCustomerLocation().finally(() =>
                navigate(
                    (response.data.Customer?.locationCount ?? 0) > 1
                        ? `/app/customers/${customerId}`
                        : "/app/customers",
                ),
            ),
        [
            deleteCustomerLocation,
            navigate,
            response.data.Customer?.locationCount,
            customerId,
        ],
    );

    return (
        <ServiceResponse
            response={response}
            options={{ errorComponent: ErrorsCard }}
        >
            <Helmet
                title={`${t("pages.customerLocation.header_view")} - ${
                    response.data.CustomerLocation?.name
                }`}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {response.data.Customer &&
                        response.data.CustomerLocation && (
                            <Box sx={{ display: "flex" }}>
                                <ButtonGroup sx={{ marginRight: 1 }}>
                                    <Button
                                        onClick={() => navigate(-1)}
                                        size="small"
                                    >
                                        <ArrowLeft />
                                    </Button>
                                </ButtonGroup>
                                <ButtonGroup sx={{ marginRight: 1 }}>
                                    <EditCustomerLocationDialog
                                        customerType={
                                            response.data.Customer.type
                                        }
                                        customerLocation={
                                            response.data.CustomerLocation
                                        }
                                        editCustomerLocation={
                                            editCustomerLocation
                                        }
                                    />
                                    <ConfirmButton
                                        confirmText={
                                            response.data.Customer
                                                .locationCount > 1
                                                ? t(
                                                      "pages.customerLocation.edit.warning_remove",
                                                  )
                                                : t(
                                                      "pages.customerLocation.edit.warning_remove_full",
                                                  )
                                        }
                                        onConfirm={deleteInternal}
                                        color="error"
                                        isDanger
                                    >
                                        {t("common.remove", {
                                            resource: t(
                                                "pages.customerLocation.resource",
                                            ),
                                        })}
                                    </ConfirmButton>
                                </ButtonGroup>
                                <ButtonGroup>
                                    <Button
                                        onClick={() =>
                                            navigate(
                                                `/app/calendar/customer/${customerId}/location/${locationId}`,
                                            )
                                        }
                                    >
                                        {t("pages.calendar.header")}
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            navigate(
                                                `/app/floorplans/customer/${customerId}/location/${locationId}`,
                                            )
                                        }
                                    >
                                        {t("pages.floorplan.header")}
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            navigate(
                                                `/app/documents/customer/${customerId}/location/${locationId}`,
                                            )
                                        }
                                    >
                                        {t("pages.document.header")}
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            navigate(
                                                `/app/controlpoints/customer/${customerId}/location/${locationId}`,
                                            )
                                        }
                                    >
                                        {t("pages.controlpoint.header")}
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            navigate(
                                                `/app/workorders/customer/${customerId}/location/${locationId}`,
                                            )
                                        }
                                    >
                                        {t("pages.workorder.header")}
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        )}
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <CustomerLocationData
                                customerLocation={
                                    response.data.CustomerLocation
                                }
                            />
                        </Grid>
                        <Grid item xs={8} />
                    </Grid>
                </Grid>
            </Grid>
        </ServiceResponse>
    );
}

export function CustomerLocationViewPage() {
    const { customerId, locationId } = useCustomerLocationIdFilter();

    if (customerId === undefined || locationId === undefined) {
        return <CustomerViewErrorRedirect />;
    } else {
        return (
            <CustomerLocationViewContent
                customerId={customerId}
                locationId={locationId}
            />
        );
    }
}
