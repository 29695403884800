import { Card, CardContent, CardHeader, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useCurrentUser } from "../../../../hooks/useCurrentUser";

export function UserInfo() {
    const { t } = useTranslation();
    const currentUser = useCurrentUser();

    return (
        <Card>
            <CardHeader title={t("pages.account.user.header")} />
            <CardContent>
                <Stack spacing={2}>
                    <TextField
                        label={t("pages.account.user.name")}
                        value={currentUser?.name}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        label={t("pages.account.user.email")}
                        value={currentUser?.email}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        label={t("pages.account.user.role.name")}
                        value={t(
                            `pages.account.user.role.values.${currentUser?.role}`,
                        )}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Stack>
            </CardContent>
        </Card>
    );
}
