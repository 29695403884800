import {
    Box,
    CircularProgress,
    CssBaseline,
    ThemeProvider,
    createTheme,
    useTheme,
} from "@mui/material";
import { PropsWithChildren, useMemo } from "react";
import useSWRImmutable from "swr/immutable";

import { DrawerLayout } from "./Drawer";
import { AppContextProvider } from "../../context/AppContext";
import { PopupContextProvider } from "../../context/PopupContext";
import { useLocales } from "../../hooks/useLocales";
import { CompanyMetadata } from "../../models/metadata/Metadata";

export function Layout({ children }: PropsWithChildren) {
    const { libraries } = useLocales();
    const theme = useTheme();

    const themeWithLocale = useMemo(
        () => createTheme(theme, libraries.material),
        [libraries, theme],
    );

    const metadataResult = useSWRImmutable<CompanyMetadata>("metadata");

    if (metadataResult.data === undefined) {
        return <CircularProgress />;
    }

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <ThemeProvider theme={themeWithLocale}>
                <AppContextProvider metadata={metadataResult.data}>
                    <PopupContextProvider>
                        <DrawerLayout
                            appName={metadataResult.data.name}
                            appColor={metadataResult.data.themeColor}
                        >
                            {children}
                        </DrawerLayout>
                    </PopupContextProvider>
                </AppContextProvider>
            </ThemeProvider>
        </Box>
    );
}
