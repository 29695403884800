import i18n, { ResourceLanguage } from "i18next";
import { initReactI18next } from "react-i18next";
import { setLocale } from "yup";

import { DefaultLocale, SupportedLocales } from "./hooks/useLocales";
import transTranslationNL from "./translations/translation_nl.json";

const resources: Partial<Record<SupportedLocales, ResourceLanguage>> = {
    nl: {
        translation: transTranslationNL,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: DefaultLocale,
    fallbackLng: DefaultLocale,
    interpolation: {
        escapeValue: false,
    },
});

setLocale({
    mixed: {
        required: "required",
    },
    string: {
        email: "email",
    },
});

export default i18n;
