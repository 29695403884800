import { LinearProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getFloorPlansListActions } from "./Actions";
import {
    ApplicationPagingDataGrid,
    ApplicationPagingDataGridProps,
} from "../../../components/Controls/DataGrid/Paging";
import { useFormTranslator } from "../../../hooks/useFormTranslator";
import { usePermissions } from "../../../hooks/usePermissions";
import { Error } from "../../../models/error/Error";
import { FloorPlanForOverview } from "../../../models/floorplan/FloorPlan";
import { Permissions } from "../../../permissions";
import { DeleteFloorPlanCallback } from "../../../service/FloorPlanService";
import { UsePaginationServiceResult } from "../../../service/PaginationService";
import { formatCustomerName } from "../../../utils/customer.utils";

interface FloorPlansListProps {
    customerLocationId?: number;
    response: UsePaginationServiceResult<FloorPlanForOverview, Error>;
    onRowClick?: Pick<
        ApplicationPagingDataGridProps<FloorPlanForOverview>,
        "onRowClick"
    >["onRowClick"];
    deleteFloorPlan: DeleteFloorPlanCallback;
}

export function FloorPlansList({
    customerLocationId,
    response,
    onRowClick,
    deleteFloorPlan,
}: FloorPlansListProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("floorplan");
    const { hasPermission } = usePermissions();

    const canEdit = hasPermission(Permissions.FloorPlansManage);

    const deleteFloorPlanInternal = useCallback(
        async (floorPlanId: number) => {
            if (!canEdit) {
                return;
            }
            await deleteFloorPlan(floorPlanId);
        },
        [canEdit, deleteFloorPlan],
    );

    const columns = useMemo<GridColDef<FloorPlanForOverview>[]>(
        () => [
            {
                field: "actions",
                type: "actions",
                getActions: params =>
                    getFloorPlansListActions({
                        row: params.row,
                        canEdit,
                        deleteFloorPlan: deleteFloorPlanInternal,
                        t,
                    }),
            },
            {
                field: "customerLocationId",
                headerName: translator.getField("customerLocationId").getName(),
                flex: 1,
                valueGetter: params => formatCustomerName(params.row),
            },
            {
                field: "name",
                headerName: translator.getField("name").getName(),
                flex: 1,
            },
        ],
        [canEdit, deleteFloorPlanInternal, t, translator],
    );

    return (
        <>
            {response.response.data ? (
                <ApplicationPagingDataGrid
                    pagination={response.response.data}
                    paginationControls={response.paginationControls}
                    columns={columns}
                    getRowId={row => row.id}
                    autoHeight
                    onRowClick={onRowClick}
                    disableRowSelectionOnClick
                    removeColumns={
                        customerLocationId ? ["customerLocationId"] : undefined
                    }
                />
            ) : (
                <LinearProgress />
            )}
        </>
    );
}
