export interface ChangePasswordRequest {
    currentPassword: string;
    newPassword: string;
    repeatNewPassword: string;
}

export const PASSWORD_MISMATCH_ERROR = "PasswordMismatch";
export const PASSWORD_MIN_LENGTH = 10;

export interface ChangePasswordResponse {
    success: boolean;
    error: string;
}
