export enum SortDirection {
    None = "None",
    Ascending = "Ascending",
    Descending = "Descending",
}

export interface Pagination {
    page: number;
    itemsPerPage: number;
    itemsPerPageOptions: number[];
    sort: string | undefined;
    sortDirection: SortDirection;
}
