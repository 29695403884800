import fileDownload from "js-file-download";
import { useCallback, useMemo, useState } from "react";

import { usePermissions } from "../../../../hooks/usePermissions";
import { useResponseCombiner } from "../../../../hooks/useServiceResponse";
import { Document, Folder } from "../../../../models/document/Document";
import { Permissions } from "../../../../permissions";
import { useDocumentService } from "../../../../service/DocumentService";
import { directoriesMatch } from "../util";

export const DIRECTORY_SEPARATOR = "/";

export type DownloadDocumentCallback = (document: Document) => Promise<void>;
export type DeleteDocumentCallback = (document: Document) => Promise<void>;

export function useDocumentsPage(customerId: number | undefined) {
    const { hasPermission } = usePermissions();
    const canEdit = hasPermission(Permissions.DocumentsManage);

    const [downloading, setDownloading] = useState(false);
    const [localDirectories, setLocalDirectories] = useState<string[][]>([]);
    const [currentDirectory, setCurrentDirectory] = useState<string[]>([]);

    const {
        documentsResponse,
        addDocuments: addDocument,
        downloadDocument: downloadDocumentInternal,
        deleteDocument: deleteDocumentInternal,
    } = useDocumentService(customerId);
    const responseData = useMemo(
        () => ({
            Documents: documentsResponse,
        }),
        [documentsResponse],
    );
    const response = useResponseCombiner(responseData);

    const depth = currentDirectory.length;

    const directoriesBreadCrumbs = currentDirectory.reduce<string[][]>(
        (prev, curr) => {
            const last = prev[prev.length - 1] ?? [];
            prev.push(last.concat(curr));
            return prev;
        },
        [],
    );

    const navigate = useMemo(
        () => ({
            home: () => {
                setCurrentDirectory([]);
            },
            up: () => {
                setCurrentDirectory(currentDirectory =>
                    currentDirectory.slice(0, currentDirectory.length - 1),
                );
            },
            directory: (directory: string[]) => {
                setCurrentDirectory(directory);
            },
        }),
        [],
    );

    const subDirectories = useMemo<Folder[]>(() => {
        if (response.data.Documents) {
            const dirs = response.data.Documents.filter(
                f =>
                    f.directory.length >= depth + 1 &&
                    directoriesMatch(f.directory, currentDirectory),
            ).map(m => m.directory[depth]);
            const directories = [
                ...new Set(
                    dirs.concat(
                        ...localDirectories.filter(
                            f =>
                                f.length >= depth + 1 &&
                                directoriesMatch(f, currentDirectory),
                        ),
                    ),
                ),
            ];

            return directories.map(m => ({
                id: -1,
                type: "folder",
                name: m,
                directory: currentDirectory.concat(m),
            }));
        }
        return [];
    }, [response.data.Documents, localDirectories, depth, currentDirectory]);

    const downloadDocument = useCallback<DeleteDocumentCallback>(
        async document => {
            if (downloading) {
                return;
            }

            setDownloading(true);
            const blob = await downloadDocumentInternal(document.id);
            setDownloading(false);

            fileDownload(blob, document.name);
        },
        [downloading, downloadDocumentInternal],
    );

    const deleteDocument = useCallback<DeleteDocumentCallback>(
        async document => {
            if (!canEdit) {
                return;
            }
            await deleteDocumentInternal(document.id);
        },
        [canEdit, deleteDocumentInternal],
    );

    const addLocalDirectory = useCallback((directory: string[]) => {
        setLocalDirectories(old => {
            old.push(directory);
            return [...old];
        });
    }, []);

    const clearLocalDirectories = useCallback(() => {
        setLocalDirectories([]);
    }, []);

    return {
        response,
        downloading,
        currentDirectory,
        directoriesBreadCrumbs,
        navigate,
        subDirectories,
        addLocalDirectory,
        clearLocalDirectories,
        addDocument,
        deleteDocument,
        downloadDocument,
    };
}
