import { useCallback, useMemo, useState } from "react";

export function useOpenState() {
    const [state, setState] = useState(false);

    const open = useCallback(() => setState(true), []);
    const close = useCallback(() => setState(false), []);

    return useMemo(
        () => ({
            state,
            open,
            close,
        }),
        [close, open, state],
    );
}
