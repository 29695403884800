import { OidcProvider } from "@axa-fr/react-oidc";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { SWRConfig } from "swr";

import { Router } from "./Router";
import { useSWRFetcher } from "./hooks/useFetch";
import { getOidcConfiguration } from "./oidc";

function ClientProvider(props: React.PropsWithChildren) {
    const { fetcher } = useSWRFetcher();

    return (
        <SWRConfig
            value={{
                fetcher,
                provider: () => new Map(),
            }}
        >
            {props.children}
        </SWRConfig>
    );
}

function ErrorComponent() {
    useEffect(() => {
        window.location.href = window.location.origin;
    }, []);

    return <></>;
}

export function App() {
    return (
        <OidcProvider
            configuration={getOidcConfiguration()}
            authenticatingErrorComponent={ErrorComponent}
        >
            <HelmetProvider>
                <ClientProvider>
                    <Router />
                </ClientProvider>
            </HelmetProvider>
        </OidcProvider>
    );
}
