import { useCallback, useMemo } from "react";
import useSWR, { SWRResponse } from "swr";

import { useFetch } from "../hooks/useFetch";
import { Document, DocumentsCreate } from "../models/document/Document";

export type AddDocumentsCallback = (body: DocumentsCreate) => Promise<void>;
export type DownloadDocumentCallback = (id: number) => Promise<Blob>;
export type DeleteDocumentCallback = (id: number) => Promise<void>;

interface DocumentServiceResult {
    documentsResponse: SWRResponse<Document[], Error>;
    addDocuments: AddDocumentsCallback;
    downloadDocument: DownloadDocumentCallback;
    deleteDocument: DeleteDocumentCallback;
}

const DocumentListPath = "documents";
const DocumentCreatePath = "documents/upload";
const DocumentDownloadPath = (id: number) => `documents/${id}/download`;
const DocumentDeletePath = (id: number) => `documents/${id}`;

export function useDocumentService(
    customerLocationId?: number,
): DocumentServiceResult {
    const fetch = useFetch();

    const params = useMemo<URLSearchParams | undefined>(() => {
        if (customerLocationId) {
            return new URLSearchParams({
                customerLocationId: customerLocationId.toString(),
            });
        }
    }, [customerLocationId]);

    const documentsResponse = useSWR<Document[], Error>(
        params ? DocumentListPath + "?" + params.toString() : DocumentListPath,
    );

    const addDocuments = useCallback<AddDocumentsCallback>(
        async body => {
            if (body.files.length === 0) {
                return;
            }

            const formData = new FormData();
            if (body.customerLocationId) {
                formData.append(
                    "customerLocationId",
                    body.customerLocationId.toString(),
                );
            }
            for (const file of body.files) {
                formData.append("files", file);
            }
            if (body.description) {
                formData.append("description", body.description);
            }
            for (const path of body.directory) {
                formData.append("directory[]", path);
            }
            await fetch.postForm(DocumentCreatePath, formData, true);
            await documentsResponse.mutate();
        },
        [fetch, documentsResponse],
    );

    const downloadDocument = useCallback<DownloadDocumentCallback>(
        id =>
            fetch
                .get(DocumentDownloadPath(id))
                .then(response => response.blob()),
        [fetch],
    );

    const deleteDocument = useCallback<DeleteDocumentCallback>(
        async id => {
            await fetch.delete(DocumentDeletePath(id));
            await documentsResponse.mutate();
        },
        [fetch, documentsResponse],
    );

    return useMemo(
        () => ({
            documentsResponse,
            addDocuments,
            downloadDocument,
            deleteDocument,
        }),
        [documentsResponse, addDocuments, downloadDocument, deleteDocument],
    );
}
