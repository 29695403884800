import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import { useRecoveryCodesPopup } from "./useRecoveryCodesPopup";
import { Code } from "../../../../components/Code";
import { FormikSubmitButton } from "../../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { useOpenState } from "../../../../hooks/useOpenState";
import {
    TwoFactorAuthEnableInformationResponse,
    TwoFactorAuthEnableRequest,
} from "../../../../models/account/TwoFactor";
import {
    TwoFactorAuthEnableCallback,
    useAccountService,
} from "../../../../service/AccountService";
import { useTwoFactorEnableSchema } from "../../schema";

interface EnableDialogProps {
    enableTwoFactorCallback: TwoFactorAuthEnableCallback;
}

const initialValues: TwoFactorAuthEnableRequest = {
    code: "",
};

export function EnableDialog({ enableTwoFactorCallback }: EnableDialogProps) {
    const { t } = useTranslation();
    const recoveryCodesPopup = useRecoveryCodesPopup();
    const accountService = useAccountService();

    const translator = useFormTranslator("account.twofactor");
    const { validationSchema, isRequired } =
        useTwoFactorEnableSchema(translator);

    const {
        state: open,
        open: openDialog,
        close: closeDialog,
    } = useOpenState();
    const [enableInformation, setEnableInformation] =
        useState<TwoFactorAuthEnableInformationResponse>();

    const openDialogInternal = useCallback(async () => {
        setEnableInformation(await accountService.getTwoFactorEnableInfo());
        openDialog();
    }, [accountService, openDialog]);

    const closeDialogInternal = useCallback(() => {
        setEnableInformation(undefined);
        closeDialog();
    }, [closeDialog]);

    return (
        <>
            <Button onClick={openDialogInternal} variant="contained">
                {t("pages.account.twofactor.enable.dialog.button")}
            </Button>
            <Dialog open={open} onClose={closeDialogInternal} fullWidth>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, formik) => {
                        try {
                            const result = await enableTwoFactorCallback(
                                values,
                            );
                            if (result.success) {
                                closeDialogInternal();
                                recoveryCodesPopup.showPopup(
                                    result.recoveryCodes,
                                );
                            } else {
                                formik.setFieldError(
                                    "code",
                                    t(
                                        "pages.account.twofactor.dialog_enable.code_invalid",
                                    ),
                                );
                                formik.setFieldValue("code", "", false);
                            }
                        } catch (ex) {
                            console.warn(ex);
                            formik.setSubmitting(false);
                            formik.resetForm();
                        }
                    }}
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t(
                                    "pages.account.twofactor.enable.dialog.header",
                                )}
                            </DialogTitle>
                            <DialogContent>
                                {enableInformation ? (
                                    <Stack spacing={2} marginY={1}>
                                        <Typography>
                                            {t(
                                                "pages.account.twofactor.enable.dialog.description_p_one",
                                            )}
                                        </Typography>

                                        <QRCode
                                            value={
                                                enableInformation.authenticatorUri
                                            }
                                        />

                                        <Typography>
                                            <Trans
                                                i18nKey="pages.account.twofactor.enable.dialog.description_p_two"
                                                values={{
                                                    code: enableInformation.sharedKey,
                                                }}
                                                components={[<Code />]}
                                            />
                                        </Typography>

                                        <Divider />

                                        <Typography>
                                            {t(
                                                "pages.account.twofactor.enable.dialog.description_p_three",
                                            )}
                                        </Typography>

                                        <FormikTextField
                                            formik={formik}
                                            field="code"
                                            translator={translator}
                                            required={isRequired("code")}
                                        />
                                    </Stack>
                                ) : (
                                    <></>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={closeDialogInternal}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
