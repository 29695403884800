import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink, matchPath, useLocation } from "react-router-dom";

type RouterLinkProps = React.PropsWithChildren<{
    to: string;
    text: string;
    icon?: JSX.Element;
}>;

export const RouterLink = (props: RouterLinkProps) => {
    const location = useLocation();
    const isCurrent = matchPath(
        {
            path: props.to,
            caseSensitive: false,
            end: false,
        },
        location.pathname,
    );

    return (
        <ListItemButton
            component={NavLink}
            to={props.to}
            selected={isCurrent !== null}
            sx={{
                paddingRight: "3em",
            }}
        >
            {props.icon !== undefined ? (
                <ListItemIcon>{props.icon}</ListItemIcon>
            ) : (
                ""
            )}
            <ListItemText primary={props.text} />
        </ListItemButton>
    );
};
