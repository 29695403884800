import { ArrowUpward, Home } from "@mui/icons-material";
import {
    Box,
    Breadcrumbs,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { useCallback, useState } from "react";

import { DocumentGrid } from "./DocumentGrid";
import {
    DIRECTORY_SEPARATOR,
    useDocumentsPage,
} from "./hooks/useDocumentsPage";
import { CustomerDropdown } from "../../../components/Controls/CustomerDropdown";
import { useCurrentCustomer } from "../../../context/AppContext";

export function DocumentsPageCustomer() {
    const currentCustomer = useCurrentCustomer();

    const [showCustomerSpecific, setCustomerSpecific] = useState(false);

    const {
        response,
        currentDirectory,
        directoriesBreadCrumbs,
        subDirectories,
        navigate,
        downloading,
        downloadDocument,
        deleteDocument,
        clearLocalDirectories,
    } = useDocumentsPage(
        showCustomerSpecific ? currentCustomer?.customerLocationId : undefined,
    );

    const setTabPage = useCallback(
        (showCustomerSpecific: boolean) => {
            setCustomerSpecific(showCustomerSpecific);
            clearLocalDirectories();
            navigate.home();
        },
        [clearLocalDirectories, navigate],
    );

    const disableDirectoryUp = currentDirectory.length === 0;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={showCustomerSpecific ? 1 : 0}
                        disabled={downloading}
                        onChange={(_, val) => setTabPage(val !== 0)}
                    >
                        <Tab label="Algemene documenten" />
                        <Tab label="Bedrijf documenten" />
                    </Tabs>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                    {showCustomerSpecific && (
                        <Grid item xs={3}>
                            <CustomerDropdown />
                        </Grid>
                    )}

                    <Grid item>
                        <IconButton
                            disabled={disableDirectoryUp}
                            onClick={navigate.up}
                            sx={{ marginRight: 1 }}
                        >
                            <ArrowUpward />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Breadcrumbs
                            separator={DIRECTORY_SEPARATOR}
                            maxItems={3}
                            aria-label="breadcrumb"
                        >
                            <Link
                                href="#"
                                underline="hover"
                                onClick={navigate.home}
                            >
                                <Home />
                            </Link>
                            {directoriesBreadCrumbs.map((directory, idx) => {
                                if (idx === directoriesBreadCrumbs.length - 1) {
                                    return (
                                        <Typography key={idx}>
                                            {directory[directory.length - 1]}
                                        </Typography>
                                    );
                                } else {
                                    return (
                                        <Link
                                            key={idx}
                                            underline="hover"
                                            onClick={() =>
                                                navigate.directory(directory)
                                            }
                                        >
                                            {directory[directory.length - 1]}
                                        </Link>
                                    );
                                }
                            })}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} flexGrow={1}>
                {response.data.Documents ? (
                    <DocumentGrid
                        documents={response.data.Documents}
                        folders={subDirectories}
                        currentDirectory={currentDirectory}
                        downloading={downloading}
                        onFolderClick={navigate.directory}
                        downloadDocument={downloadDocument}
                        deleteDocument={deleteDocument}
                    />
                ) : (
                    <LinearProgress />
                )}
            </Grid>
        </Grid>
    );
}
