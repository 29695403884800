import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useState,
} from "react";
import { useTranslation } from "react-i18next";

interface Popup {
    title: string;
    text: string;
    allowClose?: boolean;
    content?: JSX.Element;
}

type ShowPopupCallback = (popup: Popup) => void;

interface PopupContextInformation {
    showPopup: ShowPopupCallback;
}

const PopupContext = createContext<PopupContextInformation>({
    showPopup: () => {},
});

export function PopupContextProvider({ children }: PropsWithChildren) {
    const { t } = useTranslation();
    const [currentPopup, setCurrentPopup] = useState<Popup>();

    const closePopup = useCallback(() => setCurrentPopup(undefined), []);

    return (
        <PopupContext.Provider
            value={{
                showPopup: setCurrentPopup,
            }}
        >
            {children}
            {currentPopup && (
                <Dialog
                    open
                    onClose={
                        currentPopup.allowClose ?? true ? closePopup : undefined
                    }
                >
                    <DialogTitle>{currentPopup.title}</DialogTitle>
                    <DialogContent>
                        <Stack spacing={2}>
                            <Typography variant="body1">
                                {currentPopup.text}
                            </Typography>
                            {currentPopup.content}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={closePopup}
                        >
                            {t("common.close")}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </PopupContext.Provider>
    );
}

export function usePopupContext() {
    return useContext(PopupContext);
}
