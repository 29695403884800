import { styled } from "@mui/system";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import {
    endOfDay,
    isAfter,
    isSameDay,
    isWithinInterval,
    startOfDay,
} from "date-fns";
import { useFormikContext } from "formik";

import { EventCreate, EventEdit } from "../../../models/calendar/Event";

interface RangePickerDayProps extends PickersDayProps<Date> {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
}

export function WeekPickersDay(props: PickersDayProps<Date>) {
    const formikContext = useFormikContext<EventCreate | EventEdit>();
    const startDate = formikContext.getFieldMeta<Date>("startDate");
    const endDate = formikContext.getFieldMeta<Date>("endDate");

    if (isAfter(startDate.value, endDate.value)) {
        return <PickersDay {...props} />;
    }

    const start = startOfDay(startDate.value);
    const end = endOfDay(endDate.value);

    const dayIsBetween = isWithinInterval(props.day, { start, end });
    const isFirstDay = isSameDay(props.day, start);
    const isLastDay = isSameDay(props.day, end);

    return (
        <RangePickerDay
            {...props}
            dayIsBetween={dayIsBetween}
            isFirstDay={isFirstDay}
            isLastDay={isLastDay}
        />
    );
}

const RangePickerDay = styled(PickersDay, {
    shouldForwardProp: prop =>
        prop !== "dayIsBetween" &&
        prop !== "isFirstDay" &&
        prop !== "isLastDay",
})<RangePickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        margin: 0,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
        marginRight: 0,
    }),
    ...(isLastDay && {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
        marginLeft: 0,
    }),
})) as React.ComponentType<RangePickerDayProps>;
