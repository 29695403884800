import { CSSProperties } from "react";
import { XYCoord, useDragLayer } from "react-dnd";

import { ControlPointCircle } from "./ControlPointItemDrag";
import { DragLayerCollectedProps, DragObject, ItemType } from "./utils";

const layerStyles: CSSProperties = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
};

function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
) {
    if (!initialOffset || !currentOffset) {
        return {
            display: "none",
        };
    }

    const { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        backgroundColor: "transparent",
        transform,
        WebkitTransform: transform,
    };
}

export function DragLayer() {
    const { item, itemType, isDragging, initialOffset, currentOffset } =
        useDragLayer<DragLayerCollectedProps, DragObject>(monitor => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }));

    function renderItem() {
        switch (itemType) {
            case ItemType:
                return <ControlPointCircle color={item.color} />;
            default:
                return null;
        }
    }

    if (!isDragging) {
        return null;
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </div>
    );
}
