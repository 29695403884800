import { useRef } from "react";
import { XYCoord, useDrop } from "react-dnd";
import { useOptionalFactory } from "react-dnd/dist/hooks/useOptionalFactory";

import { clamp } from "../../utils/number.utils";

type UseDrop<DragObject = unknown, CollectedProps = unknown> = typeof useDrop<
    DragObject,
    DragObject,
    CollectedProps
>;

interface ResultWithCoords<DragObject = unknown> {
    item?: DragObject;
    positionX: number;
    positionY: number;
}

function getPercentages(bounds: DOMRect, coords: XYCoord) {
    return {
        x: Math.round(((coords.x - bounds.left) / bounds.width) * 10000) / 100,
        y: Math.round(((coords.y - bounds.top) / bounds.height) * 10000) / 100,
    };
}

export function useLocalDrop<DragObject = unknown, CollectedProps = unknown>(
    ...[specArgs, ...other]: Parameters<UseDrop<DragObject, CollectedProps>>
): ReturnType<UseDrop<DragObject, CollectedProps>> {
    const spec = useOptionalFactory(specArgs, other);
    const ref = useRef<Element>();

    const [props, drop] = useDrop<
        DragObject,
        ResultWithCoords<DragObject>,
        CollectedProps
    >(
        {
            ...spec,
            drop: (item, monitor, ...other) => {
                const offset = monitor.getSourceClientOffset();
                if (ref.current && offset) {
                    const percentages = getPercentages(
                        ref.current.getBoundingClientRect(),
                        offset,
                    );

                    const newItem =
                        spec.drop?.(item, monitor, ...other) ?? item;

                    return {
                        item: newItem,
                        positionX: clamp(percentages.x, 0, 100),
                        positionY: clamp(percentages.y, 0, 100),
                    };
                }
            },
        },
        ...other,
    );

    return [
        props,
        (elem, options) => {
            ref.current = elem as Element;
            return drop(ref, options);
        },
    ];
}
