import { Edit } from "@mui/icons-material";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    DialogActions,
    Button,
    IconButton,
    TextField,
} from "@mui/material";
import { Formik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CustomerSelect } from "./CustomerSelect";
import { FormikSubmitButton } from "../../../../components/Controls/Formik/SubmitButton";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { useOpenState } from "../../../../hooks/useOpenState";
import { Employee, EmployeeEdit } from "../../../../models/company/Employee";
import { EditEmployeeViewCallback } from "../../../../service/CompanyService";
import { useEmployeeEditSchema } from "../../schema";

interface EditEmployeeDialogProps {
    employee: Employee;
    editEmployeeCallback: EditEmployeeViewCallback;
}

export function EditEmployeeDialog({
    employee,
    editEmployeeCallback,
}: EditEmployeeDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("company.employee");

    const initialValues = useMemo<EmployeeEdit>(
        () => ({
            allowedCustomers: employee.allowedCustomers,
        }),
        [employee.allowedCustomers],
    );

    const {
        state: open,
        open: openDialog,
        close: closeDialog,
    } = useOpenState();
    const { validationSchema } = useEmployeeEditSchema(translator);

    return (
        <>
            <IconButton onClick={openDialog}>
                <Edit />
            </IconButton>
            <Dialog open={open} fullWidth>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={values =>
                        editEmployeeCallback(employee.id, values).then(
                            closeDialog,
                        )
                    }
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t("pages.company.employee.create_header")}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    <TextField
                                        label={translator
                                            .getField("name")
                                            .getName()}
                                        defaultValue={employee.name}
                                        disabled
                                    />

                                    <TextField
                                        label={translator
                                            .getField("email")
                                            .getName()}
                                        defaultValue={employee.email}
                                        disabled
                                    />

                                    <CustomerSelect
                                        formik={formik}
                                        translator={translator}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={closeDialog}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
