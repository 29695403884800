import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { FormTranslator } from "./useFormTranslator";
import { isRequired } from "../utils/yup.utils";

export function useYup(translator: FormTranslator) {
    const { t } = useTranslation();

    yup.setLocale({
        mixed: {
            required: msg =>
                t("errors.required", {
                    name: translator.getField(msg.path).getName(),
                }),
        },
        string: {
            email: msg =>
                t("errors.email", {
                    name: translator.getField(msg.path).getName(),
                }),
        },
        array: {
            min: msg =>
                t("errors.min", {
                    name: translator.getField(msg.path).getName(),
                    count: msg.min,
                }),
        },
    });
}

export function useValidationResult(
    validationSchema: yup.ObjectSchema<yup.AnyObject>,
) {
    const isRequiredInternal = useCallback(
        (key: string) => isRequired(validationSchema, key),
        [validationSchema],
    );

    return useMemo(
        () => ({
            validationSchema,
            isRequired: isRequiredInternal,
        }),
        [isRequiredInternal, validationSchema],
    );
}
