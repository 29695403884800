import { useCallback, useMemo } from "react";
import useSWR, { SWRResponse } from "swr";

import {
    UsePaginationServiceResult,
    usePaginationService,
} from "./PaginationService";
import { useFetch } from "../hooks/useFetch";
import {
    Customer,
    CustomerAdd,
    CustomerAllowedOption,
    CustomerEdit,
    CustomerOverview,
} from "../models/customer/Customer";
import { Error } from "../models/error/Error";
import { Pagination, SortDirection } from "../models/pagination/Pagination";

export type AddCustomerListCallback = (body: CustomerAdd) => Promise<void>;
export type EditCustomerViewCallback = (body: CustomerEdit) => Promise<void>;
export type DeleteCustomerViewCallback = () => Promise<void>;

interface CustomerServiceListResult {
    customersResponse: UsePaginationServiceResult<CustomerOverview, Error>;
    addCustomer: AddCustomerListCallback;
}

interface CustomerServiceViewResult {
    customerResponse: SWRResponse<Customer | undefined, Error>;
    editCustomer: EditCustomerViewCallback;
    deleteCustomer: DeleteCustomerViewCallback;
}

interface CustomerServiceAllowedResult {
    customerOptionsResponse: SWRResponse<CustomerAllowedOption[], Error>;
}

export const CustomerListPath = "customers";
export const CustomerSelectPath = "customers/allowed";
export const CustomerCreatePath = "customers";
export const CustomerViewPath = (id: number) => `customers/${id}`;
export const CustomerViewMinimalPath = (id: number) =>
    `customers/${id}/minimal`;
export const CustomerEditPath = (id: number) => `customers/${id}`;
export const CustomerDeletePath = (id: number) => `customers/${id}`;

export const DefaultCustomerGroupId = -1;

const defaultPagination: Pagination = {
    page: 1,
    itemsPerPage: 10,
    itemsPerPageOptions: [10, 20, 50],
    sort: undefined,
    sortDirection: SortDirection.None,
};

export function useCustomerListService(): CustomerServiceListResult {
    const fetch = useFetch();
    const customersResponse = usePaginationService<CustomerOverview, Error>(
        defaultPagination,
        CustomerListPath,
    );

    const addCustomer = useCallback<AddCustomerListCallback>(
        async body => {
            await fetch.post(CustomerCreatePath, body);
            await customersResponse.response.mutate();
        },
        [fetch, customersResponse.response],
    );

    return useMemo(
        () => ({
            customersResponse,
            addCustomer,
        }),
        [customersResponse, addCustomer],
    );
}

export function useCustomerViewService(id: number): CustomerServiceViewResult {
    const fetch = useFetch();
    const customerResponse = useSWR<Customer | undefined, Error>(
        CustomerViewPath(id),
    );

    const editCustomer = useCallback<EditCustomerViewCallback>(
        async body => {
            await fetch.put(CustomerEditPath(id), body);
            await customerResponse.mutate();
        },
        [customerResponse, fetch, id],
    );

    const deleteCustomer = useCallback<DeleteCustomerViewCallback>(async () => {
        await fetch.delete(CustomerDeletePath(id));
    }, [fetch, id]);

    return useMemo(
        () => ({
            customerResponse,
            editCustomer,
            deleteCustomer,
        }),
        [customerResponse, editCustomer, deleteCustomer],
    );
}

export function useCustomerAllowedService(
    shouldLoad = true,
): CustomerServiceAllowedResult {
    const customerOptionsResponse = useSWR<CustomerAllowedOption[], Error>(
        shouldLoad ? CustomerSelectPath : null,
    );

    return useMemo(
        () => ({
            customerOptionsResponse,
        }),
        [customerOptionsResponse],
    );
}
