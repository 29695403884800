import { DragLayerMonitor } from "react-dnd";

import { ControlPoint } from "../../../models/controlpoint/ControlPoint";

export interface DragObject {
    controlPoint: ControlPoint;
    color: string | undefined;
}

export interface DragCollectedProps {
    opacity: number;
}

export interface DropCollectedProps {
    canDrop: boolean;
    isOver: boolean;
}

export interface DragEndResult {
    controlPointId: number;
    percentX: number;
    percentY: number;
}

export interface DragLayerCollectedProps {
    item: DragObject;
    itemType: ReturnType<DragLayerMonitor<ControlPoint>["getItemType"]>;
    initialOffset: ReturnType<
        DragLayerMonitor<ControlPoint>["getInitialSourceClientOffset"]
    >;
    currentOffset: ReturnType<
        DragLayerMonitor<ControlPoint>["getSourceClientOffset"]
    >;
    isDragging: ReturnType<DragLayerMonitor<ControlPoint>["isDragging"]>;
}

export const ItemType = "CONTROLPOINT_FLOORPLAN_DRAG";
