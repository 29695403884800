import {
    Card,
    CardHeader,
    CardContent,
    Stack,
    Alert,
    Snackbar,
} from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { FormikSubmitButton } from "../../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { useOpenState } from "../../../../hooks/useOpenState";
import {
    ChangePasswordRequest,
    PASSWORD_MIN_LENGTH,
    PASSWORD_MISMATCH_ERROR,
} from "../../../../models/account/Passwords";
import { useAccountService } from "../../../../service/AccountService";
import { usePasswordChangeSchema } from "../../schema";

const initialValues: ChangePasswordRequest = {
    currentPassword: "",
    newPassword: "",
    repeatNewPassword: "",
};

export function ChangePasswordInfo() {
    const { t } = useTranslation();
    const translator = useFormTranslator("account.password");
    const accountService = useAccountService();

    const {
        state: snackbarOpen,
        open: openSnackbar,
        close: closeSnackbar,
    } = useOpenState();

    const { validationSchema, isRequired } =
        usePasswordChangeSchema(translator);

    return (
        <>
            <Card>
                <CardHeader title={t("pages.account.password.header")} />
                <CardContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, formik) => {
                            const result = await accountService.changePassword(
                                values,
                            );
                            if (result.success) {
                                formik.resetForm();
                                openSnackbar();
                            } else {
                                formik.setSubmitting(false);
                                formik.setFieldValue(
                                    "currentPassword",
                                    "",
                                    false,
                                );
                                formik.setFieldValue("newPassword", "", false);
                                formik.setFieldValue(
                                    "repeatNewPassword",
                                    "",
                                    false,
                                );
                                if (result.error === PASSWORD_MISMATCH_ERROR) {
                                    formik.setFieldError(
                                        "currentPassword",
                                        translator.getFormError(
                                            result.error.toLocaleLowerCase(),
                                        ),
                                    );
                                } else {
                                    formik.setFieldError(
                                        "newPassword",
                                        translator.getFormError(
                                            result.error.toLocaleLowerCase(),
                                            {
                                                mincount: PASSWORD_MIN_LENGTH,
                                            },
                                        ),
                                    );
                                }
                            }
                        }}
                    >
                        {formik => (
                            <Stack spacing={2}>
                                <FormikTextField
                                    formik={formik}
                                    field="currentPassword"
                                    translator={translator}
                                    type="password"
                                    required={isRequired("currentPassword")}
                                />

                                <FormikTextField
                                    formik={formik}
                                    field="newPassword"
                                    translator={translator}
                                    type="password"
                                    required={isRequired("newPassword")}
                                />

                                <FormikTextField
                                    formik={formik}
                                    field="repeatNewPassword"
                                    translator={translator}
                                    type="password"
                                    required={isRequired("repeatNewPassword")}
                                />

                                <div>
                                    <FormikSubmitButton formik={formik} />
                                </div>
                            </Stack>
                        )}
                    </Formik>
                </CardContent>
            </Card>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                onClose={closeSnackbar}
            >
                <Alert
                    severity="success"
                    sx={{ flex: 1, flexDirection: "row" }}
                    onClose={closeSnackbar}
                >
                    {t("pages.account.password.reset_success")}
                </Alert>
            </Snackbar>
        </>
    );
}
