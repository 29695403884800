import { Circle, CircleOutlined } from "@mui/icons-material";
import {
    Icon,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@mui/material";
import { useEffect } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";

import {
    DragCollectedProps,
    DragEndResult,
    DragObject,
    ItemType,
} from "./utils";
import { useLocalDrag } from "../../../hooks/dnd/useLocalDrag";
import {
    ControlPoint,
    ControlPointSort,
} from "../../../models/controlpoint/ControlPoint";

interface ControlPointCircleProps {
    color: string | undefined;
}

interface ControlPointItemDragProps {
    controlPoint: ControlPoint;
    controlPointSort: ControlPointSort | undefined;
    canDrag: boolean;
    showTextInTooltip?: boolean;
    onDragEnd: (result: DragEndResult) => void;
}

export function ControlPointCircle({ color }: ControlPointCircleProps) {
    return color ? <Circle htmlColor={color} /> : <CircleOutlined />;
}

export function ControlPointItemDrag({
    controlPoint,
    controlPointSort,
    canDrag,
    showTextInTooltip = false,
    onDragEnd,
}: ControlPointItemDragProps) {
    const { t } = useTranslation();
    const color = controlPointSort?.color;

    const [{ opacity }, drag, preview] = useLocalDrag<
        DragObject,
        DragCollectedProps
    >(
        () => ({
            type: ItemType,
            item: {
                controlPoint,
                color,
            },
            canDrag: () => {
                return canDrag;
            },
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();
                if (item && dropResult && dropResult.item) {
                    onDragEnd({
                        controlPointId: dropResult.item.controlPoint.id,
                        percentX: dropResult.positionX,
                        percentY: dropResult.positionY,
                    });
                }
            },
            collect: monitor => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        }),
        [controlPoint, color],
    );

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    return showTextInTooltip ? (
        <>
            <Tooltip
                title={`${t(
                    "pages.floorplan.view.texts.controlpoint_item_header",
                )}: ${controlPoint.name}`}
                placement="top"
            >
                <Icon ref={drag}>
                    <ControlPointCircle color={color} />
                </Icon>
            </Tooltip>
        </>
    ) : (
        <ListItem
            key={`floorplan_controlpoint_drag_item_${controlPoint.id}`}
            ref={drag}
            sx={{ opacity }}
            disableGutters
            disablePadding
        >
            <ListItemIcon>
                <ControlPointCircle color={color} />
            </ListItemIcon>
            <ListItemText>{controlPoint.name}</ListItemText>
        </ListItem>
    );
}
