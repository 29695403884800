import { SketchPicker } from "@hello-pangea/color-picker";
import {
    TextField,
    InputAdornment,
    Box,
    BaseTextFieldProps,
    Popper,
    ClickAwayListener,
} from "@mui/material";
import { useRef } from "react";

import { useOpenState } from "../../hooks/useOpenState";

interface ColorPickerProps extends Omit<BaseTextFieldProps, "color" | "value"> {
    color: string;
    onChange?: (color: string) => void;
    disabled?: boolean;
}

export function ColorPicker({
    color,
    onChange,
    disabled = false,
    ...other
}: ColorPickerProps) {
    const ref = useRef<HTMLDivElement>(null);

    const {
        state: open,
        open: openDialog,
        close: closeDialog,
    } = useOpenState();

    return (
        <>
            <TextField
                {...other}
                ref={ref}
                value={color}
                onChange={ev => onChange?.(ev.target.value.toUpperCase())}
                InputProps={{
                    readOnly: disabled,
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            onClick={disabled ? undefined : openDialog}
                        >
                            <Box
                                sx={{
                                    width: "1em",
                                    height: "1em",
                                    padding: 0,
                                    borderRadius: "0.25em",
                                    backgroundColor: color,
                                    border: "1px solid black",
                                }}
                            />
                        </InputAdornment>
                    ),
                }}
            />
            {!disabled && (
                <Popper
                    open={open}
                    placement="bottom-start"
                    anchorEl={ref.current}
                    style={{ zIndex: 10000 }}
                >
                    <ClickAwayListener onClickAway={closeDialog}>
                        <div style={{ marginTop: "0.5em" }}>
                            <div>
                                <SketchPicker
                                    color={color}
                                    disableAlpha
                                    presetColors={[]}
                                    onChange={val =>
                                        onChange?.(val.hex.toUpperCase())
                                    }
                                />
                            </div>
                        </div>
                    </ClickAwayListener>
                </Popper>
            )}
        </>
    );
}
