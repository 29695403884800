import { TextField, TextFieldProps } from "@mui/material";
import { FormikProps } from "formik";

import { FormValues, formikEventWrapper } from "./utils";
import { FormTranslator } from "../../../hooks/useFormTranslator";
import { KeysMatching } from "../../../utils/utils";

type FormikTextFieldProps<T extends FormValues> = {
    formik: FormikProps<T>;
    field: Extract<KeysMatching<T, string | number | undefined>, string>;
    translator: FormTranslator;
} & Omit<TextFieldProps, "id" | "name" | "label" | "value" | "error">;

export function FormikTextField<T extends FormValues>({
    formik,
    field,
    translator,
    helperText,
    onChange,
    onBlur,
    ...props
}: FormikTextFieldProps<T>) {
    const translatorField = translator.getField(field);
    const label = translatorField.getName();
    const meta = formik.getFieldMeta<string>(field);

    const hasError = meta.touched && meta.error !== undefined;
    const helpText = hasError ? meta.error : helperText;

    const onChangeInternal = formikEventWrapper(formik.handleChange, onChange);
    const onBlurInternal = formikEventWrapper(formik.handleBlur, onBlur);

    return (
        <TextField
            id={field}
            name={field}
            label={label}
            value={meta.value ?? ""}
            error={hasError}
            helperText={helpText}
            onChange={onChangeInternal}
            onBlur={onBlurInternal}
            {...props}
        />
    );
}
