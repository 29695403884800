import { useCallback, useMemo } from "react";
import useSWR, { SWRResponse } from "swr";

import {
    UsePaginationServiceResult,
    usePaginationService,
} from "./PaginationService";
import { useFetch } from "../hooks/useFetch";
import { Error } from "../models/error/Error";
import {
    FloorPlan,
    FloorPlanCreate,
    FloorPlanDownloadRequest,
    FloorPlanForOverview,
} from "../models/floorplan/FloorPlan";
import { Pagination, SortDirection } from "../models/pagination/Pagination";

export type AddFloorPlanCallback = (body: FloorPlanCreate) => Promise<void>;
export type DownloadFloorPlanCallback = (
    id: number,
) => Promise<FloorPlanDownloadRequest>;
export type DeleteFloorPlanCallback = (id: number) => Promise<void>;

const defaultPagination: Pagination = {
    page: 1,
    itemsPerPage: 10,
    itemsPerPageOptions: [10, 20, 50],
    sort: undefined,
    sortDirection: SortDirection.None,
};

interface FloorPlanServiceResult {
    floorPlansResponse: UsePaginationServiceResult<FloorPlanForOverview, Error>;
    addFloorPlan: AddFloorPlanCallback;
    deleteFloorPlan: DeleteFloorPlanCallback;
}

interface FloorPlanServiceViewResult {
    floorPlanResponse: SWRResponse<FloorPlan | undefined, Error>;
}

const FloorPlanListPath = "floorplans";
const FloorPlanCreatePath = "floorplans/upload";
const FloorPlanViewPath = (id: number) => `floorplans/${id}`;
const FloorPlanDeletePath = (id: number) => `floorplans/${id}`;

export function useFloorPlanService(
    customerLocationId?: number,
): FloorPlanServiceResult {
    const fetch = useFetch();

    const params = useMemo<URLSearchParams | undefined>(() => {
        if (customerLocationId) {
            return new URLSearchParams({
                customerLocationId: customerLocationId.toString(),
            });
        }
    }, [customerLocationId]);

    const floorPlansResponse = usePaginationService<
        FloorPlanForOverview,
        Error
    >(defaultPagination, FloorPlanListPath, params);

    const addFloorPlan = useCallback<AddFloorPlanCallback>(
        async body => {
            if (!body.file) {
                return;
            }

            const formData = new FormData();
            formData.append(
                "customerLocationId",
                body.customerLocationId.toString(),
            );
            formData.append("name", body.name);
            formData.append("file", body.file);
            await fetch.postForm(FloorPlanCreatePath, formData, true);
            await floorPlansResponse.reload();
        },
        [fetch, floorPlansResponse],
    );

    const deleteFloorPlan = useCallback<DeleteFloorPlanCallback>(
        async id => {
            await fetch.delete(FloorPlanDeletePath(id));
            await floorPlansResponse.response.mutate();
        },
        [fetch, floorPlansResponse],
    );

    return useMemo(
        () => ({
            floorPlansResponse,
            addFloorPlan,
            deleteFloorPlan,
        }),
        [floorPlansResponse, addFloorPlan, deleteFloorPlan],
    );
}

export function useFloorPlanViewService(
    floorPlanId: number,
): FloorPlanServiceViewResult {
    const floorPlanResponse = useSWR<FloorPlan | undefined, Error>(
        FloorPlanViewPath(floorPlanId),
    );

    return useMemo(
        () => ({
            floorPlanResponse,
        }),
        [floorPlanResponse],
    );
}
