import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useState,
} from "react";

import { CompanyMetadata } from "../models/metadata/Metadata";

type SelectCustomerFunction = (customer: CustomerSelect | undefined) => void;

interface AppContextProviderProps {
    metadata: CompanyMetadata;
}

export interface CustomerSelect {
    customerId: number;
    customerName: string;
    customerLocationId: number;
    customerLocationName: string;
}

interface AppContextInformation {
    metadata: CompanyMetadata;
    currentCustomer: CustomerSelect | undefined;
    selectCustomer: SelectCustomerFunction;
}

const AppContext = createContext<AppContextInformation | undefined>(undefined);

export function AppContextProvider({
    metadata,
    children,
}: PropsWithChildren<AppContextProviderProps>) {
    const [currentCustomer, setCurrentCustomer] = useState<CustomerSelect>();

    const selectCustomer = useCallback<SelectCustomerFunction>(customer => {
        setCurrentCustomer(customer);
    }, []);

    return (
        <AppContext.Provider
            value={{
                metadata,
                currentCustomer,
                selectCustomer,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

export function useCompanyMetadata() {
    return useContext(AppContext)?.metadata;
}

export function useCurrentCustomer() {
    return useContext(AppContext)?.currentCustomer;
}

export function useCustomerSelect() {
    return useContext(AppContext)?.selectCustomer;
}
