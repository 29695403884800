import { Role } from "./models/user/Role";

export interface Permission {
    roles: Role[];
}

interface AppPermissions {
    AccountView: Permission;
    CalendarManage: Permission;
    CalendarView: Permission;
    CompanyView: Permission;
    CalendarCustomerView: Permission;
    CompanyViewManage: Permission;
    CustomersManage: Permission;
    CustomersView: Permission;
    DashboardView: Permission;
    DocumentsManage: Permission;
    DocumentsView: Permission;
    DocumentsCustomerView: Permission;
    FloorPlansManage: Permission;
    FloorPlansView: Permission;
    FloorPlansCustomerView: Permission;
    ControlPointsView: Permission;
    ControlPointsManage: Permission;
    WorkOrdersCustomerView: Permission;
    WorkOrdersManage: Permission;
    WorkListsView: Permission;
    WorkOrdersView: Permission;
}

const EVERYONE = [Role.User, Role.Employee, Role.Admin];

const IMPORTANT = [Role.Employee, Role.Admin];

const CUSTOMER = [Role.User];

export const Permissions: AppPermissions = {
    AccountView: {
        roles: EVERYONE,
    },
    CalendarManage: {
        roles: IMPORTANT,
    },
    CalendarView: {
        roles: IMPORTANT,
    },
    CalendarCustomerView: {
        roles: CUSTOMER,
    },
    CompanyView: {
        roles: IMPORTANT,
    },
    CompanyViewManage: {
        roles: [Role.Admin],
    },
    CustomersManage: {
        roles: IMPORTANT,
    },
    CustomersView: {
        roles: IMPORTANT,
    },
    DashboardView: {
        roles: EVERYONE,
    },
    DocumentsManage: {
        roles: IMPORTANT,
    },
    DocumentsView: {
        roles: IMPORTANT,
    },
    DocumentsCustomerView: {
        roles: CUSTOMER,
    },
    FloorPlansManage: {
        roles: IMPORTANT,
    },
    FloorPlansView: {
        roles: IMPORTANT,
    },
    FloorPlansCustomerView: {
        roles: CUSTOMER,
    },
    ControlPointsView: {
        roles: IMPORTANT,
    },
    ControlPointsManage: {
        roles: IMPORTANT,
    },
    WorkOrdersCustomerView: {
        roles: CUSTOMER,
    },
    WorkOrdersManage: {
        roles: IMPORTANT,
    },
    WorkListsView: {
        roles: IMPORTANT,
    },
    WorkOrdersView: {
        roles: IMPORTANT,
    },
};
