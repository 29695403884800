import { ArrowUpward, Home } from "@mui/icons-material";
import {
    Box,
    Breadcrumbs,
    ButtonGroup,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { DocumentGrid } from "./DocumentGrid";
import {
    DIRECTORY_SEPARATOR,
    useDocumentsPage,
} from "./hooks/useDocumentsPage";
import { CustomerPageTitle } from "../../../components/CustomerPageTitle";
import { useCustomerLocationIdFilter } from "../../../hooks/useCustomerLocationIdFilter";
import { AddDocumentDialog } from "../Dialogs/AddDocumentDialog";
import { AddFolderDialog } from "../Dialogs/AddFolderDialog";

export function DocumentsPageEmployee() {
    const { customerId, locationId } = useCustomerLocationIdFilter();
    const { t } = useTranslation();

    const {
        response,
        subDirectories,
        directoriesBreadCrumbs,
        navigate,
        currentDirectory,
        downloading,
        addDocument,
        downloadDocument,
        deleteDocument,
        addLocalDirectory,
    } = useDocumentsPage(locationId);

    const disableDirectoryUp = currentDirectory.length === 0;

    return (
        <>
            {customerId && locationId && (
                <CustomerPageTitle
                    headerPrefix={t("pages.document.header_customer")}
                    customerId={customerId}
                    locationId={locationId}
                />
            )}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid item>
                        <ButtonGroup>
                            <AddFolderDialog
                                directory={currentDirectory}
                                onComplete={addLocalDirectory}
                            />
                            <AddDocumentDialog
                                customerLocationId={locationId}
                                directory={currentDirectory}
                                addDocuments={addDocument}
                            />
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="baseline">
                        <IconButton
                            disabled={disableDirectoryUp}
                            onClick={navigate.up}
                            sx={{ marginRight: 1 }}
                        >
                            <ArrowUpward />
                        </IconButton>
                        <Breadcrumbs
                            separator={DIRECTORY_SEPARATOR}
                            maxItems={3}
                            aria-label="breadcrumb"
                        >
                            <Link
                                href="#"
                                underline="hover"
                                onClick={navigate.home}
                            >
                                <Home />
                            </Link>
                            {directoriesBreadCrumbs.map((directory, idx) => {
                                if (idx === directoriesBreadCrumbs.length - 1) {
                                    return (
                                        <Typography key={idx}>
                                            {directory[directory.length - 1]}
                                        </Typography>
                                    );
                                } else {
                                    return (
                                        <Link
                                            key={idx}
                                            underline="hover"
                                            onClick={() =>
                                                navigate.directory(directory)
                                            }
                                        >
                                            {directory[directory.length - 1]}
                                        </Link>
                                    );
                                }
                            })}
                        </Breadcrumbs>
                    </Box>
                </Grid>
                <Grid item xs={12} flexGrow={1}>
                    {response.data.Documents ? (
                        <DocumentGrid
                            documents={response.data.Documents}
                            folders={subDirectories}
                            currentDirectory={currentDirectory}
                            downloading={downloading}
                            onFolderClick={navigate.directory}
                            downloadDocument={downloadDocument}
                            deleteDocument={deleteDocument}
                        />
                    ) : (
                        <LinearProgress />
                    )}
                </Grid>
            </Grid>
        </>
    );
}
