import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ApplicationPagingDataGrid } from "../../../../components/Controls/DataGrid/Paging";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { CustomerType } from "../../../../models/customer/Customer";
import { CustomerLocation } from "../../../../models/customer/CustomerLocation";
import { PagedResults } from "../../../../models/pagination/PagedResults";
import { AddCustomerLocationListCallback } from "../../../../service/CustomerLocationService";
import { PaginationControls } from "../../../../service/PaginationService";
import { AddCustomerLocationDialog } from "../../Location/EditDialog/AddCustomerLocationDialog";

interface CustomerLocationsDataProps {
    customerId: number;
    customerType: CustomerType | undefined;
    locations: PagedResults<CustomerLocation> | undefined;
    paginationControls: PaginationControls;
    addCustomerLocation: AddCustomerLocationListCallback;
}

export function CustomerLocationsData({
    customerId,
    customerType,
    locations,
    paginationControls,
    addCustomerLocation,
}: CustomerLocationsDataProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const translator = useFormTranslator("customerLocation");

    const columns = useMemo<GridColDef<CustomerLocation>[]>(
        () => [
            {
                field: "name",
                headerName: translator.getField("name").getName(),
                flex: 1,
            },
            {
                field: "fullAddress",
                headerName: translator.getField("fullAddress").getName(),
                flex: 1,
                valueGetter: row =>
                    `${row.row.address}, ${row.row.postal} ${row.row.residence}`,
            },
        ],
        [translator],
    );

    return (
        <Card>
            <CardHeader title={t("pages.customer.view.headers.locations")} />
            <CardContent>
                {customerType && locations && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AddCustomerLocationDialog
                                customerType={customerType}
                                addCustomerLocation={addCustomerLocation}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ApplicationPagingDataGrid
                                pagination={locations}
                                paginationControls={paginationControls}
                                columns={columns}
                                getRowId={row => row.id}
                                autoHeight
                                disableRowSelectionOnClick
                                onRowClick={row =>
                                    navigate(
                                        `/app/customers/${customerId}/locations/${row.id}`,
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
}
