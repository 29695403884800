import { useTheme } from "@mui/material";

import { DragObject, DropCollectedProps, ItemType } from "./utils";
import { useLocalDrop } from "../../../hooks/dnd/useLocalDrop";

interface FloorPlanImageDropProps {
    imageUrl: string | undefined;
    canDrop: boolean;
}

export function FloorPlanImageDrop({
    imageUrl,
    canDrop: canDropOuter,
    children,
}: React.PropsWithChildren<FloorPlanImageDropProps>) {
    const theme = useTheme();

    const [{ canDrop, isOver }, drop] = useLocalDrop<
        DragObject,
        DropCollectedProps
    >(
        {
            accept: ItemType,
            canDrop: () => {
                return canDropOuter;
            },
            collect: monitor => ({
                canDrop: monitor.canDrop(),
                isOver: monitor.isOver({ shallow: true }),
            }),
        },
        [],
    );
    const borderColor =
        theme.palette.mode === "light"
            ? "rgba(0, 0, 0, 0.23)"
            : "rgba(255, 255, 255, 0.23)";
    return (
        <div style={{ display: "flex" }}>
            <div style={{ position: "relative" }}>
                {imageUrl && (
                    <img
                        src={imageUrl}
                        ref={drop}
                        style={{
                            borderColor:
                                canDrop && isOver
                                    ? theme.palette.text.primary
                                    : borderColor,
                            maxWidth: "100%",
                        }}
                    />
                )}
                {children}
            </div>
        </div>
    );
}
