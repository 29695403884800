import {
    Card,
    CardHeader,
    CardContent,
    Stack,
    Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { DisableDialog } from "./DisableDialog";
import { EnableDialog } from "./EnableDialog";
import { RecoveryCodesDialog } from "./RecoveryCodesDialog";
import { useRecoveryCodesPopup } from "./useRecoveryCodesPopup";
import { TwoFactorAuth } from "../../../../models/account/TwoFactor";
import { useAccountService } from "../../../../service/AccountService";

export function TwoFactorInfo() {
    const { t } = useTranslation();
    const recoveryCodesPopup = useRecoveryCodesPopup();
    const accountService = useAccountService();

    const [twoFactorAuth, setTwoFactorAuth] = useState<TwoFactorAuth>();

    const updateTwoFactorAuth = useCallback(async () => {
        const newState = await accountService.getTwoFactorAuth();
        setTwoFactorAuth(oldState => {
            if (
                oldState?.enabled !== newState.enabled ||
                oldState.recoveryCodesLeft !== newState.recoveryCodesLeft
            ) {
                return newState;
            }
            return oldState;
        });
    }, [accountService]);

    const disableTwoFactor = useCallback(async () => {
        await accountService.disableTwoFactor();
        updateTwoFactorAuth();
    }, [accountService, updateTwoFactorAuth]);

    const generateRecoveryCodes = useCallback(async () => {
        const response = await accountService.generateRecoveryCodes();
        updateTwoFactorAuth();
        recoveryCodesPopup.showPopup(response.recoveryCodes);
    }, [accountService, recoveryCodesPopup, updateTwoFactorAuth]);

    useEffect(() => {
        updateTwoFactorAuth();
    }, [updateTwoFactorAuth]);

    const isLoaded = twoFactorAuth !== undefined;

    return (
        <Card>
            <CardHeader title={t("pages.account.twofactor.header")} />
            <CardContent>
                {isLoaded && (
                    <Stack spacing={2}>
                        {twoFactorAuth.enabled ? (
                            <>
                                <Typography sx={{ paddingBottom: 3 }}>
                                    <Trans
                                        i18nKey="pages.account.twofactor.description.enabled"
                                        components={[<strong />]}
                                    />
                                </Typography>

                                <Typography sx={{ paddingBottom: 3 }}>
                                    {t(
                                        "pages.account.twofactor.recovery_codes.amount",
                                        {
                                            count: twoFactorAuth.recoveryCodesLeft,
                                        },
                                    )}
                                </Typography>

                                <Stack spacing={1} direction="row">
                                    <DisableDialog
                                        onFinish={disableTwoFactor}
                                    />
                                    <RecoveryCodesDialog
                                        onFinish={generateRecoveryCodes}
                                    />
                                </Stack>
                            </>
                        ) : (
                            <>
                                <Typography sx={{ paddingBottom: 3 }}>
                                    <Trans
                                        i18nKey="pages.account.twofactor.description.disabled"
                                        components={[<strong />]}
                                    />
                                </Typography>
                                <EnableDialog
                                    enableTwoFactorCallback={
                                        accountService.enableTwoFactor
                                    }
                                />
                            </>
                        )}
                    </Stack>
                )}
            </CardContent>
        </Card>
    );
}
