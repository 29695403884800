import { Delete } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CreateEmployeeDialog } from "./CreateEmployeeDialog";
import { EditEmployeeDialog } from "./EditEmployeeDialog";
import { ConfirmButton } from "../../../../components/Controls/ConfirmButton";
import { ApplicationStandardDataGrid } from "../../../../components/Controls/DataGrid/Standard";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { usePermissions } from "../../../../hooks/usePermissions";
import { Employee } from "../../../../models/company/Employee";
import { Permissions } from "../../../../permissions";
import { useCompanyEmployeeService } from "../../../../service/CompanyService";

export function EmployeeInfo() {
    const { t } = useTranslation();

    const permissions = usePermissions();
    const canEdit = permissions.hasPermission(Permissions.CompanyViewManage);

    const companyService = useCompanyEmployeeService(canEdit);
    const translator = useFormTranslator("company.employee");

    const columns = useMemo<GridColDef<Employee>[]>(
        () => [
            {
                field: "actions",
                type: "actions",
                getActions: params => [
                    <EditEmployeeDialog
                        employee={params.row}
                        editEmployeeCallback={companyService.editEmployee}
                    />,
                    <ConfirmButton
                        key={`company_employee_delete_${params.id}`}
                        confirmText={t("pages.company.employee.warning_remove")}
                        onConfirm={() =>
                            companyService.deleteEmployee(params.row.id)
                        }
                        color="error"
                        icon
                        isDanger
                    >
                        <Delete />
                    </ConfirmButton>,
                ],
                renderHeader: () => (
                    <CreateEmployeeDialog
                        createEmployeeCallback={companyService.createEmployee}
                    />
                ),
            },
            {
                field: "name",
                headerName: translator.getField("name").getName(),
                flex: 1,
            },
            {
                field: "email",
                headerName: translator.getField("email").getName(),
                flex: 1,
            },
        ],
        [companyService, t, translator],
    );

    return (
        <Card>
            <CardHeader title={t("pages.company.employee.header")} />
            <CardContent>
                <Typography sx={{ paddingBottom: 3 }}>
                    {t("pages.company.employee.description")}
                </Typography>
                <ApplicationStandardDataGrid
                    columns={columns}
                    rows={companyService.employeesResponse.data ?? []}
                    loading={companyService.employeesResponse.isValidating}
                    getRowId={item => item.id}
                    removeColumns={canEdit ? [] : ["actions"]}
                    noRowsLabel={
                        canEdit
                            ? undefined
                            : t("pages.company.employee.no_view")
                    }
                    autoHeight
                    disableRowSelectionOnClick
                    pagination
                    pageSizeOptions={[5]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                        sorting: {
                            sortModel: [
                                {
                                    field: "name",
                                    sort: "asc",
                                },
                            ],
                        },
                    }}
                />
            </CardContent>
        </Card>
    );
}
