import { useParams } from "react-router-dom";

interface ResponseData {
    customerId: number | undefined;
}

export function useCustomerIdFilter(): ResponseData {
    const { customerId } = useParams();

    let customerIdParsed: number | undefined = undefined;
    if (customerId) {
        customerIdParsed = parseInt(customerId, 10);
        if (isNaN(customerIdParsed)) {
            customerIdParsed = undefined;
        }
    }

    return {
        customerId: customerIdParsed,
    };
}
