import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
    ButtonGroup,
    Collapse,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
} from "@mui/material";
import { useEffect, useState } from "react";

import { WorkListEditor } from "./editor";
import { WorkListCategoryGet } from "../../models/worklist/WorkListCategory";
import { WorkListSubCategoryGet } from "../../models/worklist/WorkListSubCategory";
import { WorkListIndex } from "../../models/worklist/Worklist";

type SubCategoryDropdownProps = {
    selectedWorkListId: number | undefined;
    subCategory: WorkListSubCategoryGet;
    onItemClick: (workList: WorkListIndex) => void;
};

function SubCategoryDropdown({
    selectedWorkListId,
    subCategory,
    onItemClick,
}: SubCategoryDropdownProps) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemText>{subCategory.name}</ListItemText>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {subCategory.workLists.length > 0 ? (
                        subCategory.workLists.map(workList => (
                            <ListItemButton
                                key={workList.id}
                                selected={workList.id === selectedWorkListId}
                                onClick={() => onItemClick(workList)}
                            >
                                <ListItemText
                                    sx={{ pl: 4 }}
                                    primaryTypographyProps={{
                                        variant: "subtitle2",
                                    }}
                                >
                                    {workList.name}
                                </ListItemText>
                            </ListItemButton>
                        ))
                    ) : (
                        <ListItem>
                            <ListItemText
                                sx={{ pl: 4 }}
                                primaryTypographyProps={{
                                    variant: "subtitle2",
                                }}
                            >
                                <i>Geen lijsten gevonden</i>
                            </ListItemText>
                        </ListItem>
                    )}
                </List>
            </Collapse>
        </>
    );
}

export function WorkListPage() {
    // const workListService = useOidcService(Registry.workListService);
    const [workListCategories, setWorkListCategories] =
        useState<WorkListCategoryGet[]>();
    const [selectedWorkList, setSelectedWorkList] = useState<WorkListIndex>();

    useEffect(() => {
        const fetchData = async () => {
            // const workLists = await workListService.getWorkLists();
            // if (workLists !== undefined) {
            //     setWorkListCategories(workLists.categories);
            // } else {
            //     setWorkListCategories(undefined);
            // }
        };

        fetchData().catch(console.error);
    }, []);

    const openWorkListEditor = (workList: WorkListIndex): void => {
        setSelectedWorkList(workList);
    };

    return workListCategories !== undefined ? (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                {workListCategories.map((category, catIdx) => (
                    <Paper key={catIdx}>
                        <List>
                            <ListItem divider>
                                <b>{category.name}</b>
                            </ListItem>
                            {category.subCategories.map(
                                (subCategory, subCatIdx) => (
                                    <SubCategoryDropdown
                                        key={subCatIdx}
                                        selectedWorkListId={
                                            selectedWorkList?.id
                                        }
                                        subCategory={subCategory}
                                        onItemClick={openWorkListEditor}
                                    />
                                ),
                            )}
                        </List>
                    </Paper>
                ))}
                <ButtonGroup orientation="vertical" />
            </Grid>
            <Grid item xs={9}>
                {/* <WorkListEditor
                    workListId={selectedWorkList?.id}
                    workListService={workListService}
                /> */}
            </Grid>
        </Grid>
    ) : (
        <LinearProgress />
    );
}
