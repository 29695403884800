import { useCallback, useMemo } from "react";
import useSWR, { SWRResponse } from "swr";
import useSWRImmutable from "swr/immutable";

import { CustomerViewMinimalPath } from "./CustomerService";
import {
    UsePaginationServiceResult,
    usePaginationService,
} from "./PaginationService";
import { useFetch } from "../hooks/useFetch";
import { LimitedCustomerInformation } from "../models/customer/Customer";
import {
    CustomerLocation,
    CustomerLocationAdd,
    CustomerLocationEdit,
} from "../models/customer/CustomerLocation";
import { Pagination } from "../models/pagination/Pagination";

export type AddCustomerLocationListCallback = (
    body: CustomerLocationAdd,
) => Promise<void>;
export type EditCustomerLocationViewCallback = (
    body: CustomerLocationEdit,
) => Promise<void>;
export type DeleteCustomerLocationViewCallback = () => Promise<void>;

interface CustomerLocationServiceListResult {
    customerLocationsResponse: UsePaginationServiceResult<
        CustomerLocation,
        Error
    >;
    addCustomerLocation: AddCustomerLocationListCallback;
}

interface CustomerLocationServiceViewResult {
    customerResponse: SWRResponse<
        LimitedCustomerInformation | undefined,
        Error
    >;
    customerLocationResponse: SWRResponse<CustomerLocation | undefined, Error>;
    editCustomerLocation: EditCustomerLocationViewCallback;
    deleteCustomerLocation: DeleteCustomerLocationViewCallback;
}

export const CustomerLocationListPath = (customerId: number) =>
    `customers/${customerId}/locations`;
export const CustomerLocationCreatePath = (customerId: number) =>
    `customers/${customerId}/locations`;

export const CustomerLocationViewPath = (customerId: number, id: number) =>
    `customers/${customerId}/locations/${id}`;
export const CustomerLocationEditPath = (customerId: number, id: number) =>
    `customers/${customerId}/locations/${id}`;
export const CustomerLocationDeletePath = (customerId: number, id: number) =>
    `customers/${customerId}/locations/${id}`;

export function useCustomerLocationListService(
    customerId: number,
    defaultPagination: Pagination,
): CustomerLocationServiceListResult {
    const fetch = useFetch();
    const customerLocationsResponse = usePaginationService<
        CustomerLocation,
        Error
    >(defaultPagination, CustomerLocationListPath(customerId));

    const addCustomerLocation = useCallback<AddCustomerLocationListCallback>(
        async body => {
            await fetch.post(CustomerLocationCreatePath(customerId), body);
            await customerLocationsResponse.response.mutate();
        },
        [fetch, customerId, customerLocationsResponse.response],
    );

    return useMemo(
        () => ({
            customerLocationsResponse,
            addCustomerLocation,
        }),
        [addCustomerLocation, customerLocationsResponse],
    );
}

export function useCustomerLocationViewService(
    customerId: number,
    id: number,
): CustomerLocationServiceViewResult {
    const fetch = useFetch();
    const customerResponse = useSWRImmutable<
        LimitedCustomerInformation | undefined,
        Error
    >(CustomerViewMinimalPath(customerId), {
        revalidateOnMount: true,
    });
    const customerLocationResponse = useSWR<
        CustomerLocation | undefined,
        Error
    >(CustomerLocationViewPath(customerId, id));

    const editCustomerLocation = useCallback<EditCustomerLocationViewCallback>(
        async body => {
            await fetch.put(CustomerLocationEditPath(customerId, id), body);
            await customerLocationResponse.mutate();
        },
        [customerLocationResponse, fetch, customerId, id],
    );

    const deleteCustomerLocation =
        useCallback<DeleteCustomerLocationViewCallback>(async () => {
            await fetch.delete(CustomerLocationDeletePath(customerId, id));
            await customerResponse.mutate();
            await customerLocationResponse.mutate();
        }, [fetch, customerId, id, customerResponse, customerLocationResponse]);

    return useMemo(
        () => ({
            customerResponse,
            customerLocationResponse,
            editCustomerLocation,
            deleteCustomerLocation,
        }),
        [
            customerResponse,
            customerLocationResponse,
            editCustomerLocation,
            deleteCustomerLocation,
        ],
    );
}
