import { FormikProps } from "formik";
import { MuiFileInput, MuiFileInputProps } from "mui-file-input";

import { FormValues, formikEventWrapper } from "./utils";
import { FormTranslator } from "../../../hooks/useFormTranslator";
import { KeysMatching } from "../../../utils/utils";

type FormikFileInputProps<T extends FormValues, T2 extends boolean> = {
    formik: FormikProps<T>;
    field: Extract<
        KeysMatching<T, T2 extends true ? File[] : File | undefined>,
        string
    >;
    translator: FormTranslator;
    accept?: string;
} & Omit<
    MuiFileInputProps<T2>,
    "id" | "name" | "label" | "value" | "error" | "value" | "defaultValue"
>;

export function FormikFileInput<T extends FormValues>({
    formik,
    field,
    translator,
    accept,
    helperText,
    onChange,
    onBlur,
    ...props
}: FormikFileInputProps<T, false>) {
    const translatorField = translator.getField(field);
    const label = translatorField.getName();
    const meta = formik.getFieldMeta<File>(field);

    const hasError = meta.touched && meta.error !== undefined;
    const helpText = hasError ? meta.error : helperText;

    const handleBlur = formikEventWrapper(formik.handleBlur, onBlur);

    return (
        <MuiFileInput
            id={field}
            name={field}
            label={label}
            multiple={false}
            error={hasError}
            helperText={helpText}
            value={meta.value}
            onChange={(file, ...args) => {
                formik.setFieldValue(field, file);
                if (onChange) {
                    onChange(file, ...args);
                }
            }}
            onBlur={handleBlur}
            {...props}
        />
    );
}

export function FormikMultipleFileInput<T extends FormValues>({
    formik,
    field,
    translator,
    accept,
    helperText,
    onChange,
    onBlur,
    ...props
}: FormikFileInputProps<T, true>) {
    const translatorField = translator.getField(field);
    const label = translatorField.getName();
    const meta = formik.getFieldMeta<File[]>(field);

    const hasError = meta.touched && meta.error !== undefined;
    const helpText = hasError ? meta.error : helperText;

    const handleBlur = formikEventWrapper(formik.handleBlur, onBlur);

    return (
        <MuiFileInput
            id={field}
            name={field}
            label={label}
            multiple
            error={hasError}
            helperText={helpText}
            value={meta.value}
            onChange={(files, ...args) => {
                formik.setFieldValue(field, files);
                if (onChange) {
                    onChange(files, ...args);
                }
            }}
            onBlur={handleBlur}
            {...props}
        />
    );
}
