import { useOidc } from "@axa-fr/react-oidc";
import { Logout } from "@mui/icons-material";
import {
    AppBar,
    Box,
    Divider,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Toolbar,
    Typography,
} from "@mui/material";
import { Fragment, PropsWithChildren, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link as ReactRouterLink } from "react-router-dom";

import { RouterLink } from "./RouterLink";
import { useCompanyMetadata } from "../../context/AppContext";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { PagesFinder } from "../../pages";

interface DrawerLayoutProps extends PropsWithChildren {
    appName: string;
    appColor: string;
}

export function DrawerLayout({
    children,
    appName,
    appColor,
}: DrawerLayoutProps) {
    const { t } = useTranslation();
    const { isAuthenticated, logout } = useOidc();
    const currentUser = useCurrentUser();

    const metadata = useCompanyMetadata();

    const pages = useMemo(() => {
        if (isAuthenticated) {
            return PagesFinder.getPages(currentUser).filter(
                f => f.isNavigation,
            );
        } else {
            return [];
        }
    }, [isAuthenticated, currentUser]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            minHeight="100vh"
            width="100%"
        >
            <Helmet>
                <link
                    href={`${metadata?.domains.api}/api/metadata/manifest.json`}
                    rel="manifest"
                />
                <link
                    href={`${metadata?.domains.api}/api/metadata/favicon?variant=ICON`}
                    rel="icon"
                />
                <link
                    href={`${metadata?.domains.api}/api/metadata/favicon?variant=IMAGE_192`}
                    rel="apple-touch-icon"
                />

                <meta name="theme-color" content={metadata?.themeColor} />
                <meta
                    name="description"
                    content={`IPM Knaagdierbeheersing logboek voor ${metadata?.name}`}
                />
            </Helmet>
            <Box
                width="100%"
                height="7em"
                style={{
                    backgroundImage: `url(${metadata?.domains.api}/api/metadata/logo?variant=BANNER)`,
                    backgroundPosition: "left",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <AppBar
                position="relative"
                sx={{
                    zIndex: theme => theme.zIndex.drawer + 1,
                    backgroundColor: appColor,
                    flex: 0,
                }}
            >
                <Toolbar>
                    <Typography
                        variant="h6"
                        noWrap
                        component="h1"
                        sx={{ flexGrow: 1 }}
                    >
                        <Link
                            component={ReactRouterLink}
                            to="/"
                            color="inherit"
                            underline="none"
                        >
                            {appName}
                        </Link>
                    </Typography>
                    {isAuthenticated && currentUser && (
                        <Typography variant="body1">
                            {t("common.welcome_login", {
                                user: currentUser.name,
                            })}
                        </Typography>
                    )}
                </Toolbar>
            </AppBar>
            <Box flex={1} display="flex" flexDirection="row">
                <Box flex={0} display="flex">
                    {isAuthenticated && (
                        <Paper sx={{ flex: 1 }} square>
                            <Box sx={{ overflow: "auto" }}>
                                <List>
                                    {pages.map(page => {
                                        const Icon = page.icon;
                                        return (
                                            <Fragment
                                                key={`drawer_page_${page.path}`}
                                            >
                                                {page.navigationDivider ? (
                                                    <Divider />
                                                ) : (
                                                    ""
                                                )}
                                                {Icon !== undefined ? (
                                                    <RouterLink
                                                        to={`/app/${page.path}`}
                                                        text={t(page.name)}
                                                        icon={<Icon />}
                                                    />
                                                ) : (
                                                    <RouterLink
                                                        to={`/app/${page.path}`}
                                                        text={t(page.name)}
                                                    />
                                                )}
                                            </Fragment>
                                        );
                                    })}

                                    <Divider />
                                    <ListItemButton onClick={() => logout("/")}>
                                        <ListItemIcon>
                                            <Logout />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={t("common.logout")}
                                        />
                                    </ListItemButton>
                                </List>
                            </Box>
                        </Paper>
                    )}
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    margin={3}
                    component="main"
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
}
