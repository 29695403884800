import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { FloorPlansList } from "./FloorPlansList";
import { CustomerDropdown } from "../../../components/Controls/CustomerDropdown";
import { useCurrentCustomer } from "../../../context/AppContext";
import { useFloorPlanService } from "../../../service/FloorPlanService";

export function FloorPlanIndexCustomerPage() {
    const currentCustomer = useCurrentCustomer();
    const navigate = useNavigate();

    const { floorPlansResponse, deleteFloorPlan } = useFloorPlanService(
        currentCustomer?.customerLocationId,
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} flexGrow={0}>
                <Grid container>
                    <Grid item xs={3}>
                        <CustomerDropdown />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} flexGrow={1}>
                <FloorPlansList
                    customerLocationId={currentCustomer?.customerLocationId}
                    response={floorPlansResponse}
                    onRowClick={row => navigate(`/app/floorplans/${row.id}`)}
                    deleteFloorPlan={deleteFloorPlan}
                />
            </Grid>
        </Grid>
    );
}
