import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useCustomerUserSchema } from "./schema";
import { FormikSubmitButton } from "../../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { CustomerUserAdd } from "../../../../models/customer/CustomerUser";
import { AddCustomerUserListCallback } from "../../../../service/CustomerUserService";

interface AddCustomerUserDialogProps {
    addCustomerUser: AddCustomerUserListCallback;
}

const emptyCustomerUser: CustomerUserAdd = {
    name: "",
    email: "",
};

export function AddCustomerUserDialog({
    addCustomerUser,
}: AddCustomerUserDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("customerUser");
    const { validationSchema, isRequired } = useCustomerUserSchema(translator);

    const [opened, setOpened] = useState(false);

    const onOpen = useCallback(() => setOpened(true), [setOpened]);
    const onClose = useCallback(() => setOpened(false), [setOpened]);

    const onSubmit = useCallback(
        async (
            values: CustomerUserAdd,
            formik: FormikHelpers<CustomerUserAdd>,
        ) => {
            try {
                await addCustomerUser(values);
                onClose();
                formik.resetForm();
            } catch (ex) {
                console.warn(ex);
            }
        },
        [addCustomerUser, onClose],
    );

    const formik = useFormik({
        initialValues: emptyCustomerUser,
        validationSchema,
        onSubmit,
    });

    return (
        <>
            <Button onClick={onOpen} variant="outlined">
                {t("common.add", {
                    resource: t("pages.customerUser.resource"),
                })}
            </Button>
            <Dialog open={opened} fullWidth>
                <DialogTitle>
                    {t("common.add", {
                        resource: t("pages.customerUser.resource"),
                    })}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} marginY={1}>
                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="name"
                            required={isRequired("name")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="email"
                            required={isRequired("email")}
                            fullWidth
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="error" variant="contained" onClick={onClose}>
                        {t("common.cancel")}
                    </Button>
                    <FormikSubmitButton formik={formik} />
                </DialogActions>
            </Dialog>
        </>
    );
}
