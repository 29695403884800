import { useMemo } from "react";
import * as yup from "yup";

import { FormTranslator } from "../../hooks/useFormTranslator";
import { useValidationResult, useYup } from "../../hooks/useYup";

export function usePestSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(() => {
        return yup.object({
            name: yup.string().required(),
        });
    }, []);

    return useValidationResult(validationSchema);
}

export function useEmployeeCreateSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(() => {
        return yup.object({
            name: yup.string().required(),
            email: yup.string().required(),
            allowedCustomers: yup
                .array(yup.number())
                .min(1, translator.getFormError("customers_empty")),
        });
    }, [translator]);

    return useValidationResult(validationSchema);
}

export function useEmployeeEditSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(() => {
        return yup.object({
            allowedCustomers: yup
                .array(yup.number())
                .min(1, translator.getFormError("customers_empty")),
        });
    }, [translator]);

    return useValidationResult(validationSchema);
}
