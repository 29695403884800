import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useCustomerLocationSchema } from "./schema";
import { FormikSubmitButton } from "../../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { CustomerType } from "../../../../models/customer/Customer";
import { CustomerLocationEdit } from "../../../../models/customer/CustomerLocation";
import { EditCustomerLocationViewCallback } from "../../../../service/CustomerLocationService";

interface EditCustomerLocationDialogProps {
    customerType: CustomerType;
    customerLocation: CustomerLocationEdit;
    editCustomerLocation: EditCustomerLocationViewCallback;
}

export function EditCustomerLocationDialog({
    customerType,
    customerLocation,
    editCustomerLocation,
}: EditCustomerLocationDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("customerLocation");
    const { validationSchema, isRequired } = useCustomerLocationSchema(
        customerType,
        translator,
    );

    const [opened, setOpened] = useState(false);

    const onOpen = useCallback(() => setOpened(true), [setOpened]);
    const onClose = useCallback(() => setOpened(false), [setOpened]);

    const onSubmit = useCallback(
        async (values: CustomerLocationEdit) => {
            await editCustomerLocation(values);
            onClose();
        },
        [editCustomerLocation, onClose],
    );

    const formik = useFormik({
        initialValues: customerLocation,
        validationSchema,
        onSubmit,
    });

    return (
        <>
            <Button onClick={onOpen} variant="outlined">
                {t("common.edit", {
                    resource: t("pages.customerLocation.resource"),
                })}
            </Button>
            <Dialog open={opened} fullWidth>
                <DialogTitle>
                    {t("common.edit", {
                        resource: t("pages.customerLocation.resource"),
                    })}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} marginY={1}>
                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="name"
                            required={isRequired("name")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="address"
                            required={isRequired("address")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="postal"
                            required={isRequired("postal")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="residence"
                            required={isRequired("residence")}
                            fullWidth
                        />

                        {(customerType === CustomerType.Company ||
                            customerType === CustomerType.Township) && (
                            <FormikTextField
                                formik={formik}
                                translator={translator}
                                field="commerceNumber"
                                required={isRequired("commerceNumber")}
                                fullWidth
                            />
                        )}

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="email"
                            required={isRequired("email")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="phoneNumber"
                            required={isRequired("phoneNumber")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="mobileNumber"
                            required={isRequired("mobileNumber")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="locationDescription"
                            required={isRequired("locationDescription")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="employmentReason"
                            required={isRequired("employmentReason")}
                            fullWidth
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="error" variant="contained" onClick={onClose}>
                        {t("common.cancel")}
                    </Button>
                    <FormikSubmitButton formik={formik} />
                </DialogActions>
            </Dialog>
        </>
    );
}
