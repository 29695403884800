import { useOidcIdToken } from "@axa-fr/react-oidc";

import { UserClaims } from "../models/user/UserClaims";

export function useCurrentUser(): UserClaims | undefined {
    const { idTokenPayload } = useOidcIdToken();

    if (!idTokenPayload) {
        return undefined;
    }

    return {
        aud: idTokenPayload.aud,
        azp: idTokenPayload.azp,
        iss: idTokenPayload.iss,
        sub: idTokenPayload.sub,
        exp: idTokenPayload.exp,
        iat: idTokenPayload.iat,
        name: idTokenPayload.name,
        email: idTokenPayload.email,
        role: idTokenPayload.role,
    };
}
