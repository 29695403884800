import { useMemo } from "react";
import * as yup from "yup";

import { FormTranslator } from "../../../hooks/useFormTranslator";
import { useValidationResult, useYup } from "../../../hooks/useYup";

export function useWorkOrderSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(
        () =>
            yup.object({
                comment: yup.string(),
            }),
        [],
    );

    return useValidationResult(validationSchema);
}
