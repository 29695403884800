export enum CustomerType {
    Private = "Private",
    Company = "Company",
    Township = "Township",
}

export interface LimitedCustomerInformation {
    name: string;
    type: CustomerType;
    locationCount: number;
}

export interface CustomerAllowedOption {
    customerId: number;
    customerName: string;
    customerLocationId: number;
    customerLocationName: string;
}

export type CustomerAdd = CommonFields & {
    locationName: string;
    locationAddress: string;
    locationPostal: string;
    locationResidence: string;
    locationDescription: string;
    locationEmploymentReason: string;
    locationCommerceNumber: string | undefined;
};

export interface CustomerEdit extends CommonFields {
    id: number;
}

export interface Customer extends CommonFields {
    id: number;
}

export interface CustomerOverview {
    id: number;
    type: CustomerType;
    name: string;
}

interface CommonFields {
    type: CustomerType;
    name: string;
    email: string;
    phoneNumber: string | undefined;
    mobileNumber: string | undefined;
}
