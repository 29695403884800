import { Add } from "@mui/icons-material";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    DialogActions,
    Button,
    IconButton,
} from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { FormikSubmitButton } from "../../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { useOpenState } from "../../../../hooks/useOpenState";
import { PestCreate } from "../../../../models/company/Pest";
import { CreatePestViewCallback } from "../../../../service/CompanyService";
import { usePestSchema } from "../../schema";

interface CreatePestDialogProps {
    createPestCallback: CreatePestViewCallback;
}

const initialValues: PestCreate = {
    name: "",
};

export function CreatePestDialog({
    createPestCallback,
}: CreatePestDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("company.pest");

    const {
        state: open,
        open: openDialog,
        close: closeDialog,
    } = useOpenState();
    const { validationSchema, isRequired } = usePestSchema(translator);

    return (
        <>
            <IconButton onClick={openDialog}>
                <Add />
            </IconButton>
            <Dialog open={open} fullWidth>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={values =>
                        createPestCallback(values).then(closeDialog)
                    }
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t("pages.company.pest.create_header")}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    <FormikTextField
                                        formik={formik}
                                        field="name"
                                        translator={translator}
                                        required={isRequired("name")}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={closeDialog}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
