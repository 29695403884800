import { LinearProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { fromUnixTime } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getWorkOrdersListActions } from "./Actions";
import { ApplicationPagingDataGrid } from "../../../components/Controls/DataGrid/Paging";
import { useDateUtils } from "../../../hooks/useDateUtils";
import { useFormTranslator } from "../../../hooks/useFormTranslator";
import { usePermissions } from "../../../hooks/usePermissions";
import { SortDirection } from "../../../models/pagination/Pagination";
import { WorkOrderOverview } from "../../../models/workorder/WorkOrder";
import { Permissions } from "../../../permissions";
import { useWorkOrderListService } from "../../../service/WorkOrderService";
import { formatCustomerName } from "../../../utils/customer.utils";

interface WorkOrderListProps {
    customerLocationId?: number;
}

export function WorkOrderList({ customerLocationId }: WorkOrderListProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("workorder");
    const dateUtils = useDateUtils();

    const { hasPermission } = usePermissions();
    const canEdit = hasPermission(Permissions.WorkOrdersManage);

    const {
        workOrdersResponse,
        downloading,
        downloadWorkOrder,
        editWorkOrder,
        deleteWorkOrder,
    } = useWorkOrderListService(
        {
            page: 1,
            itemsPerPage: 10,
            itemsPerPageOptions: [10, 20, 50],
            sort: "visitationDate",
            sortDirection: SortDirection.Descending,
        },
        customerLocationId,
    );

    const columns = useMemo<GridColDef<WorkOrderOverview>[]>(
        () => [
            getWorkOrdersListActions({
                canEdit,
                downloading,
                download: downloadWorkOrder,
                editWorkOrder,
                deleteWorkOrder,
                t,
            }),
            {
                field: "customerLocationId",
                headerName: translator.getField("customerLocationId").getName(),
                flex: 1,
                disableExport: true,
                valueGetter: params => formatCustomerName(params.row),
            },
            {
                field: "workListName",
                headerName: translator.getField("workListName").getName(),
                sortable: false,
                disableExport: true,
                flex: 1,
            },
            {
                field: "comment",
                headerName: translator.getField("comment").getName(),
                sortable: false,
                disableExport: true,
                flex: 1,
            },
            {
                field: "visitationDate",
                headerName: translator.getField("visitationDate").getName(),
                flex: 1,
                disableExport: true,
                valueGetter: params =>
                    dateUtils.format(
                        fromUnixTime(params.row.visitationDate),
                        "P",
                    ),
            },
        ],
        [
            canEdit,
            dateUtils,
            deleteWorkOrder,
            downloadWorkOrder,
            downloading,
            editWorkOrder,
            t,
            translator,
        ],
    );

    return workOrdersResponse.response.data ? (
        <ApplicationPagingDataGrid
            pagination={workOrdersResponse.response.data}
            paginationControls={workOrdersResponse.paginationControls}
            columns={columns}
            getRowId={row => row.id}
            removeColumns={
                customerLocationId ? ["customerLocationId"] : undefined
            }
            disableRowSelectionOnClick
            autoHeight
        />
    ) : (
        <LinearProgress />
    );
}
