import { useOidc } from "@axa-fr/react-oidc";
import {
    Card,
    CardActionArea,
    CardHeader,
    CardMedia,
    Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { Page, PagesFinder } from "../../pages";

export function DashboardPage() {
    const { t } = useTranslation();
    const { isAuthenticated } = useOidc();
    const currentUser = useCurrentUser();

    let pages: Page[] = [];
    if (isAuthenticated) {
        pages = PagesFinder.getPages(currentUser).filter(f => f.isDashboard);
    }

    return (
        <Grid container>
            <Grid item xs={false} md={2} />
            <Grid item md={12} lg={8} container spacing={3}>
                {pages.map((page, idx) => {
                    const Icon = page.icon;
                    return (
                        <Grid key={idx} item xs={12} md={3}>
                            <Card>
                                <CardActionArea
                                    component={Link}
                                    to={`/app/${page.path}`}
                                >
                                    <CardMedia component="div">
                                        <Grid
                                            container
                                            justifyContent="center"
                                            paddingTop={3}
                                        >
                                            <Grid item>
                                                {Icon !== undefined ? (
                                                    <Icon fontSize="large" />
                                                ) : (
                                                    ""
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardMedia>
                                    <CardHeader
                                        title={t(page.name)}
                                        titleTypographyProps={{
                                            align: "center",
                                        }}
                                    />
                                </CardActionArea>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
            <Grid item xs={false} md={2} />
        </Grid>
    );
}
