import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmButtonDialog } from "../../../../components/Controls/ConfirmButton/ConfirmButtonDialog";
import { useOpenState } from "../../../../hooks/useOpenState";
import { DeleteCustomerUserListCallback } from "../../../../service/CustomerUserService";

interface DeleteCustomerUserDialogProps {
    userId: number;
    deleteCustomerUser: DeleteCustomerUserListCallback;
}

export function DeleteCustomerUserDialog({
    userId,
    deleteCustomerUser,
}: DeleteCustomerUserDialogProps) {
    const { t } = useTranslation();
    const { state, open, close } = useOpenState();

    const handleClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed) {
                deleteCustomerUser(userId);
            }
            close();
        },
        [close, deleteCustomerUser, userId],
    );

    return (
        <>
            <IconButton onClick={open} color="error">
                <Delete />
            </IconButton>
            <ConfirmButtonDialog
                open={state}
                text={t("pages.customerUser.warning_remove")}
                isDanger
                onClose={handleClose}
            />
        </>
    );
}
