import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { FormikProps } from "formik";

import { FormValues, formikEventWrapper } from "./utils";
import { FormTranslator } from "../../../hooks/useFormTranslator";
import { KeysMatching } from "../../../utils/utils";

type AutoCompleteResolvedProps<TOptions> = AutocompleteProps<
    TOptions,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
>;

export type FormikAutoCompleteProps<T extends FormValues, TOptions> = {
    formik: FormikProps<T>;
    field: Extract<KeysMatching<T, string | number | undefined>, string>;
    translator: FormTranslator;
    helperText?: string;
    required?: boolean;
} & Omit<AutoCompleteResolvedProps<TOptions>, "id" | "value" | "renderInput">;

export function FormikAutoComplete<T extends FormValues, TOptions>({
    formik,
    field,
    translator,
    helperText,
    freeSolo,
    required = false,
    onChange,
    onInputChange,
    onBlur,
    ...props
}: FormikAutoCompleteProps<T, TOptions>) {
    const translatorField = translator.getField(field);
    const label = translatorField.getName();
    const meta = formik.getFieldMeta<string>(field);

    const hasError = meta.touched && meta.error !== undefined;
    const helpText = hasError ? meta.error : helperText;

    const onBlurInternal = formikEventWrapper(formik.handleBlur, onBlur);

    return (
        <Autocomplete
            id={field}
            value={meta.value}
            freeSolo={freeSolo}
            onChange={(e, value, ...extras) => {
                formik.setFieldValue(field, value);

                if (onChange) {
                    onChange(e, value, ...extras);
                }
            }}
            onInputChange={(e, value, ...extras) => {
                if (freeSolo) {
                    formik.setFieldValue(field, value);
                }

                if (onInputChange) {
                    onInputChange(e, value, ...extras);
                }
            }}
            onBlur={onBlurInternal}
            renderInput={params => (
                <TextField
                    name={field}
                    label={label}
                    error={hasError}
                    helperText={helpText}
                    required={required}
                    {...params}
                />
            )}
            {...props}
        />
    );
}
