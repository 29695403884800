import { Grid } from "@mui/material";

import { ChangePasswordInfo } from "./Blocks/ChangePasswordInfo";
import { TwoFactorInfo } from "./Blocks/TwoFactorInfo";
import { UserInfo } from "./Blocks/UserInfo";

export function AccountPage() {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={3} md={6} sm={12}>
                    <UserInfo />
                </Grid>
                <Grid item lg={3} md={6} sm={12}>
                    <ChangePasswordInfo />
                </Grid>
                <Grid item lg={4} md={6} sm={12}>
                    <TwoFactorInfo />
                </Grid>
            </Grid>
        </>
    );
}
