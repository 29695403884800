import { Typography, TypographyProps } from "@mui/material";

type CodeProps = Omit<TypographyProps, "component">;

export function Code(props: CodeProps) {
    return (
        <Typography
            {...props}
            component="code"
            sx={[
                ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
                theme => ({
                    backgroundColor: theme.palette.divider,
                    borderRadius: theme.shape.borderRadius / 4,
                    padding: "0.125em 0.25em",
                    width: "fit-content",
                    fontFamily:
                        "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
                    fontSize: theme.typography.fontSize * 0.95,
                }),
            ]}
        />
    );
}
