export function directoriesMatch(
    dir: string[],
    directories: string[],
    partialMatch = true,
) {
    let compareTo = dir;
    if (partialMatch) {
        compareTo = dir.slice(0, directories.length);
    }
    return compareTo.join() === directories.join();
}
