import { useCallback, useMemo } from "react";
import useSWR, { SWRResponse } from "swr";

import {
    UsePaginationServiceResult,
    usePaginationService,
} from "./PaginationService";
import { useFetch } from "../hooks/useFetch";
import {
    ControlPoint,
    ControlPointsCreate,
    ControlPointForOverview,
    ControlPointSort,
    ControlPointEdit,
} from "../models/controlpoint/ControlPoint";
import { Pagination, SortDirection } from "../models/pagination/Pagination";

export type AddControlPointsCallback = (
    model: ControlPointsCreate,
) => Promise<void>;
export type EditControlPointCallback = (
    controlPointId: number,
    model: ControlPointEdit,
) => Promise<void>;
type SetLocationViewCallback = (
    controlPointId: number,
    floorPlanId: number,
    percentX: number,
    percentY: number,
) => Promise<void>;

interface ControlPointServiceResult {
    controlPointsResponse: UsePaginationServiceResult<
        ControlPointForOverview,
        Error
    >;
    addControlPoints: AddControlPointsCallback;
    editControlPoint: EditControlPointCallback;
}

interface ControlPointFloorPlanServiceViewResult {
    controlPointsResponse: SWRResponse<ControlPoint[], Error>;
    controlPointSortsResponse: SWRResponse<ControlPointSort[], Error>;
    setLocation: SetLocationViewCallback;
}

const defaultPagination: Pagination = {
    page: 1,
    itemsPerPage: 10,
    itemsPerPageOptions: [10, 20, 50],
    sort: "name",
    sortDirection: SortDirection.Ascending,
};

const ControlPointsListPath = "controlpoints";
const ControlPointsCreatePath = "controlpoints";
const ControlPointsEditPath = (controlPointId: number) =>
    `controlpoints/${controlPointId}`;
const ControlPointsForCustomerPath = (customerLocationId: number) =>
    `controlpoints/all?customerLocationId=${customerLocationId}`;
const ControlPointsSortsPath = "controlpoints/sorts";
const ControlPointSetLocationpath = (controlPointId: number) =>
    `controlpoints/${controlPointId}/set-location`;

export function useControlPointService(
    customerLocationId?: number,
): ControlPointServiceResult {
    const fetch = useFetch();

    const params = useMemo<URLSearchParams | undefined>(() => {
        if (customerLocationId) {
            return new URLSearchParams({
                customerLocationId: customerLocationId.toString(),
            });
        }
    }, [customerLocationId]);

    const controlPointsResponse = usePaginationService<
        ControlPointForOverview,
        Error
    >(defaultPagination, ControlPointsListPath, params);

    const addControlPoints = useCallback<AddControlPointsCallback>(
        async body => {
            await fetch.post(ControlPointsCreatePath, body);
            await controlPointsResponse.reload();
        },
        [controlPointsResponse, fetch],
    );

    const editControlPoint = useCallback<EditControlPointCallback>(
        async (id, body) => {
            await fetch.put(ControlPointsEditPath(id), body);
            await controlPointsResponse.reload();
        },
        [controlPointsResponse, fetch],
    );

    return useMemo(
        () => ({
            controlPointsResponse,
            addControlPoints,
            editControlPoint,
        }),
        [addControlPoints, controlPointsResponse, editControlPoint],
    );
}

export function useControlPointFloorPlanService(
    customerLocationId?: number,
): ControlPointFloorPlanServiceViewResult {
    const fetch = useFetch();

    const controlPointsResponse = useSWR<ControlPoint[], Error>(
        customerLocationId
            ? ControlPointsForCustomerPath(customerLocationId)
            : undefined,
    );
    const controlPointSortsResponse = useSWR<ControlPointSort[], Error>(
        customerLocationId ? ControlPointsSortsPath : undefined,
    );

    const setLocation = useCallback<SetLocationViewCallback>(
        async (controlPointId, floorPlanId, percentX, percentY) => {
            await fetch.post(ControlPointSetLocationpath(controlPointId), {
                floorPlanId,
                percentX,
                percentY,
            });
            await controlPointsResponse.mutate();
        },
        [controlPointsResponse, fetch],
    );

    return useMemo(
        () => ({
            controlPointsResponse,
            controlPointSortsResponse,
            setLocation,
        }),
        [controlPointSortsResponse, controlPointsResponse, setLocation],
    );
}
