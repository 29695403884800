import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CalendarList } from "./CalendarList";
import { CustomerPageTitle } from "../../../components/CustomerPageTitle";
import { useCustomerLocationIdFilter } from "../../../hooks/useCustomerLocationIdFilter";
import { useCalendarService } from "../../../service/CalendarService";

export function CalendarIndexEmployeePage() {
    const { t } = useTranslation();
    const { customerId, locationId } = useCustomerLocationIdFilter();

    const { eventsResponse, addEvent, editEvent, deleteEvent, downloadIcal } =
        useCalendarService(locationId);

    return (
        <>
            {customerId && locationId && (
                <CustomerPageTitle
                    headerPrefix={t("pages.calendar.header_customer")}
                    customerId={customerId}
                    locationId={locationId}
                />
            )}
            <Grid container spacing={2}>
                <Grid item xs={12} flexGrow={1}>
                    <CalendarList
                        customerLocationId={locationId}
                        response={eventsResponse}
                        addEvent={addEvent}
                        editEvent={editEvent}
                        deleteEvent={deleteEvent}
                        downloadIcal={downloadIcal}
                    />
                </Grid>
            </Grid>
        </>
    );
}
