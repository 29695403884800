import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from "@mui/material";
import { Formik } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFolderDialogSchema } from "./schema";
import { FormikSubmitButton } from "../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../hooks/useFormTranslator";

interface FolderCreate {
    folder: string;
}

interface AddFolderDialogProps {
    directory: string[];
    onComplete: (folder: string[]) => void;
}

const initialValues: FolderCreate = {
    folder: "",
};

export function AddFolderDialog({
    directory,
    onComplete,
}: AddFolderDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("document");
    const { validationSchema, isRequired } = useFolderDialogSchema(translator);

    const [opened, setOpened] = useState(false);

    const onOpen = useCallback(() => setOpened(true), [setOpened]);
    const onClose = useCallback(() => setOpened(false), [setOpened]);

    return (
        <>
            <Button variant="outlined" onClick={onOpen}>
                {t("pages.document.edit.action_folder")}
            </Button>
            <Dialog open={opened} fullWidth>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async values => {
                        onComplete([...directory].concat(values.folder));
                        onClose();
                    }}
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t("pages.document.edit.action_folder")}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    <FormikTextField
                                        formik={formik}
                                        field="folder"
                                        translator={translator}
                                        required={isRequired("folder")}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={onClose}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
