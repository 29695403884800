import { Description, FolderOpen } from "@mui/icons-material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getDocumentsListActions } from "./Actions";
import {
    DeleteDocumentCallback,
    DownloadDocumentCallback,
} from "./hooks/useDocumentsPage";
import { directoriesMatch } from "./util";
import { ApplicationStandardDataGrid } from "../../../components/Controls/DataGrid/Standard";
import { useFormTranslator } from "../../../hooks/useFormTranslator";
import { usePermissions } from "../../../hooks/usePermissions";
import { Document, Folder } from "../../../models/document/Document";
import { Permissions } from "../../../permissions";

interface DocumentGridProps {
    documents: Document[];
    folders: Folder[];
    currentDirectory: string[];
    downloading: boolean;
    onFolderClick: (directory: string[]) => void;
    downloadDocument: DownloadDocumentCallback;
    deleteDocument: DeleteDocumentCallback;
}

export function DocumentGrid({
    documents,
    folders,
    currentDirectory,
    downloading,
    onFolderClick,
    downloadDocument,
    deleteDocument,
}: DocumentGridProps) {
    const arr = folders as (Document | Folder)[];
    const rows = arr.concat(
        documents.filter(f =>
            directoriesMatch(f.directory, currentDirectory, false),
        ),
    );

    const { t } = useTranslation();
    const translator = useFormTranslator("document");

    const { hasPermission } = usePermissions();
    const canEdit = hasPermission(Permissions.DocumentsManage);

    const columns = useMemo<GridColDef<Document | Folder>[]>(
        () => [
            {
                field: "actions",
                type: "actions",
                getActions: params =>
                    getDocumentsListActions({
                        row: params.row,
                        canEdit,
                        downloading,
                        download: downloadDocument,
                        deleteDocument,
                        t,
                    }),
            },
            {
                field: "icon",
                type: "actions",
                getActions: params => [
                    params.row.type === "document" ? (
                        <GridActionsCellItem
                            icon={<Description />}
                            label=""
                            disableRipple
                        />
                    ) : (
                        <GridActionsCellItem
                            icon={<FolderOpen />}
                            label=""
                            disableRipple
                        />
                    ),
                ],
            },
            {
                field: "name",
                headerName: translator.getField("name").getName(),
                flex: 1,
            },
            {
                field: "description",
                headerName: translator.getField("description").getName(),
                flex: 1,
            },
        ],
        [canEdit, deleteDocument, downloadDocument, downloading, t, translator],
    );

    return (
        <ApplicationStandardDataGrid<Document | Folder>
            columns={columns}
            rows={rows}
            // rows={[]}
            getRowId={row => row.id + "_" + row.name}
            autoHeight
            disableRowSelectionOnClick
            onRowClick={params => {
                if (params.row.type === "folder") {
                    onFolderClick(params.row.directory);
                }
            }}
        />
    );
}
