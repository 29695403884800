import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { WeekPickersDay } from "./RangePickerDay";
import { useCalendarEventSchema } from "./schema";
import { FormikAutoComplete } from "../../../components/Controls/Formik/AutoComplete";
import { FormikDateInput } from "../../../components/Controls/Formik/DateInput";
import { FormikCustomerSelect } from "../../../components/Controls/Formik/Impl/CustomerSelect";
import { FormikSubmitButton } from "../../../components/Controls/Formik/SubmitButton";
import { useFormTranslator } from "../../../hooks/useFormTranslator";
import { EventCreate } from "../../../models/calendar/Event";
import {
    AddEventListCallback,
    useCalendarEventNames,
} from "../../../service/CalendarService";

interface AddDialogProps {
    customerLocationId: number | undefined;
    addEventCallback: AddEventListCallback;
}

function getInitialValues(customerLocationId: number | undefined): EventCreate {
    return {
        name: "",
        customerLocationId: customerLocationId ?? -1,
        startDate: new Date(),
        endDate: new Date(),
    };
}

export function AddDialog({
    customerLocationId,
    addEventCallback,
}: AddDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("calendar");
    const [opened, setOpened] = useState(false);

    const { validationSchema, isRequired } = useCalendarEventSchema(translator);
    const { namesResponse } = useCalendarEventNames(opened, customerLocationId);

    const onOpen = useCallback(() => setOpened(true), [setOpened]);
    const onClose = useCallback(() => setOpened(false), []);

    const onSubmitInternal = useCallback(
        async (values: EventCreate, helpers: FormikHelpers<EventCreate>) => {
            await addEventCallback(values)
                .then(() => helpers.resetForm())
                .then(onClose);
        },
        [addEventCallback, onClose],
    );

    return (
        <>
            <Button variant="outlined" onClick={onOpen}>
                {t("pages.calendar.edit.action_create")}
            </Button>
            <Dialog open={opened} fullWidth>
                <Formik
                    initialValues={getInitialValues(customerLocationId)}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitInternal}
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t("pages.calendar.edit.action_create")}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    <FormikAutoComplete<EventCreate, string>
                                        formik={formik}
                                        field="name"
                                        translator={translator}
                                        required={isRequired("name")}
                                        options={namesResponse.data ?? []}
                                        freeSolo
                                        autoComplete
                                        autoHighlight
                                        autoSelect
                                        blurOnSelect
                                    />

                                    <FormikCustomerSelect
                                        formik={formik}
                                        field="customerLocationId"
                                        translator={translator}
                                        required={isRequired(
                                            "customerLocationId",
                                        )}
                                        opened={opened}
                                        disabled={
                                            customerLocationId !== undefined
                                        }
                                        noCustomerText={t(
                                            "pages.calendar.edit.no_customer",
                                        )}
                                    />

                                    <FormikDateInput
                                        formik={formik}
                                        field="startDate"
                                        translator={translator}
                                        slots={{
                                            day: WeekPickersDay,
                                        }}
                                    />

                                    <FormikDateInput
                                        formik={formik}
                                        field="endDate"
                                        translator={translator}
                                        minDate={formik.values.startDate}
                                        slots={{
                                            day: WeekPickersDay,
                                        }}
                                        slotProps={{
                                            day: {},
                                        }}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={onClose}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
