import { useMemo } from "react";
import * as yup from "yup";

import { FormTranslator } from "../../../../hooks/useFormTranslator";
import { useValidationResult, useYup } from "../../../../hooks/useYup";
import { CustomerType } from "../../../../models/customer/Customer";

export function useCustomerLocationSchema(
    customerType: CustomerType,
    translator: FormTranslator,
) {
    useYup(translator);

    const validationSchema = useMemo(() => {
        return yup.object({
            name: yup.string().required(),
            phoneNumber: yup.string(),
            mobileNumber: yup.string(),
            email: yup.string().email(),
            address: yup.string().required(),
            postal: yup.string().required(),
            residence: yup.string().required(),
            locationDescription: yup.string().required(),
            employmentReason: yup.string().required(),
            commerceNumber:
                customerType === CustomerType.Company ||
                customerType === CustomerType.Township
                    ? yup.number().required()
                    : yup.number(),
        });
    }, [customerType]);

    return useValidationResult(validationSchema);
}
