import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CustomerLocationField } from "./CustomerLocationField";
import { CustomerLocation } from "../../../../models/customer/CustomerLocation";

interface CustomerLocationDataProps {
    customerLocation: CustomerLocation | undefined;
}

export function CustomerLocationData({
    customerLocation,
}: CustomerLocationDataProps) {
    const { t } = useTranslation();

    return (
        <Card>
            <CardHeader title={t("pages.customer.view.headers.base_info")} />
            <CardContent>
                {customerLocation && (
                    <Stack spacing={2}>
                        <CustomerLocationField
                            field="name"
                            value={customerLocation.name}
                        />
                        <CustomerLocationField
                            field="address"
                            value={customerLocation.address}
                        />
                        <CustomerLocationField
                            field="postal"
                            value={customerLocation.postal}
                        />
                        <CustomerLocationField
                            field="residence"
                            value={customerLocation.residence}
                        />
                        <CustomerLocationField
                            field="email"
                            value={customerLocation.email}
                            link={`mailto:${customerLocation.email}`}
                        />
                        <CustomerLocationField
                            field="phoneNumber"
                            value={customerLocation.phoneNumber}
                            link={`tel:${customerLocation.phoneNumber}`}
                        />
                        <CustomerLocationField
                            field="mobileNumber"
                            value={customerLocation.mobileNumber}
                            link={`tel:${customerLocation.mobileNumber}`}
                        />
                        <CustomerLocationField
                            field="locationDescription"
                            value={customerLocation.locationDescription}
                        />
                        <CustomerLocationField
                            field="employmentReason"
                            value={customerLocation.employmentReason}
                        />
                    </Stack>
                )}
            </CardContent>
        </Card>
    );
}
