interface AppEnvironment {
    API_HOST: string;
    API_VERSION: string;
    OIDC_CLIENT_ID: string;
    OIDC_AUTHORITY: string;
}

function required<T>(item: T | undefined, name: string): T {
    if (item) {
        return item;
    } else {
        throw new Error(`${name} is required`);
    }
}

export const Environment: AppEnvironment =
    process.env.NODE_ENV === "production"
        ? {
              API_HOST: `${window.location.protocol}//${window.location.host}`,
              API_VERSION: "1.0",
              OIDC_CLIENT_ID: "website",
              OIDC_AUTHORITY: `${window.location.protocol}//auth.${window.location.host}`,
          }
        : {
              API_HOST: required(process.env.REACT_APP_API_HOST, "API Host"),
              API_VERSION: required(
                  process.env.REACT_APP_API_VERSION,
                  "API Version",
              ),
              OIDC_CLIENT_ID: required(
                  process.env.REACT_APP_OIDC_CLIENT_ID,
                  "Client ID",
              ),
              OIDC_AUTHORITY: required(
                  process.env.REACT_APP_OIDC_AUTHORITY,
                  "Authority",
              ),
          };
