import { Delete, Download } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { TFunction } from "react-i18next";

import {
    DeleteDocumentCallback,
    DownloadDocumentCallback,
} from "./hooks/useDocumentsPage";
import { ConfirmButton } from "../../../components/Controls/ConfirmButton";
import { Document, Folder } from "../../../models/document/Document";

interface Options {
    row: Document | Folder;
    canEdit: boolean;
    downloading: boolean;
    download: DownloadDocumentCallback;
    deleteDocument: DeleteDocumentCallback;
    t: TFunction;
}

export function getDocumentsListActions({
    row,
    canEdit,
    downloading,
    download,
    deleteDocument,
    t,
}: Options) {
    if (row.type === "document") {
        const actions = [
            <IconButton
                key={`document_action_download_${row.id}`}
                onClick={() => download(row)}
                disabled={downloading}
            >
                {downloading ? <CircularProgress size="1em" /> : <Download />}
            </IconButton>,
        ];
        if (canEdit) {
            actions.push(
                <ConfirmButton
                    key={`document_action_delete_${row.id}`}
                    confirmText={t("pages.document.edit.warning_remove")}
                    onConfirm={() => deleteDocument(row)}
                    disabled={downloading}
                    color="error"
                    icon
                    isDanger
                >
                    <Delete />
                </ConfirmButton>,
            );
        }
        return actions;
    } else {
        return [];
    }
}
