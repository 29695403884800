import { useMemo } from "react";
import * as yup from "yup";

import { FormTranslator } from "../../../../hooks/useFormTranslator";
import { useValidationResult, useYup } from "../../../../hooks/useYup";
import { CustomerType } from "../../../../models/customer/Customer";

export type Mode = "add" | "edit";

export function useCustomerSchema(mode: Mode, translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(() => {
        return yup.object({
            type: yup
                .mixed<CustomerType>()
                .oneOf(Object.values(CustomerType))
                .required(),
            name: yup.string().required(),
            email: yup.string().required().email(),
            phoneNumber: yup.string(),
            mobileNumber: yup.string(),
            locationName:
                mode === "add" ? yup.string().required() : yup.string(),
            locationAddress:
                mode === "add" ? yup.string().required() : yup.string(),
            locationPostal:
                mode === "add" ? yup.string().required() : yup.string(),
            locationResidence:
                mode === "add" ? yup.string().required() : yup.string(),
            locationDescription:
                mode === "add" ? yup.string().required() : yup.string(),
            locationEmploymentReason:
                mode === "add" ? yup.string().required() : yup.string(),
            locationCommerceNumber:
                mode === "add"
                    ? yup.number().when("type", {
                          is: (value: CustomerType) =>
                              value === CustomerType.Company ||
                              value === CustomerType.Township,
                          then: schema => schema.required(),
                      })
                    : yup.number(),
        });
    }, [mode]);

    return useValidationResult(validationSchema);
}
