import { useCallback, useMemo } from "react";

import { useFetch } from "../hooks/useFetch";
import {
    ChangePasswordRequest,
    ChangePasswordResponse,
    PASSWORD_MISMATCH_ERROR,
} from "../models/account/Passwords";
import {
    TwoFactorAuth,
    TwoFactorAuthEnableInformationResponse,
    TwoFactorAuthEnableRequest,
    TwoFactorAuthEnableResponse,
    TwoFactorAuthRecoveryCodesResponse,
} from "../models/account/TwoFactor";

export type ChangePasswordCallback = (
    values: ChangePasswordRequest,
) => Promise<ChangePasswordResponse>;
export type GetTwoFactorAuthCallback = () => Promise<TwoFactorAuth>;
export type GetTwoFactorAuthEnableInformationCallback =
    () => Promise<TwoFactorAuthEnableInformationResponse>;
export type TwoFactorAuthEnableCallback = (
    model: TwoFactorAuthEnableRequest,
) => Promise<TwoFactorAuthEnableResponse>;
export type TwoFactorAuthDisableCallback = () => Promise<void>;
export type TwoFactorAuthGenerateRecoveryCodesCallback =
    () => Promise<TwoFactorAuthRecoveryCodesResponse>;

interface AccountServiceResult {
    changePassword: ChangePasswordCallback;
    getTwoFactorAuth: GetTwoFactorAuthCallback;
    getTwoFactorEnableInfo: GetTwoFactorAuthEnableInformationCallback;
    enableTwoFactor: TwoFactorAuthEnableCallback;
    disableTwoFactor: TwoFactorAuthDisableCallback;
    generateRecoveryCodes: TwoFactorAuthGenerateRecoveryCodesCallback;
}

export function useAccountService(): AccountServiceResult {
    const fetch = useFetch();

    const changePassword = useCallback<ChangePasswordCallback>(
        async values => {
            try {
                const request = await fetch.post<
                    ChangePasswordRequest,
                    ChangePasswordResponse
                >("account/changepassword", values);
                return await request.json();
            } catch (ex) {
                console.warn(ex);
                return {
                    success: false,
                    error: PASSWORD_MISMATCH_ERROR,
                };
            }
        },
        [fetch],
    );

    const getTwoFactorAuth = useCallback<GetTwoFactorAuthCallback>(async () => {
        const request = await fetch.get<TwoFactorAuth>("account/twofactor");
        return await request.json();
    }, [fetch]);

    const getTwoFactorEnableInfo =
        useCallback<GetTwoFactorAuthEnableInformationCallback>(async () => {
            const request =
                await fetch.get<TwoFactorAuthEnableInformationResponse>(
                    "account/twofactor/enable",
                );
            return await request.json();
        }, [fetch]);

    const enableTwoFactor = useCallback<TwoFactorAuthEnableCallback>(
        async model => {
            try {
                const request = await fetch.post<
                    TwoFactorAuthEnableRequest,
                    TwoFactorAuthEnableResponse
                >("account/twofactor/enable", model);
                return await request.json();
            } catch (ex) {
                console.warn(ex);
                return {
                    success: false,
                    recoveryCodes: [],
                };
            }
        },
        [fetch],
    );

    const disableTwoFactor =
        useCallback<TwoFactorAuthDisableCallback>(async () => {
            await fetch.post("account/twofactor/disable", {});
        }, [fetch]);

    const generateRecoveryCodes =
        useCallback<TwoFactorAuthGenerateRecoveryCodesCallback>(async () => {
            const request = await fetch.post<
                object,
                TwoFactorAuthRecoveryCodesResponse
            >("account/twofactor/recoverycodes", {});
            return await request.json();
        }, [fetch]);

    return useMemo(
        () => ({
            changePassword,
            getTwoFactorAuth,
            getTwoFactorEnableInfo,
            enableTwoFactor,
            disableTwoFactor,
            generateRecoveryCodes,
        }),
        [
            changePassword,
            getTwoFactorAuth,
            getTwoFactorEnableInfo,
            enableTwoFactor,
            disableTwoFactor,
            generateRecoveryCodes,
        ],
    );
}
