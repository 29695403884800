import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useControlPointCreateSchema } from "./schema";
import { FormikCustomerSelect } from "../../components/Controls/Formik/Impl/CustomerSelect";
import { FormikSelect } from "../../components/Controls/Formik/Select";
import { FormikSubmitButton } from "../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../hooks/useFormTranslator";
import { useOpenState } from "../../hooks/useOpenState";
import {
    ControlPointsCreate,
    ControlPointType,
} from "../../models/controlpoint/ControlPoint";
import { useCompanyControlPointSortService } from "../../service/CompanyService";
import { AddControlPointsCallback } from "../../service/ControlPointService";

interface AddDialogProps {
    customerLocationId: number | undefined;
    addControlPointsCallback: AddControlPointsCallback;
}

function getInitialValues(
    customerLocationId: number | undefined,
): ControlPointsCreate {
    return {
        name: "",
        customerLocationId: customerLocationId ?? -1,
        type: ControlPointType.MONITOR_MECHANICAL,
        sortId: -1,
        room: "",
        exactLocation: "",
    };
}

export function AddDialog({
    customerLocationId,
    addControlPointsCallback,
}: AddDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("controlpoint");
    const { open, close, state } = useOpenState();
    const controlPointSortService = useCompanyControlPointSortService(state);

    const { validationSchema, isRequired } =
        useControlPointCreateSchema(translator);

    const onSubmitInternal = useCallback(
        async (
            values: ControlPointsCreate,
            helpers: FormikHelpers<ControlPointsCreate>,
        ) => {
            await addControlPointsCallback(values)
                .then(() => helpers.resetForm())
                .then(close);
        },
        [addControlPointsCallback, close],
    );

    return (
        <>
            <Button variant="outlined" onClick={open}>
                {t("pages.controlpoint.edit.action_create")}
            </Button>
            <Dialog open={state} fullWidth>
                <Formik
                    initialValues={getInitialValues(customerLocationId)}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitInternal}
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t("pages.controlpoint.edit.action_create")}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    <FormikTextField
                                        formik={formik}
                                        field="name"
                                        translator={translator}
                                        required={isRequired("name")}
                                    />

                                    <FormikCustomerSelect
                                        formik={formik}
                                        field="customerLocationId"
                                        translator={translator}
                                        opened={state}
                                        disabled={
                                            customerLocationId !== undefined
                                        }
                                        noCustomerText={t(
                                            "pages.controlpoint.edit.no_customer",
                                        )}
                                    />

                                    <FormikSelect
                                        formik={formik}
                                        field="type"
                                        translator={translator}
                                        required={isRequired("type")}
                                    >
                                        {Object.keys(ControlPointType).map(
                                            controlPointType => (
                                                <MenuItem
                                                    key={`typeId_selection_${controlPointType}`}
                                                    value={controlPointType}
                                                >
                                                    {translator
                                                        .getField("type")
                                                        .getEnum(
                                                            controlPointType,
                                                        )}
                                                </MenuItem>
                                            ),
                                        )}
                                    </FormikSelect>

                                    {controlPointSortService
                                        .controlPointSortsResponse.data && (
                                        <FormikSelect
                                            formik={formik}
                                            field="sortId"
                                            translator={translator}
                                            required={isRequired("sortId")}
                                        >
                                            {controlPointSortService.controlPointSortsResponse.data.map(
                                                controlPointSort => (
                                                    <MenuItem
                                                        key={`sortId_selection_${controlPointSort.id}`}
                                                        value={
                                                            controlPointSort.id
                                                        }
                                                    >
                                                        {controlPointSort.name}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </FormikSelect>
                                    )}

                                    <FormikTextField
                                        formik={formik}
                                        field="room"
                                        translator={translator}
                                        required={isRequired("room")}
                                    />

                                    <FormikTextField
                                        formik={formik}
                                        field="exactLocation"
                                        translator={translator}
                                        required={isRequired("exactLocation")}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={close}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
