import { endOfDay, getUnixTime, startOfDay } from "date-fns";
import fileDownload from "js-file-download";
import { useCallback, useMemo } from "react";
import useSWR, { SWRResponse } from "swr";

import {
    UsePaginationServiceResult,
    usePaginationService,
} from "./PaginationService";
import { useFetch } from "../hooks/useFetch";
import { Event, EventCreate, EventEdit } from "../models/calendar/Event";
import { Error } from "../models/error/Error";
import { Pagination, SortDirection } from "../models/pagination/Pagination";

export type AddEventListCallback = (body: EventCreate) => Promise<void>;
export type EditEventListCallback = (
    id: number,
    body: EventEdit,
) => Promise<void>;
export type DownloadIcalListCallback = (
    id: number,
    name: string,
    companyName: string,
) => Promise<void>;
export type DeleteEventListCallback = (id: number) => Promise<void>;

export type GetEventNamesCallback = () => Promise<string[]>;

interface CalendarServiceListResult {
    eventsResponse: UsePaginationServiceResult<Event, Error>;
    addEvent: AddEventListCallback;
    editEvent: EditEventListCallback;
    downloadIcal: DownloadIcalListCallback;
    deleteEvent: DeleteEventListCallback;
}

interface CalendarServiceEventNamesResult {
    namesResponse: SWRResponse<string[], Error>;
}

export const CalendarListPath = "calendar/events";
export const CalendarCreatePath = "calendar/events";
export const CalendarEditPath = (id: number) => `calendar/events/${id}`;
export const CalendarDownloadPath = (id: number) =>
    `calendar/events/${id}/ical`;
export const CalendarDeletePath = (id: number) => `calendar/events/${id}`;

export const CalendarEventNamesPath = "calendar/names";

const defaultPagination: Pagination = {
    page: 1,
    itemsPerPage: 10,
    itemsPerPageOptions: [10, 20, 50],
    sort: "startDate",
    sortDirection: SortDirection.Ascending,
};

export function useCalendarService(
    customerLocationId?: number,
): CalendarServiceListResult {
    const fetch = useFetch();

    const params = useMemo<URLSearchParams | undefined>(() => {
        if (customerLocationId) {
            return new URLSearchParams({
                customerLocationId: customerLocationId.toString(),
            });
        }
    }, [customerLocationId]);

    const eventsResponse = usePaginationService<Event, Error>(
        defaultPagination,
        CalendarListPath,
        params,
    );

    const addEvent = useCallback<AddEventListCallback>(
        async body => {
            await fetch.post(CalendarCreatePath, {
                ...body,
                startDate: getUnixTime(startOfDay(body.startDate)),
                endDate: getUnixTime(endOfDay(body.endDate)),
            });
            await eventsResponse.response.mutate();
        },
        [fetch, eventsResponse],
    );

    const editEvent = useCallback<EditEventListCallback>(
        async (id, body) => {
            await fetch.post(CalendarEditPath(id), {
                ...body,
                startDate: getUnixTime(startOfDay(body.startDate)),
                endDate: getUnixTime(endOfDay(body.endDate)),
            });
            await eventsResponse.response.mutate();
        },
        [fetch, eventsResponse],
    );

    const downloadIcal = useCallback<DownloadIcalListCallback>(
        async (id, name, companyName) => {
            const blob = await fetch
                .get(CalendarDownloadPath(id))
                .then(response => response.blob());
            fileDownload(blob, `${companyName}-${name}.ics`);
        },
        [fetch],
    );

    const deleteEvent = useCallback<DeleteEventListCallback>(
        async id => {
            await fetch.delete(CalendarDeletePath(id));
            await eventsResponse.response.mutate();
        },
        [fetch, eventsResponse],
    );

    return useMemo(
        () => ({
            eventsResponse,
            addEvent,
            editEvent,
            downloadIcal,
            deleteEvent,
        }),
        [eventsResponse, addEvent, editEvent, downloadIcal, deleteEvent],
    );
}

export function useCalendarEventNames(
    shouldRun: boolean,
    customerLocationId?: number,
): CalendarServiceEventNamesResult {
    const params = useMemo<URLSearchParams | undefined>(() => {
        if (customerLocationId) {
            return new URLSearchParams({
                customerLocationId: customerLocationId.toString(),
            });
        }
    }, [customerLocationId]);

    const namesResponse = useSWR<string[], Error>(
        shouldRun
            ? params
                ? `${CalendarEventNamesPath}?${params.toString()}`
                : CalendarEventNamesPath
            : undefined,
    );

    return useMemo(() => ({ namesResponse }), [namesResponse]);
}
