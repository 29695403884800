import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ApplicationPagingDataGrid } from "../../../../components/Controls/DataGrid/Paging";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { CustomerUser } from "../../../../models/customer/CustomerUser";
import { PagedResults } from "../../../../models/pagination/PagedResults";
import {
    AddCustomerUserListCallback,
    DeleteCustomerUserListCallback,
} from "../../../../service/CustomerUserService";
import { PaginationControls } from "../../../../service/PaginationService";
import { AddCustomerUserDialog } from "../../User/EditDialog/AddCustomerUserDialog";
import { DeleteCustomerUserDialog } from "../../User/EditDialog/DeleteCustomerUserDialog";

interface CustomerUsersDataProps {
    users: PagedResults<CustomerUser> | undefined;
    paginationControls: PaginationControls;
    addCustomerUser: AddCustomerUserListCallback;
    deleteCustomerUser: DeleteCustomerUserListCallback;
}

export function CustomerUsersData({
    users,
    paginationControls,
    addCustomerUser,
    deleteCustomerUser,
}: CustomerUsersDataProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("customerUser");

    const columns = useMemo<GridColDef<CustomerUser>[]>(
        () => [
            {
                field: "actions",
                type: "actions",
                getActions: params => [
                    <DeleteCustomerUserDialog
                        userId={params.row.id}
                        deleteCustomerUser={deleteCustomerUser}
                    />,
                ],
            },
            {
                field: "name",
                headerName: translator.getField("name").getName(),
                flex: 1,
            },
            {
                field: "email",
                headerName: translator.getField("email").getName(),
                flex: 1,
            },
        ],
        [deleteCustomerUser, translator],
    );

    return (
        <Card>
            <CardHeader title={t("pages.customer.view.headers.users")} />
            <CardContent>
                {users ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AddCustomerUserDialog
                                addCustomerUser={addCustomerUser}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ApplicationPagingDataGrid
                                pagination={users}
                                paginationControls={paginationControls}
                                columns={columns}
                                getRowId={row => row.id}
                                autoHeight
                                disableRowSelectionOnClick
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
            </CardContent>
        </Card>
    );
}
