import { Delete, Download } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { TFunction } from "react-i18next";

import { ConfirmButton } from "../../../components/Controls/ConfirmButton";
import { WorkOrderOverview } from "../../../models/workorder/WorkOrder";
import {
    DeleteWorkOrderListCallback,
    DownloadWorkOrderListCallback,
    EditWorkOrderListCallback,
} from "../../../service/WorkOrderService";
import { createActionsColumn } from "../../../utils/datagrid.utils";
import { EditDialog } from "../Dialog/EditDialog";

interface Options {
    canEdit: boolean;
    downloading: boolean;
    download: DownloadWorkOrderListCallback;
    editWorkOrder: EditWorkOrderListCallback;
    deleteWorkOrder: DeleteWorkOrderListCallback;
    t: TFunction;
}

export function getWorkOrdersListActions({
    canEdit,
    downloading,
    download,
    editWorkOrder,
    deleteWorkOrder,
    t,
}: Options) {
    return createActionsColumn<WorkOrderOverview>(params => {
        const row = params.row;

        let confirmText = t("pages.workorder.index.warning_remove");
        if (row.containsControlPointUsages) {
            confirmText +=
                "\n\n" + t("pages.workorder.index.warning_remove_cp");
        }

        const actions = [
            <IconButton
                key={`workorders_action_download_${row.id}`}
                onClick={() => download(row.id)}
            >
                {downloading ? <CircularProgress size="1em" /> : <Download />}
            </IconButton>,
        ];
        if (canEdit) {
            actions.push(
                <EditDialog
                    key={`workorders_action_delete_${row.id}`}
                    workOrder={row}
                    editWorkOrder={editWorkOrder}
                />,
            );
            actions.push(
                <ConfirmButton
                    key={`workorders_action_delete_${row.id}`}
                    confirmText={confirmText}
                    onConfirm={() => deleteWorkOrder(row.id)}
                    disabled={downloading}
                    color="error"
                    icon
                    isDanger
                >
                    <Delete />
                </ConfirmButton>,
            );
        }
        return actions;
    });
}
