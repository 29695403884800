import { useMemo } from "react";
import * as yup from "yup";

import { FormTranslator } from "../../../hooks/useFormTranslator";
import { useValidationResult, useYup } from "../../../hooks/useYup";

export function useDocumentDialogSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(() => {
        return yup.object({
            files: yup.array<File[]>().required().min(1),
            directory: yup.array<string[]>().required(),
            description: yup.string(),
        });
    }, []);

    return useValidationResult(validationSchema);
}

export function useFolderDialogSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(() => {
        return yup.object({
            folder: yup.string().required(),
        });
    }, []);

    return useValidationResult(validationSchema);
}
