import { Box, Grid } from "@mui/material";

import { WorkOrderList } from "./WorkOrderList";
import { CustomerDropdown } from "../../../components/Controls/CustomerDropdown";
import { useCurrentCustomer } from "../../../context/AppContext";

export function WorkOrderIndexCustomerPage() {
    const currentCustomer = useCurrentCustomer();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ marginBottom: 1 }}>
                    <Grid container>
                        <Grid item xs={3}>
                            <CustomerDropdown />
                        </Grid>
                    </Grid>
                </Box>
                <WorkOrderList
                    customerLocationId={currentCustomer?.customerLocationId}
                />
            </Grid>
        </Grid>
    );
}
