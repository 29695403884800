import * as Datefns from "date-fns";
import { useMemo } from "react";

import { useLocales } from "./useLocales";

type FormatOptions = Omit<
    NonNullable<Parameters<typeof Datefns.format>[2]>,
    "locale"
>;

export class DateUtils {
    private locale: Datefns.Locale;

    constructor(locale: Datefns.Locale) {
        this.locale = locale;
    }

    public format(
        date: Date | number,
        format: string,
        options?: FormatOptions,
    ) {
        return Datefns.format(
            date,
            format,
            Object.assign({ locale: this.locale }, options),
        );
    }
}

export function useDateUtils() {
    const { libraries } = useLocales();

    return useMemo(() => new DateUtils(libraries.datefns), [libraries.datefns]);
}
