import { useCallback, useMemo } from "react";

import {
    UsePaginationServiceResult,
    usePaginationService,
} from "./PaginationService";
import { useFetch } from "../hooks/useFetch";
import { CustomerUser, CustomerUserAdd } from "../models/customer/CustomerUser";
import { Pagination } from "../models/pagination/Pagination";

export type AddCustomerUserListCallback = (
    body: CustomerUserAdd,
) => Promise<void>;
export type DeleteCustomerUserListCallback = (userId: number) => Promise<void>;

interface CustomerUserServiceListResult {
    customerUsersResponse: UsePaginationServiceResult<CustomerUser, Error>;
    addCustomerUser: AddCustomerUserListCallback;
    deleteCustomerUser: DeleteCustomerUserListCallback;
}

export const CustomerUserListPath = (id: number) => `customers/${id}/users`;
export const CustomerUserCreatePath = (customerId: number) =>
    `customers/${customerId}/users`;
export const CustomerUserDeletePath = (customerId: number, userId: number) =>
    `customers/${customerId}/users/${userId}`;

export function useCustomerUserListService(
    customerId: number,
    defaultPagination: Pagination,
): CustomerUserServiceListResult {
    const fetch = useFetch();
    const customerUsersResponse = usePaginationService<CustomerUser, Error>(
        defaultPagination,
        CustomerUserListPath(customerId),
    );

    const addCustomerUser = useCallback<AddCustomerUserListCallback>(
        async body => {
            await fetch.post(CustomerUserCreatePath(customerId), body);
            await customerUsersResponse.response.mutate();
        },
        [fetch, customerId, customerUsersResponse.response],
    );

    const deleteCustomerUser = useCallback<DeleteCustomerUserListCallback>(
        async userId => {
            await fetch.delete(CustomerUserDeletePath(customerId, userId));
            await customerUsersResponse.response.mutate();
        },
        [fetch, customerId, customerUsersResponse.response],
    );

    return useMemo(
        () => ({
            customerUsersResponse,
            addCustomerUser,
            deleteCustomerUser,
        }),
        [addCustomerUser, deleteCustomerUser, customerUsersResponse],
    );
}
