import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    List,
    ListItemText,
    Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ControlPointItemDrag } from "./ControlPointItemDrag";
import { DragLayer } from "./DragLayer";
import { FloorPlanImageDrop } from "./FloorPlanImageDrop";
import { DragEndResult } from "./utils";
import { use404Page } from "../../../hooks/use404Page";
import { usePermissions } from "../../../hooks/usePermissions";
import { Permissions } from "../../../permissions";
import { useControlPointFloorPlanService } from "../../../service/ControlPointService";
import { useFloorPlanViewService } from "../../../service/FloorPlanService";

interface FloorPlanViewContentProps {
    floorPlanId: number;
}

function FloorPlanViewErrorRedirect() {
    use404Page(true);
    return <></>;
}

function FloorPlanViewContent({ floorPlanId }: FloorPlanViewContentProps) {
    const { t } = useTranslation();
    const { hasPermission } = usePermissions();
    const canEdit = hasPermission(Permissions.FloorPlansManage);

    const { floorPlanResponse } = useFloorPlanViewService(floorPlanId);
    const { controlPointsResponse, controlPointSortsResponse, setLocation } =
        useControlPointFloorPlanService(
            floorPlanResponse.data?.customerLocationId,
        );

    const floorPlansUnassigned = useMemo(
        () =>
            controlPointsResponse.data
                ?.filter(f => f.floorPlanId === undefined)
                ?.sort((item1, item2) =>
                    item1.name.localeCompare(item2.name, "en", {
                        sensitivity: "base",
                    }),
                ) ?? [],
        [controlPointsResponse.data],
    );

    const floorPlansAssigned = useMemo(
        () =>
            controlPointsResponse.data
                ?.filter(f => f.floorPlanId === floorPlanId)
                ?.sort((item1, item2) =>
                    item1.name.localeCompare(item2.name, "en", {
                        sensitivity: "base",
                    }),
                ) ?? [],
        [controlPointsResponse.data, floorPlanId],
    );

    const onDragEnd = useCallback(
        (result: DragEndResult) => {
            setLocation(
                result.controlPointId,
                floorPlanId,
                result.percentX,
                result.percentY,
            );
        },
        [floorPlanId, setLocation],
    );

    return (
        <>
            <Helmet title={t("pages.floorplan.header_view")} />
            <DndProvider backend={HTML5Backend}>
                <DragLayer />
                <Grid container spacing={3}>
                    {canEdit && (
                        <Grid item xs={2}>
                            <Card>
                                <CardHeader
                                    title={t(
                                        "pages.floorplan.view.headers.controlpoints",
                                    )}
                                />
                                <CardContent>
                                    <List
                                        subheader={t(
                                            "pages.floorplan.view.texts.new_controlpoints",
                                        )}
                                        sx={{ marginBottom: 3 }}
                                    >
                                        {floorPlansUnassigned.map(
                                            controlPoint => (
                                                <ControlPointItemDrag
                                                    key={`floorplan_controlpoint_unused_${controlPoint.id}`}
                                                    controlPoint={controlPoint}
                                                    controlPointSort={controlPointSortsResponse.data?.find(
                                                        f =>
                                                            f.id ===
                                                            controlPoint.sortId,
                                                    )}
                                                    canDrag
                                                    onDragEnd={onDragEnd}
                                                />
                                            ),
                                        )}
                                        {floorPlansUnassigned.length === 0 && (
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography fontStyle="italic">
                                                        {t(
                                                            "pages.floorplan.view.texts.new_controlpoints_empty",
                                                        )}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    </List>
                                    <List
                                        subheader={t(
                                            "pages.floorplan.view.texts.added_controlpoints",
                                        )}
                                    >
                                        {floorPlansAssigned.map(
                                            controlPoint => (
                                                <ControlPointItemDrag
                                                    key={`floorplan_controlpoint_used_${controlPoint.id}`}
                                                    controlPoint={controlPoint}
                                                    controlPointSort={controlPointSortsResponse.data?.find(
                                                        f =>
                                                            f.id ===
                                                            controlPoint.sortId,
                                                    )}
                                                    canDrag
                                                    onDragEnd={onDragEnd}
                                                />
                                            ),
                                        )}
                                        {floorPlansAssigned.length === 0 && (
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography fontStyle="italic">
                                                        {t(
                                                            "pages.floorplan.view.texts.added_controlpoints_empty",
                                                        )}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    <Grid item xs>
                        <Card>
                            <CardHeader
                                title={t("pages.floorplan.view.headers.image")}
                            />
                            <CardContent>
                                <FloorPlanImageDrop
                                    imageUrl={floorPlanResponse.data?.accessUrl}
                                    canDrop={canEdit}
                                >
                                    {floorPlansAssigned.map(controlPoint => (
                                        <Box
                                            key={`floorplan_controlpoint_on_map_${controlPoint.id}`}
                                            sx={{
                                                position: "absolute",
                                                left: `${controlPoint.floorPlanPercentX}%`,
                                                top: `${controlPoint.floorPlanPercentY}%`,
                                            }}
                                        >
                                            <ControlPointItemDrag
                                                controlPoint={controlPoint}
                                                controlPointSort={controlPointSortsResponse.data?.find(
                                                    f =>
                                                        f.id ===
                                                        controlPoint.sortId,
                                                )}
                                                canDrag={canEdit}
                                                showTextInTooltip
                                                onDragEnd={onDragEnd}
                                            />
                                        </Box>
                                    ))}
                                </FloorPlanImageDrop>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </DndProvider>
        </>
    );
}

export function FloorPlanViewPage() {
    const { floorPlanId } = useParams();

    let floorPlanIdParsed: number | undefined = undefined;
    if (floorPlanId) {
        floorPlanIdParsed = parseInt(floorPlanId, 10);
        if (isNaN(floorPlanIdParsed)) {
            floorPlanIdParsed = undefined;
        }
    }

    if (floorPlanIdParsed === undefined) {
        return <FloorPlanViewErrorRedirect />;
    } else {
        return <FloorPlanViewContent floorPlanId={floorPlanIdParsed} />;
    }
}
