import { Theme } from "@mui/material";
import {
    DataGrid,
    DataGridProps,
    GridLogicOperator,
    GridRowId,
    GridValidRowModel,
} from "@mui/x-data-grid";
import { useMemo } from "react";

import { useLocales } from "../../../hooks/useLocales";

export interface ApplicationStandardDataGridProps<R extends GridValidRowModel>
    extends Omit<DataGridProps<R>, "localeText"> {
    removeColumns?: GridRowId[];
    noRowsLabel?: string;
}

export function ApplicationStandardDataGrid<R extends GridValidRowModel>({
    columns,
    removeColumns = [],
    noRowsLabel,
    ...props
}: ApplicationStandardDataGridProps<R>) {
    const { libraries } = useLocales();

    const filteredColumns = useMemo(
        () => columns.filter(f => !removeColumns.includes(f.field)),
        [columns, removeColumns],
    );

    const localeText =
        libraries.datagrid.components.MuiDataGrid.defaultProps.localeText;
    if (noRowsLabel) {
        localeText.noRowsLabel = noRowsLabel;
    }

    return (
        <DataGrid
            {...props}
            columns={filteredColumns}
            localeText={localeText}
            disableColumnSelector
            disableDensitySelector
            slotProps={{
                toolbar: {
                    showQuickFilter: false,
                },
                filterPanel: {
                    logicOperators: [GridLogicOperator.And],
                    columnsSort: "asc",
                    filterFormProps: {
                        logicOperatorInputProps: {
                            variant: "outlined",
                            size: "small",
                        },
                        columnInputProps: {
                            variant: "outlined",
                            size: "small",
                            sx: { mt: "auto" },
                        },
                        operatorInputProps: {
                            variant: "outlined",
                            size: "small",
                            sx: { mt: "auto" },
                        },
                        valueInputProps: {
                            InputComponentProps: {
                                variant: "outlined",
                                size: "small",
                            },
                        },
                        deleteIconProps: {
                            sx: {
                                "& .MuiSvgIcon-root": { color: "#d32f2f" },
                            },
                        },
                    },
                    sx: {
                        "& .MuiDataGrid-filterForm": { p: 2 },
                        "& .MuiDataGrid-filterForm::nth-of-type(even)": {
                            backgroundColor: (theme: Theme) =>
                                theme.palette.mode === "dark"
                                    ? "#444"
                                    : "#f5f5f5",
                        },
                        "& .MuiDataGrid-filterFormLogicOperatorInput": {
                            mr: 2,
                        },
                        "& .MuiDataGrid-filterFormColumnInput": {
                            mr: 2,
                            width: 150,
                        },
                        "& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
                        "& .MuiDataGrid-filterFormValueInput": { width: 200 },
                    },
                },
            }}
        />
    );
}
