import { Button } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmButtonDialog } from "../../../../components/Controls/ConfirmButton/ConfirmButtonDialog";
import { useOpenState } from "../../../../hooks/useOpenState";

interface RecoveryCodesDialogProps {
    onFinish: () => void;
}

export function RecoveryCodesDialog({ onFinish }: RecoveryCodesDialogProps) {
    const { t } = useTranslation();
    const {
        state: open,
        open: openDialog,
        close: closeDialog,
    } = useOpenState();

    const handleClose = useCallback(
        (accepted: boolean) => {
            if (accepted) {
                onFinish();
            }
            closeDialog();
        },
        [closeDialog, onFinish],
    );

    return (
        <>
            <Button variant="outlined" onClick={openDialog}>
                {t(
                    "pages.account.twofactor.recovery_codes.dialog.create.button",
                )}
            </Button>
            <ConfirmButtonDialog
                open={open}
                title={t(
                    "pages.account.twofactor.recovery_codes.dialog.create.header",
                )}
                text={t(
                    "pages.account.twofactor.recovery_codes.dialog.create.description",
                )}
                onClose={handleClose}
            />
        </>
    );
}
