import { fromUnixTime } from "date-fns";
import fileDownload from "js-file-download";
import { useCallback, useMemo, useState } from "react";

import {
    UsePaginationServiceResult,
    usePaginationService,
} from "./PaginationService";
import { useDateUtils } from "../hooks/useDateUtils";
import { useFetch } from "../hooks/useFetch";
import { Error } from "../models/error/Error";
import { Pagination } from "../models/pagination/Pagination";
import {
    WorkOrder,
    WorkOrderEdit,
    WorkOrderOverview,
} from "../models/workorder/WorkOrder";
import { formatCustomerName } from "../utils/customer.utils";

export type DownloadWorkOrderListCallback = (id: number) => Promise<void>;
export type EditWorkOrderListCallback = (
    id: number,
    model: WorkOrderEdit,
) => Promise<void>;
export type DeleteWorkOrderListCallback = (id: number) => Promise<void>;

interface WorkOrderServiceListResult {
    workOrdersResponse: UsePaginationServiceResult<WorkOrderOverview, Error>;
    downloading: boolean;
    downloadWorkOrder: DownloadWorkOrderListCallback;
    editWorkOrder: EditWorkOrderListCallback;
    deleteWorkOrder: DeleteWorkOrderListCallback;
}

const WorkOrderListPath = "workorders";
const WorkOrderGetPath = (id: number) => `workorders/${id}`;
const WorkOrderEditPath = (id: number) => `workorders/${id}`;
const WorkOrderDownloadPath = (id: number) => `workorders/${id}/download`;
const WorkOrderDeletePath = (id: number) => `workorders/${id}`;

export function useWorkOrderListService(
    defaultPagination: Pagination,
    customerLocationId?: number,
): WorkOrderServiceListResult {
    const fetch = useFetch();
    const dateUtils = useDateUtils();

    const [downloading, setDownloading] = useState(false);

    const params = useMemo<URLSearchParams | undefined>(() => {
        if (customerLocationId) {
            return new URLSearchParams({
                customerLocationId: customerLocationId.toString(),
            });
        }
    }, [customerLocationId]);

    const workOrdersResponse = usePaginationService<WorkOrderOverview, Error>(
        defaultPagination,
        WorkOrderListPath,
        params,
    );

    const downloadWorkOrder = useCallback<DownloadWorkOrderListCallback>(
        async id => {
            setDownloading(true);
            try {
                const workOrder = await fetch
                    .get<WorkOrder>(WorkOrderGetPath(id))
                    .then(response => response.json());
                const blob = await fetch
                    .get(WorkOrderDownloadPath(id))
                    .then(response => response.blob());
                fileDownload(
                    blob,
                    `${formatCustomerName(workOrder)} - ${
                        workOrder.workListName
                    } - ${dateUtils.format(
                        fromUnixTime(workOrder.visitationDate),
                        "P",
                    )}.pdf`,
                );
            } finally {
                setDownloading(false);
            }
        },
        [dateUtils, fetch],
    );

    const editWorkOrder = useCallback<EditWorkOrderListCallback>(
        async (id, model) => {
            await fetch
                .put(WorkOrderEditPath(id), model)
                .finally(workOrdersResponse.reload);
        },
        [fetch, workOrdersResponse.reload],
    );

    const deleteWorkOrder = useCallback<DeleteWorkOrderListCallback>(
        async id => {
            await fetch
                .delete(WorkOrderDeletePath(id))
                .finally(workOrdersResponse.reload);
        },
        [fetch, workOrdersResponse.reload],
    );

    return {
        workOrdersResponse,
        downloading,
        downloadWorkOrder,
        editWorkOrder,
        deleteWorkOrder,
    };
}
