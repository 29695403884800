import { Box, Divider, Typography } from "@mui/material";
import {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Helmet, HelmetProps } from "react-helmet-async";

import { useCompanyMetadata } from "../../context/AppContext";

interface PageRootProps {
    title: string;
}

export function PageRoot({
    title,
    children,
}: PropsWithChildren<PageRootProps>) {
    const metadata = useCompanyMetadata();

    const headerFormat = useMemo(() => ` | ${metadata?.name}`, [metadata]);
    const stripFormat = useCallback(
        (header: string) => header.replace(headerFormat, ""),
        [headerFormat],
    );

    const [strippedTitle, setStrippedTitle] = useState(title);

    const updateClientState = useCallback(
        (newState: HelmetProps) => {
            setStrippedTitle(stripFormat(newState.title ?? ""));
        },
        [stripFormat, setStrippedTitle],
    );

    useEffect(() => {
        setStrippedTitle(title);
    }, [title]);

    return (
        <>
            <Helmet
                title={title}
                titleTemplate={`%s${headerFormat}`}
                onChangeClientState={updateClientState}
            />
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h4" component="h1">
                    {strippedTitle}
                </Typography>
            </Box>
            <Divider sx={{ marginY: 2 }} />
            {children}
        </>
    );
}
