import { Helmet } from "react-helmet-async";

import { useCustomerLocationViewService } from "../service/CustomerLocationService";

interface PageTitleProps {
    headerPrefix: string;
    customerId: number;
    locationId: number;
}

export function CustomerPageTitle({
    headerPrefix,
    customerId,
    locationId,
}: PageTitleProps) {
    const { customerResponse, customerLocationResponse } =
        useCustomerLocationViewService(customerId, locationId);

    return (
        <Helmet
            title={`${headerPrefix}: ${customerResponse.data?.name} - ${customerLocationResponse.data?.name}`}
        />
    );
}
