import { useMemo } from "react";
import * as yup from "yup";

import { FormTranslator } from "../../hooks/useFormTranslator";
import { useValidationResult, useYup } from "../../hooks/useYup";
import { ControlPointType } from "../../models/controlpoint/ControlPoint";

export function useControlPointCreateSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(
        () =>
            yup.object({
                name: yup.string().required(),
                customerLocationId: yup
                    .number()
                    .required()
                    .test(
                        "Cannot be default value",
                        translator
                            .getField("customerLocationId")
                            .getGlobalError("required"),
                        num => num !== -1,
                    ),
                type: yup
                    .mixed<ControlPointType>()
                    .oneOf(Object.values(ControlPointType)),
                sortId: yup.number().optional(),
                room: yup.string().required(),
            }),
        [translator],
    );

    return useValidationResult(validationSchema);
}

export function useControlPointEditSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(
        () =>
            yup.object({
                sortId: yup.number().optional(),
                room: yup.string().required(),
            }),
        [],
    );

    return useValidationResult(validationSchema);
}
