import { Button } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmButtonDialog } from "../../../../components/Controls/ConfirmButton/ConfirmButtonDialog";
import { useOpenState } from "../../../../hooks/useOpenState";

interface DisableDialogProps {
    onFinish: () => void;
}

export function DisableDialog({ onFinish }: DisableDialogProps) {
    const { t } = useTranslation();
    const {
        state: open,
        open: openDialog,
        close: closeDialog,
    } = useOpenState();

    const handleClose = useCallback(
        (accepted: boolean) => {
            if (accepted) {
                onFinish();
            }
            closeDialog();
        },
        [closeDialog, onFinish],
    );

    return (
        <>
            <Button onClick={openDialog} variant="contained" color="error">
                {t("pages.account.twofactor.disable.dialog.button")}
            </Button>
            <ConfirmButtonDialog
                open={open}
                title={t("pages.account.twofactor.disable.dialog.header")}
                text={t("pages.account.twofactor.disable.dialog.description")}
                isDanger
                onClose={handleClose}
            />
        </>
    );
}
