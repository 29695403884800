import { Add } from "@mui/icons-material";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    DialogActions,
    Button,
    IconButton,
} from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { CustomerSelect } from "./CustomerSelect";
import { FormikSubmitButton } from "../../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import { useOpenState } from "../../../../hooks/useOpenState";
import { EmployeeCreate } from "../../../../models/company/Employee";
import { CreateEmployeeViewCallback } from "../../../../service/CompanyService";
import { useEmployeeCreateSchema } from "../../schema";

interface CreateEmployeeDialogProps {
    createEmployeeCallback: CreateEmployeeViewCallback;
}

const initialValues: EmployeeCreate = {
    name: "",
    email: "",
    allowedCustomers: [],
};

export function CreateEmployeeDialog({
    createEmployeeCallback,
}: CreateEmployeeDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("company.employee");

    const {
        state: open,
        open: openDialog,
        close: closeDialog,
    } = useOpenState();
    const { validationSchema, isRequired } =
        useEmployeeCreateSchema(translator);

    return (
        <>
            <IconButton onClick={openDialog}>
                <Add />
            </IconButton>
            <Dialog open={open} fullWidth>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={values =>
                        createEmployeeCallback(values).then(closeDialog)
                    }
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t("pages.company.employee.create_header")}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    <FormikTextField
                                        formik={formik}
                                        field="name"
                                        translator={translator}
                                        required={isRequired("name")}
                                    />

                                    <FormikTextField
                                        formik={formik}
                                        field="email"
                                        translator={translator}
                                        required={isRequired("email")}
                                    />

                                    <CustomerSelect
                                        formik={formik}
                                        translator={translator}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={closeDialog}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
