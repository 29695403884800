import { Card, CardContent, CardHeader, Link, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { Link as RouterLink } from "react-router-dom";

import { useCompanyMetadata } from "../../../../context/AppContext";

export function AppInfo() {
    const { t } = useTranslation();
    const metadata = useCompanyMetadata();

    return (
        <Card>
            <CardHeader title={t("pages.company.app.header")} />
            <CardContent>
                <Typography sx={{ paddingBottom: 3 }}>
                    <Trans
                        i18nKey="pages.company.app.description"
                        components={[
                            <Link
                                component={RouterLink}
                                to="https://play.google.com/store/apps/details?id=com.ipmknaagdierbeheersing.app"
                            />,
                        ]}
                    />
                </Typography>
                <QRCode
                    value={`ipm://add_company/${metadata?.domains.api}`}
                    style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: 256,
                        maxHeight: 256,
                    }}
                />
            </CardContent>
        </Card>
    );
}
