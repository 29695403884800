/* eslint-disable @typescript-eslint/no-explicit-any */
export type FormValues = Record<string, any>;

type EventFunction<EventType extends React.SyntheticEvent> = (
    e: EventType,
    ...args: any[]
) => void;

export function formikEventWrapper<T extends React.SyntheticEvent>(
    handler: EventFunction<T>,
    callback: EventFunction<T> | undefined,
): EventFunction<T> {
    return function (ev: T, args: any[]) {
        handler(ev);
        if (callback) {
            callback(ev, args);
        }
    };
}
