import { useDrag } from "react-dnd";
import { useOptionalFactory } from "react-dnd/dist/hooks/useOptionalFactory";

type UseDrag<DragObject = unknown, CollectedProps = unknown> = typeof useDrag<
    DragObject,
    ResultWithCoords<DragObject>,
    CollectedProps
>;

interface ResultWithCoords<DragObject = unknown> {
    item?: DragObject;
    positionX: number;
    positionY: number;
}

export function useLocalDrag<DragObject = unknown, CollectedProps = unknown>(
    ...[specArgs, ...other]: Parameters<UseDrag<DragObject, CollectedProps>>
): ReturnType<UseDrag<DragObject, CollectedProps>> {
    const spec = useOptionalFactory(specArgs, other);

    return useDrag<DragObject, ResultWithCoords<DragObject>, CollectedProps>(
        spec,
        ...other,
    );
}
