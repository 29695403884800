import { Edit } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useWorkOrderSchema } from "./schema";
import { FormikSubmitButton } from "../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../hooks/useFormTranslator";
import { useOpenState } from "../../../hooks/useOpenState";
import {
    WorkOrderEdit,
    WorkOrderOverview,
} from "../../../models/workorder/WorkOrder";
import { EditWorkOrderListCallback } from "../../../service/WorkOrderService";

interface EditDialogProps {
    workOrder: WorkOrderOverview;
    editWorkOrder: EditWorkOrderListCallback;
}

export function EditDialog({ workOrder, editWorkOrder }: EditDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("workorder");
    const { state, open, close } = useOpenState();

    const { validationSchema, isRequired } = useWorkOrderSchema(translator);

    const onSubmitInternal = useCallback(
        async (
            values: WorkOrderEdit,
            helpers: FormikHelpers<WorkOrderEdit>,
        ) => {
            await editWorkOrder(workOrder.id, values)
                .then(() => helpers.resetForm())
                .then(close);
        },
        [editWorkOrder, workOrder.id, close],
    );

    return (
        <>
            <IconButton onClick={open}>
                <Edit />
            </IconButton>
            <Dialog open={state} fullWidth>
                <Formik
                    initialValues={{
                        comment: workOrder.comment,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitInternal}
                >
                    {formik => (
                        <>
                            <DialogTitle>
                                {t("pages.workorder.edit.action_edit")}
                            </DialogTitle>
                            <DialogContent>
                                <Stack spacing={2} marginY={1}>
                                    <FormikTextField
                                        formik={formik}
                                        field="comment"
                                        translator={translator}
                                        required={isRequired("comment")}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={close}
                                >
                                    {t("common.cancel")}
                                </Button>
                                <FormikSubmitButton formik={formik} />
                            </DialogActions>
                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
