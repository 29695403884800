import { Autocomplete, TextField } from "@mui/material";
import { FormikProps } from "formik";
import { useMemo } from "react";

import { FormTranslator } from "../../../../hooks/useFormTranslator";
import { EmployeeAllowedCustomers } from "../../../../models/company/Employee";
import { useCustomerAllowedService } from "../../../../service/CustomerService";

interface CustomerSelectProps<T extends EmployeeAllowedCustomers> {
    formik: FormikProps<T>;
    translator: FormTranslator;
}

export function CustomerSelect<T extends EmployeeAllowedCustomers>({
    formik,
    translator,
}: CustomerSelectProps<T>) {
    const { customerOptionsResponse } = useCustomerAllowedService();

    const allowedCustomers = useMemo(
        () => [
            ...new Map(
                customerOptionsResponse.data?.map(m => [
                    m.customerId,
                    { id: m.customerId, name: m.customerName },
                ]),
            ).values(),
        ],
        [customerOptionsResponse.data],
    );

    const meta = formik.getFieldMeta<string>("allowedCustomers");
    const hasError = meta.error !== undefined;

    return (
        <Autocomplete
            id="allowedCustomers"
            value={allowedCustomers.filter(f =>
                formik.values.allowedCustomers.includes(f.id),
            )}
            onChange={(_, value) => {
                formik.setFieldValue(
                    "allowedCustomers",
                    value.map(m => m.id),
                );
            }}
            options={allowedCustomers}
            getOptionLabel={item => item.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            limitTags={2}
            multiple
            renderInput={params => (
                <TextField
                    {...params}
                    name="allowedCustomers"
                    label={translator.getField("allowedCustomers").getName()}
                    error={hasError}
                    helperText={hasError ? meta.error : undefined}
                />
            )}
        />
    );
}
