import { SvgIconComponent } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { formikEventWrapper } from "./utils";

type FormikSubmitButtonProps<T> = {
    formik: FormikProps<T>;
    text?: string;
    icon?: SvgIconComponent;
} & Omit<LoadingButtonProps, "id" | "disabled" | "loading">;

export function FormikSubmitButton<T>({
    formik,
    text,
    icon,
    onClick,
    ...props
}: FormikSubmitButtonProps<T>) {
    const { t } = useTranslation();
    const Icon = icon ?? SaveIcon;

    const onClickInternal = formikEventWrapper(formik.submitForm, onClick);

    return (
        <LoadingButton
            id="submit"
            disabled={!formik.isValid}
            loading={formik.isSubmitting}
            loadingPosition="start"
            startIcon={<Icon />}
            color="primary"
            variant="contained"
            onClick={onClickInternal}
            data-testid="formSubmitButton"
            {...props}
        >
            {text ?? t("common.save")}
        </LoadingButton>
    );
}
