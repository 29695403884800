import { useMemo } from "react";
import * as yup from "yup";

import { FormTranslator } from "../../../../hooks/useFormTranslator";
import { useValidationResult, useYup } from "../../../../hooks/useYup";

export function useCustomerUserSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(() => {
        return yup.object({
            email: yup.string().email().required(),
        });
    }, []);

    return useValidationResult(validationSchema);
}
