import {
    DesktopDatePicker,
    DesktopDatePickerProps,
    LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormikProps } from "formik";

import { FormValues } from "./utils";
import { FormTranslator } from "../../../hooks/useFormTranslator";
import { useLocales } from "../../../hooks/useLocales";
import { KeysMatching } from "../../../utils/utils";

export type FormikDateInputProps<T extends FormValues> = {
    formik: FormikProps<T>;
    field: Extract<KeysMatching<T, Date | undefined>, string>;
    translator: FormTranslator;
    helperText?: string;
    onClose?: () => void;
    valueModifier?: (date: Date) => Date;
} & Omit<
    DesktopDatePickerProps<Date>,
    "label" | "value" | "onClose" | "renderInput"
>;

export function FormikDateInput<T extends FormValues>({
    formik,
    field,
    translator,
    helperText,
    slotProps,
    onChange,
    onClose,
    valueModifier = date => date,
    ...props
}: FormikDateInputProps<T>) {
    const { libraries } = useLocales();

    const translatorField = translator.getField(field);
    const label = translatorField.getName();
    const meta = formik.getFieldMeta<Date>(field);

    const hasError = meta.touched && meta.error !== undefined;
    const helpText = hasError ? meta.error : helperText;

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={libraries.datefns}
        >
            <DesktopDatePicker
                label={label}
                value={meta.value}
                onChange={(value, ...props) => {
                    if (value) {
                        formik.setFieldValue(field, valueModifier(value));
                    } else {
                        formik.setFieldValue(field, undefined);
                    }
                    if (onChange) {
                        onChange(value, ...props);
                    }
                }}
                onClose={() => {
                    formik.setFieldTouched(field);
                    if (onClose) {
                        onClose();
                    }
                }}
                slotProps={{
                    ...slotProps,
                    textField: {
                        ...slotProps?.textField,
                        id: field,
                        name: field,
                        error: hasError,
                        helperText: helpText,
                    },
                }}
                {...props}
            />
        </LocalizationProvider>
    );
}
