import { ButtonGroup, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { FloorPlansList } from "./FloorPlansList";
import { CustomerPageTitle } from "../../../components/CustomerPageTitle";
import { useCustomerLocationIdFilter } from "../../../hooks/useCustomerLocationIdFilter";
import { usePermissions } from "../../../hooks/usePermissions";
import { Permissions } from "../../../permissions";
import { useFloorPlanService } from "../../../service/FloorPlanService";
import { UploadDialog } from "../UploadDialog";

export function FloorPlanIndexEmployeePage() {
    const { customerId, locationId } = useCustomerLocationIdFilter();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { floorPlansResponse, addFloorPlan, deleteFloorPlan } =
        useFloorPlanService(locationId);

    const { hasPermission } = usePermissions();
    const canEdit = hasPermission(Permissions.FloorPlansManage);

    return (
        <>
            {customerId && locationId && (
                <CustomerPageTitle
                    headerPrefix={t("pages.floorplan.header_customer")}
                    customerId={customerId}
                    locationId={locationId}
                />
            )}
            <Grid container spacing={2}>
                <Grid item xs={12} flexGrow={0}>
                    <Grid container spacing={2}>
                        {canEdit && (
                            <Grid item>
                                <ButtonGroup>
                                    <UploadDialog
                                        customerLocationId={locationId}
                                        onSave={addFloorPlan}
                                    />
                                </ButtonGroup>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} flexGrow={1}>
                    <FloorPlansList
                        customerLocationId={locationId}
                        response={floorPlansResponse}
                        onRowClick={row =>
                            navigate(`/app/floorplans/${row.id}`)
                        }
                        deleteFloorPlan={deleteFloorPlan}
                    />
                </Grid>
            </Grid>
        </>
    );
}
