import { ButtonGroup, Grid, LinearProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { fromUnixTime } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getCalendarListActions } from "./Actions";
import { ApplicationPagingDataGrid } from "../../../components/Controls/DataGrid/Paging";
import { use404Page } from "../../../hooks/use404Page";
import { useDateUtils } from "../../../hooks/useDateUtils";
import { useFormTranslator } from "../../../hooks/useFormTranslator";
import { usePermissions } from "../../../hooks/usePermissions";
import { Event } from "../../../models/calendar/Event";
import { Error } from "../../../models/error/Error";
import { Permissions } from "../../../permissions";
import {
    AddEventListCallback,
    DeleteEventListCallback,
    DownloadIcalListCallback,
    EditEventListCallback,
} from "../../../service/CalendarService";
import { UsePaginationServiceResult } from "../../../service/PaginationService";
import { formatCustomerName } from "../../../utils/customer.utils";
import { AddDialog } from "../Dialog/AddDialog";

interface CalendarListProps {
    customerLocationId?: number;
    response: UsePaginationServiceResult<Event, Error>;
    addEvent: AddEventListCallback;
    editEvent: EditEventListCallback;
    downloadIcal: DownloadIcalListCallback;
    deleteEvent: DeleteEventListCallback;
}

export function CalendarList({
    customerLocationId,
    response,
    addEvent,
    editEvent,
    downloadIcal,
    deleteEvent,
}: CalendarListProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("calendar");
    const dateUtils = useDateUtils();
    const { hasPermission } = usePermissions();

    use404Page(response.response.error !== undefined);

    const canEdit = hasPermission(Permissions.CalendarManage);

    const columns = useMemo<GridColDef<Event>[]>(
        () => [
            getCalendarListActions({
                canEdit,
                download: downloadIcal,
                editEvent,
                deleteEvent,
                t,
            }),
            {
                field: "name",
                headerName: translator.getField("name").getName(),
                flex: 1,
            },
            {
                field: "customerLocationId",
                headerName: translator.getField("customerLocationId").getName(),
                flex: 1,
                valueGetter: row => formatCustomerName(row.row),
            },
            {
                field: "startDate",
                headerName: translator.getField("startDate").getName(),
                flex: 1,
                valueFormatter: params =>
                    dateUtils.format(fromUnixTime(params.value), "P"),
            },
            {
                field: "endDate",
                headerName: translator.getField("endDate").getName(),
                flex: 1,
                valueFormatter: params =>
                    dateUtils.format(fromUnixTime(params.value), "P"),
            },
        ],
        [
            canEdit,
            dateUtils,
            deleteEvent,
            downloadIcal,
            editEvent,
            t,
            translator,
        ],
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {canEdit && (
                        <Grid item>
                            <ButtonGroup>
                                <AddDialog
                                    addEventCallback={addEvent}
                                    customerLocationId={customerLocationId}
                                />
                            </ButtonGroup>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {response.response.data ? (
                    <ApplicationPagingDataGrid
                        pagination={response.response.data}
                        paginationControls={response.paginationControls}
                        columns={columns}
                        getRowId={row => row.id}
                        removeColumns={
                            customerLocationId
                                ? ["customerLocationId"]
                                : undefined
                        }
                        disableRowSelectionOnClick
                        autoHeight
                    />
                ) : (
                    <LinearProgress />
                )}
            </Grid>
        </Grid>
    );
}
