import { ArrowLeft } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Grid } from "@mui/material";
import { useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { CustomerData } from "./CustomerData";
import { CustomerLocationsData } from "./CustomerLocationsData";
import { CustomerUsersData } from "./CustomerUsersData";
import { ConfirmButton } from "../../../../components/Controls/ConfirmButton";
import { use404Page } from "../../../../hooks/use404Page";
import { useCustomerIdFilter } from "../../../../hooks/useCustomerIdFilter";
import { SortDirection } from "../../../../models/pagination/Pagination";
import { useCustomerLocationListService } from "../../../../service/CustomerLocationService";
import { useCustomerViewService } from "../../../../service/CustomerService";
import { useCustomerUserListService } from "../../../../service/CustomerUserService";
import { EditCustomerDialog } from "../EditDialog/EditCustomerDialog";

interface CustomerViewContentProps {
    customerId: number;
}

function CustomerViewErrorRedirect() {
    use404Page(true);
    return <></>;
}

function CustomerViewContent({ customerId }: CustomerViewContentProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { customerResponse, editCustomer, deleteCustomer } =
        useCustomerViewService(customerId);
    const { customerLocationsResponse, addCustomerLocation } =
        useCustomerLocationListService(customerId, {
            page: 1,
            itemsPerPage: 10,
            itemsPerPageOptions: [10, 20, 50],
            sort: undefined,
            sortDirection: SortDirection.None,
        });
    const { customerUsersResponse, addCustomerUser, deleteCustomerUser } =
        useCustomerUserListService(customerId, {
            page: 1,
            itemsPerPage: 10,
            itemsPerPageOptions: [10, 20, 50],
            sort: undefined,
            sortDirection: SortDirection.None,
        });

    use404Page(
        !customerResponse.isLoading && customerResponse.data === undefined,
    );

    const deleteInternal = useCallback(
        () => deleteCustomer().finally(() => navigate("/app/customers")),
        [deleteCustomer, navigate],
    );

    return (
        <>
            {customerResponse.data && (
                <Helmet
                    title={`${t("pages.customer.header_view")} - ${
                        customerResponse.data?.name
                    }`}
                />
            )}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex" }}>
                        <ButtonGroup sx={{ marginRight: 1 }}>
                            <Button onClick={() => navigate(-1)} size="small">
                                <ArrowLeft />
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup sx={{ marginRight: 1 }}>
                            {customerResponse.data && (
                                <EditCustomerDialog
                                    customer={customerResponse.data}
                                    editCustomer={editCustomer}
                                />
                            )}
                            <ConfirmButton
                                confirmText={t(
                                    "pages.customer.edit.warning_remove",
                                )}
                                onConfirm={deleteInternal}
                                color="error"
                                isDanger
                            >
                                {t("common.remove", {
                                    resource: t("pages.customer.resource"),
                                })}
                            </ConfirmButton>
                        </ButtonGroup>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <CustomerData customer={customerResponse.data} />
                        </Grid>
                        <Grid item xs={8}>
                            <CustomerLocationsData
                                customerId={customerId}
                                customerType={customerResponse.data?.type}
                                locations={
                                    customerLocationsResponse.response.data
                                }
                                paginationControls={
                                    customerLocationsResponse.paginationControls
                                }
                                addCustomerLocation={addCustomerLocation}
                            />
                            <CustomerUsersData
                                users={customerUsersResponse.response.data}
                                paginationControls={
                                    customerUsersResponse.paginationControls
                                }
                                addCustomerUser={addCustomerUser}
                                deleteCustomerUser={deleteCustomerUser}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export function CustomerViewPage() {
    const { customerId } = useCustomerIdFilter();

    if (customerId === undefined) {
        return <CustomerViewErrorRedirect />;
    } else {
        return <CustomerViewContent customerId={customerId} />;
    }
}
