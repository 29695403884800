import { useMemo } from "react";
import * as yup from "yup";

import { FormTranslator } from "../../hooks/useFormTranslator";
import { useValidationResult, useYup } from "../../hooks/useYup";

export function usePasswordChangeSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(() => {
        return yup.object({
            currentPassword: yup.string().required(),
            newPassword: yup.string().required(),
            repeatNewPassword: yup
                .string()
                .required()
                .oneOf(
                    [yup.ref("newPassword")],
                    translator.getFormError("passwordmatch"),
                ),
        });
    }, [translator]);

    return useValidationResult(validationSchema);
}

export function useTwoFactorEnableSchema(translator: FormTranslator) {
    useYup(translator);

    const validationSchema = useMemo(() => {
        return yup.object({
            code: yup.string().required(),
        });
    }, []);

    return useValidationResult(validationSchema);
}
