import {
    GridPaginationModel,
    GridSortDirection,
    GridSortModel,
    GridValidRowModel,
} from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";

import {
    ApplicationStandardDataGrid,
    ApplicationStandardDataGridProps,
} from "./Standard";
import { PagedResults } from "../../../models/pagination/PagedResults";
import { SortDirection } from "../../../models/pagination/Pagination";
import { PaginationControls } from "../../../service/PaginationService";

export type ApplicationPagingDataGridProps<R extends GridValidRowModel> = {
    pagination: PagedResults<R>;
    paginationControls: PaginationControls;
} & Omit<
    ApplicationStandardDataGridProps<R>,
    | "rows"
    | "pagination"
    | "paginationMode"
    | "page"
    | "pageSize"
    | "rowCount"
    | "rowsPerPageOptions"
    | "onPageChange"
    | "onPageSizeChange"
    | "sortModel"
    | "sortingMode"
    | "sortingOrder"
    | "onSortModelChange"
>;

const getMaterialSort = (sort: SortDirection): GridSortDirection => {
    if (sort === SortDirection.Ascending) {
        return "asc";
    } else if (sort === SortDirection.Descending) {
        return "desc";
    } else {
        return undefined;
    }
};

const getApplicationSort = (sort: GridSortDirection): SortDirection => {
    if (sort === "asc") {
        return SortDirection.Ascending;
    } else if (sort === "desc") {
        return SortDirection.Descending;
    } else {
        return SortDirection.None;
    }
};

export function ApplicationPagingDataGrid<R extends GridValidRowModel>({
    pagination,
    paginationControls,
    ...rest
}: ApplicationPagingDataGridProps<R>) {
    const sortDirection = useMemo<GridSortDirection>(() => {
        if (pagination.sort) {
            return getMaterialSort(pagination.sortDirection);
        } else {
            return undefined;
        }
    }, [pagination.sort, pagination.sortDirection]);

    const sortModel = useMemo<GridSortModel>(
        () =>
            pagination.sort
                ? [
                      {
                          field: pagination.sort,
                          sort: sortDirection,
                      },
                  ]
                : [],
        [pagination.sort, sortDirection],
    );

    const paginationChangedCallback = useCallback(
        (model: GridPaginationModel) => {
            paginationControls.setPage(model.page);
            paginationControls.setItemsPerPage(model.pageSize);
        },
        [paginationControls],
    );

    const sortChangedCallback = useCallback(
        (model: GridSortModel) => {
            if (model.length > 0) {
                paginationControls.setSort(
                    model[0].field,
                    getApplicationSort(model[0].sort),
                );
            } else {
                paginationControls.setSort(undefined, SortDirection.None);
            }
        },
        [paginationControls],
    );

    return (
        <ApplicationStandardDataGrid
            {...rest}
            rows={pagination.items}
            rowCount={pagination.totalItems}
            pagination
            paginationMode="server"
            paginationModel={{
                page: pagination.page - 1,
                pageSize: pagination.itemsPerPage,
            }}
            pageSizeOptions={pagination.itemsPerPageOptions}
            onPaginationModelChange={paginationChangedCallback}
            filterMode="client"
            sortingMode="server"
            sortModel={sortModel}
            sortingOrder={["asc", "desc", null]}
            onSortModelChange={sortChangedCallback}
        />
    );
}
