import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useCustomerSchema } from "./schema";
import { FormikSelect } from "../../../../components/Controls/Formik/Select";
import { FormikSubmitButton } from "../../../../components/Controls/Formik/SubmitButton";
import { FormikTextField } from "../../../../components/Controls/Formik/TextField";
import { useFormTranslator } from "../../../../hooks/useFormTranslator";
import {
    CustomerAdd,
    CustomerType,
} from "../../../../models/customer/Customer";
import { AddCustomerListCallback } from "../../../../service/CustomerService";

interface AddCustomerDialogProps {
    addCustomer: AddCustomerListCallback;
}

const emptyCustomer: CustomerAdd = {
    type: CustomerType.Private,
    name: "",
    email: "",
    phoneNumber: undefined,
    mobileNumber: undefined,
    locationName: "",
    locationAddress: "",
    locationPostal: "",
    locationResidence: "",
    locationDescription: "",
    locationEmploymentReason: "",
    locationCommerceNumber: undefined,
};

export function AddCustomerDialog({ addCustomer }: AddCustomerDialogProps) {
    const { t } = useTranslation();
    const translator = useFormTranslator("customer");

    const { validationSchema, isRequired } = useCustomerSchema(
        "add",
        translator,
    );

    const [opened, setOpened] = useState(false);

    const onOpen = useCallback(() => setOpened(true), [setOpened]);
    const onClose = useCallback(() => setOpened(false), [setOpened]);

    const onSubmit = useCallback(
        async (values: CustomerAdd) => {
            await addCustomer(values);
            onClose();
        },
        [addCustomer, onClose],
    );

    const formik = useFormik({
        initialValues: emptyCustomer,
        validationSchema,
        onSubmit,
    });

    return (
        <>
            <Button onClick={onOpen} variant="outlined">
                {t("common.add", { resource: t("pages.customer.resource") })}
            </Button>
            <Dialog open={opened} fullWidth>
                <DialogTitle>
                    {t("common.add", {
                        resource: t("pages.customer.resource"),
                    })}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} marginY={1}>
                        <FormikSelect
                            formik={formik}
                            translator={translator}
                            field="type"
                            required
                            fullWidth
                        >
                            {Object.keys(CustomerType).map(key => (
                                <MenuItem
                                    key={`customer_type${key}`}
                                    value={key}
                                >
                                    {translator.getField("type").getEnum(key)}
                                </MenuItem>
                            ))}
                        </FormikSelect>

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="name"
                            required={isRequired("name")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="email"
                            required={isRequired("email")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="phoneNumber"
                            required={isRequired("phoneNumber")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="mobileNumber"
                            required={isRequired("mobileNumber")}
                            fullWidth
                        />

                        <Typography variant="subtitle1">Locatie</Typography>

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="locationName"
                            required={isRequired("locationName")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="locationAddress"
                            required={isRequired("locationAddress")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="locationPostal"
                            required={isRequired("locationPostal")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="locationResidence"
                            required={isRequired("locationResidence")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="locationDescription"
                            required={isRequired("locationDescription")}
                            fullWidth
                        />

                        <FormikTextField
                            formik={formik}
                            translator={translator}
                            field="locationEmploymentReason"
                            required={isRequired("locationEmploymentReason")}
                            fullWidth
                        />

                        {formik.values.type === CustomerType.Company && (
                            <FormikTextField
                                formik={formik}
                                translator={translator}
                                field="locationCommerceNumber"
                                required={isRequired("locationCommerceNumber")}
                                fullWidth
                            />
                        )}

                        {formik.values.type === CustomerType.Township && (
                            <FormikTextField
                                formik={formik}
                                translator={translator}
                                field="locationCommerceNumber"
                                required={isRequired("locationCommerceNumber")}
                                fullWidth
                            />
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="error" variant="contained" onClick={onClose}>
                        {t("common.cancel")}
                    </Button>
                    <FormikSubmitButton formik={formik} />
                </DialogActions>
            </Dialog>
        </>
    );
}
